import React, { useState, useEffect, FC } from 'react'
import { BillingCompany } from '../../../../types'

type Props = {
  data: any
  edit: string
  isNBS: boolean
  selectedValue: (values: BillingCompany) => void
}

const SearchDropDown: FC<Props> = (props) => {
  const [show, setShow] = useState<boolean>(false)
  const [data, setData] = useState(props.data)
  const [searchValue, setSearchValue] = useState<string>('')
  const [selected, setSelected] = useState<BillingCompany | undefined>()

  const handleSearch = (e: any) => {
    const input = e.target.value?.toUpperCase()

    setShow(true)
    setSearchValue(e.target.value)
    setData(
      props.data?.filter((value: any) => {
        return (
          `${value.BillingCompanyID} - ${value.CompanyName}`.toUpperCase().includes(input) === true
        )
      })
    )
  }

  const handleSelect = (item: BillingCompany) => {
    props.selectedValue(item)

    setSelected(item)
    setShow(false)
    setSearchValue(`${item.BillingCompanyID} - ${item.CompanyName}`)
  }

  useEffect(() => {
    setData(props.data)

    if (props.edit !== '') {
      if (props.edit === 'reset') {
        const company: BillingCompany = {
          CompanyName: '',
          taxExempt: '',
          netTerms: '',
          BillingCompanyID: '',
          BillAddress: '',
          BillAddress2: '',
          BillCity: '',
          BillFax: '',
          BillPhone: '',
          BillState: '',
          BillZip: '',
          BillAttn_email: '',
          BillNotes: ''
        }
        setSelected(company)
        setSearchValue('')
      } else {
        const editCompany = props.data.find(
          (company: BillingCompany) => company.BillingCompanyID === props.edit
        )

        setSelected(editCompany)
        setSearchValue(`${editCompany?.BillingCompanyID} - ${editCompany?.CompanyName}`)
      }
    }
  }, [props])

  return (
    <div className='position-relative'>
      <span className='select2-container' onClick={() => setShow(!show)}>
        <span className='select2-selection select2-selection--single form-select form-select-solid fw-bolder'>
          <div className='select2-search select2-search--dropdown'>
            <input
              type='text'
              className='select2-search__field w-100'
              value={searchValue}
              onChange={handleSearch}
            />
          </div>
        </span>
      </span>
      <div
        className={`select2-container select2-container--bootstrap5 search-dropdown-container ${
          !show && 'd-none'
        }`}
      >
        <div className='select2-dropdown'>
          <span className='select2-results'>
            <ul className='select2-results__options'>
              {data.length === 0 ? (
                <li key='empty' className='select2-results__option'>
                  No Company
                </li>
              ) : (
                data?.map((item: any, index: number) => {
                  return (
                    <li
                      key={index}
                      className={`${
                        selected?.BillingCompanyID === item.BillingCompanyID &&
                        'select2-results__option--selected'
                      } select2-results__option select2-results__option--selectable`}
                      onClick={() => handleSelect(item)}
                    >
                      {item.BillingCompanyID} - {item?.CompanyName}
                    </li>
                  )
                })
              )}
            </ul>
          </span>
        </div>
      </div>
    </div>
  )
}

export { SearchDropDown }
