import axios from 'axios'
import { fileType } from '../../../types'

export const APP_URL = process.env.REACT_APP_API_URL
export const QUOTES_URL = `${process.env.REACT_APP_API_URL}/service-quotes`
export const ACTION_QUOTES_URL = `${process.env.REACT_APP_API_URL}/service-sales-quotes`
export const APPROVE_QUOTE_URL = `${process.env.REACT_APP_API_URL}/sales-quotes/approve`

export function getServiceQuotes(searchFilter: any = {}) {
  return axios.post(QUOTES_URL, { searchFilter })
}

export function updateServiceQuote(values: any) {
  const API_URL = `${ACTION_QUOTES_URL}/${values.dbkey}}`

  return axios.post(API_URL, values)
}

export function rejectQuote(values: any = {}) {
  return axios.post(`${QUOTES_URL}/reject`, { values })
}

export function commentQuote(values: any = {}) {
  return axios.post(`${QUOTES_URL}/comment`, { values })
}

export function approveQuote(id: number, values: any) {
  let formData = new FormData()
  formData.append('quoteId', id.toString())

  if (values.attachment !== '') {
    for (let i = 0; i < values.attachment.length; i++) {
      formData.append(`attachment[${i}]`, values.attachment[i])
    }
  }

  Object.keys(values).forEach((key) => formData.append(key, values[key]))

  return axios.post(APPROVE_QUOTE_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function getQuoteAttachment(dbkey: number) {
  const API_URL = `${APP_URL}/get-quote-attachment/${dbkey}`

  return axios.get(API_URL)
}

export function uploadAttachment(quoteId: number, oldAttachment: fileType[], files: any) {
  let formData = new FormData()

  formData.append('quoteId', JSON.stringify(quoteId))
  formData.append('oldAttachment', JSON.stringify(oldAttachment))

  for (let i = 0; i < files.files.length; i++) {
    formData.append(`files[${i}]`, files.files[i])
  }

  Object.keys(files).forEach((key) => formData.append(key, files[key]))

  return axios.post(`${APP_URL}/upload-attachment`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function downloadAttachment(name: string) {
  return axios.post(
    `${APP_URL}/download-attachment`,
    { name },
    {
      responseType: 'blob',
    }
  )
}

export function getTrnTypes(){
  const API_URL = `${APP_URL}/NBSTablesData/get/trnTypes`

  return axios.get(API_URL)
}

export function getQuoteFilterStatus() {
  return axios.get(`${process.env.REACT_APP_API_URL}/quote-filter-status`)
}

