import { sprintf } from "sprintf-js"
import { exportAssets } from "../../asset-mangement/AssetManagementCrud";
import { Result } from "antd";

export function FormatConditionAsText(resValue: number, resName: string) {
    let text = '';
    console.log(resName, resValue)
    switch (resName) {
        case 'isRepeatabilityOk':
            if (resValue == 1) {
                text = "Passed";
            } else if (resValue == 0) {
                text = "Failed";
            }
            break;
        case 'isWorking':
            if (resValue == 1) {
                text = "Working";
            } else if (resValue == 0) {
                text = "Not Working";
            }
            break;
        case 'isClean':
            if (resValue == 1) {
                text = "Clean";
            } else if (resValue == 0) {
                text = "Dirty";
            }
            break;
        case 'isVerifiedZero':
            if (resValue == 1) {
                text = "Passed";
            } else if (resValue == 0) {
                text = "Failed";
            }
            break;
        case 'isSiteCalibration':
            if (resValue == 1) {
                text = "Yes";
            } else if (resValue == 0) {
                text = "No";
            }
            break;
        default:
            text = '';
    }

    return text;
}

export function StandardDeviationVer2(division: string | undefined, arrValues: (string | undefined)[], checkV1Formula = false) {
    division = division?.replace(/\.*0+$/, "");
    let getDigits = division;
    getDigits = getDigits?.split(".")[1];
    
    let divisionLen = (getDigits && getDigits.length + 1) as number;
    let result;
    let checkByDivision = 0.41 * Number(division);
    let formatCheckByDivision = formatStandardDeviationFloats(checkByDivision);

    if (checkV1Formula) {
        //ver1Calculation = this->StandardDeviationVer1Calculation(arrValues);
        let ver1Calculation = StandardDeviationVer1Formula2(arrValues);
        ver1Calculation = formatStandardDeviationFloats(ver1Calculation);

        if (ver1Calculation < parseFloat(formatCheckByDivision)) {
            result = formatCheckByDivision;
        } else {

            result = Number(ver1Calculation).toFixed(divisionLen);
        }
    } else {
        result = formatCheckByDivision.toFixed(divisionLen);;
    }
    result = result?.replace(/\.*0+$/, "");
    return result;
    
}

export function StandardDeviation(aValues: (string | undefined)[], bSample = false) {
    var tmpArray = [];
    for (let i = 0; i < aValues.length; i++) {
        const element = aValues[i];
        if (element && Number(element) > 0) {
            tmpArray.push(aValues[i]);
        }
    }
    if (tmpArray.length === 0) { // case no data
        return '0';
    }
    let fMean = array_sum(tmpArray) / tmpArray.length;
    var fVariance = 0.0;
    for (let i = 0; i < tmpArray.length; i++) {
        const element = Number(tmpArray[i]);
        fVariance += Math.pow(element - fMean, 2);
    }
    fVariance /= (bSample ? tmpArray.length - 1 : tmpArray.length);
    let getDivision = tmpArray[0]?.split(".")[1].length;
    let stDev = Math.sqrt(fVariance);
    stDev = formatStandardDeviationFloats(stDev);
    return Number(stDev).toFixed(getDivision);
}

export function UncertaintyCalculationVer2(
  standardDeviation: string,
  division: string,
  uncertaintyParameter: string
) {
  if (!standardDeviation || !division || !uncertaintyParameter) {
    return ''
  }

  standardDeviation = standardDeviation?.replace(/\.*0+$/, '')
  division = division?.replace(/\.*0+$/, '')
  let getDigits = division.split('.')[1].length + 1
  let checkByDivision = 0.41 * Number(division)
  checkByDivision = formatStandardDeviationFloats(checkByDivision)
  console.log('hello==', checkByDivision, standardDeviation)
  let useStandardDeviation
  if (parseFloat(standardDeviation) < checkByDivision) {
    useStandardDeviation = checkByDivision
  } else {
    useStandardDeviation = parseFloat(standardDeviation)
  }
  // rounded always to the second significant digit
  console.log("here1")
  let step_1 = Math.pow(useStandardDeviation, 2)
  let step_2 = 2 * Math.pow(parseFloat(division) * 0.29, 2)
  let step_3 = Math.pow(parseFloat(uncertaintyParameter), 2)

  let result = Math.sqrt(step_1 + step_2 + step_3)
  result = result * 2
  return result.toFixed(getDigits)
}

export function CalculateUncertaintyForRepeatability(division: string, uncertainty: string, standardDeviation: number, scaleClass: string) {
    if (uncertainty == "" || division == "") {
        if(scaleClass == "III" && division != ""){
            return 1.2 * parseFloat(division);
        }
        return '';
    }
    let parts = division.split(".");
    let decimalPart = parts.length > 1 ? parts[1].replace(/0+$/, '') : '';
    let getDigits = decimalPart.length;
    var result = 0
    if(scaleClass != "III") {
        let step_0 = (standardDeviation);
        let step_1 = ((parseFloat(division) /3.4641));
        let step_2 = ((parseFloat(division) /3.4641));
        let step_3 = ((parseFloat(uncertainty) / 2));
        result = Math.sqrt(Math.pow(step_0,2)+Math.pow(step_1,2) + Math.pow(step_2,2) + Math.pow(step_3,2));
        result = result * 2;
        if(result < (1.2 * parseFloat(division))) {
            result = result = 1.2 * parseFloat(division);
        }
    } else {
        result = 1.2 * parseFloat(division);
    }

    return result;
}

function formatStandardDeviationFloats(values: number) {
    let value = sprintf('%.15f', values);
    value = rtrim(value, '0');
    let getDigits = value.split(".")[1]
    let decimalPoints = getDigits.length;
    let fVal = '%.' + decimalPoints + 'f';
    let format = sprintf(fVal, value);

    return format;
}
export function rtrim(str: string, chr: string) {
    var rgxtrim = (!chr) ? new RegExp('\\s+') : new RegExp(chr + '+');
    return str.replace(rgxtrim, '');
}

function StandardDeviationVer1Formula2(values: (string | undefined)[]) {
    let fMean = array_sum(values) / values.length;
    let fVariance = 0.0;
    for (let i = 0; i < values.length; i++) {
        const element = Number(values[i]);
        fVariance += Math.pow(element - fMean, 2);

    }

    let size = values.length - 1;

    return Math.sqrt(fVariance) / Math.sqrt(size);
}
function array_sum(values: (number | string | undefined)[]) {
    var sum = 0;
    for (let i = 0; i < values.length; i++) {

        const element = Number(values[i]);
        sum += element;
    }
    return sum
}
export function CheckIsAdjusted(arrayAsFound: string[], arrayAsLeft: string[]) {
    for (let i = 0; i < arrayAsFound.length; i++) {
        const element = arrayAsFound[i];
        if (
            element != arrayAsLeft[i]
        ) {
            return "Yes"
        }
    }
    return "No"
}

export function PerformanceAsLeftTolerance(WtL1: string, WtL2: string, WtL3: string, WtL4: string,WtL5: string, WtL6: string, TOL1: string, TOL2: string, TOL3: string, TOL4: string, TOL5: string,  TOL6: string,  A2LA: number) {
    if(A2LA == 1) {
        if (
            checkToleranceAsLeft(TOL1, WtL1) == 'Yes' &&
            checkToleranceAsLeft(TOL2, WtL2) === 'Yes' &&
            checkToleranceAsLeft(TOL3, WtL3) === 'Yes' &&
            checkToleranceAsLeft(TOL4, WtL4) === 'Yes' &&
            checkToleranceAsLeft(TOL5, WtL5) === 'Yes' &&
            checkToleranceAsLeft(TOL6, WtL6) === 'Yes'
        )  {
            return 'Yes';
        } else {
            return 'No';
        }
    } else {
        if (checkToleranceAsLeft(TOL3, WtL3) == 'Yes'
        && checkToleranceAsLeft(TOL4, WtL4) == 'Yes'
        && checkToleranceAsLeft(TOL5, WtL5) == 'Yes'
        && checkToleranceAsLeft(TOL6, WtL6) == 'Yes'
        ) {
            return 'Yes';
        } else {
            return 'No';
        }
    }
}

function checkToleranceAsLeft(tolRange: string, weight: string) {
    let pieces = tolRange.split(" - ");
    if (!weight) {
        return 'Yes';
    }

    if (!pieces[0] || !pieces[1]) {
        return 'Yes';
    }
    if (parseFloat(pieces[0]) <= parseFloat(weight) && parseFloat(weight) <= parseFloat(pieces[1])) {
        return 'Yes';
    } else {
        let epsilon = 0.00001;
        console.log(parseFloat(weight),"--",pieces[1],"--",epsilon,"--",tolRange)
        console.log()
        if (Math.abs(parseFloat(pieces[0]) - parseFloat(weight)) < epsilon) { // case equal floats
            return "Yes";
        } else if (Math.abs(parseFloat(weight) - parseFloat(pieces[1])) < epsilon) { /// case equal floats
            return 'Yes';
        } else {
            return 'No';
        }

    }
}

// export function CornerloadToleranceValueRounded(value:string, tolerance:string, division:string, isUnutsWithDivisionsOneGram = false,um : string,dv:string,Capacity:string,dvdual:string) {
//     if ((dv == '1.0' && um == 'g') || ((dv == '1.0' && um == 'lb'))) {
//         isUnutsWithDivisionsOneGram = true;
//     }


//     let tmpCapacity = Capacity;
//    let tmpDivision = dv;
//    let tmpDivisionDual = dvdual;

//     if (tmpDivisionDual) {
//         let tmpCap = Capacity.split("/");

//         if (!tmpCap[0] || !tmpCap[1]) {
//             $setDivisionForTolerance = $tmpDivision;
//         } else {
//             $tmpCapacity1 = trim($tmpCapacity[0]);
//             $tmpCapacity2 = trim($tmpCapacity[1]);
//             if ($arrayEquipmentTest[0]['CornerLoadValue'] > $tmpCapacity1) {
//                 $setDivisionForTolerance = $tmpDivisionDual;
//             } else {
//                 $setDivisionForTolerance = $tmpDivision;
//             }
//         }
//     } else {
//         $setDivisionForTolerance = $tmpDivision;
//     }



//     if (isUnutsWithDivisionsOneGram) {
//         return (value - tolerance). ' - '. (value + tolerance);
//     } else {
//         decimals = ((int) division != division ) ?(strlen(division) - strpos(division, '.')) - 1 : 0;
//         part1 = round((value - tolerance), decimals);
//         part2 = round((value + tolerance), decimals);

//         return sprintf("%.".decimals."f", part1). ' - '.sprintf("%.".decimals."f", part2);
//     }
// }

