import { type } from 'os'
import React, { useState, FC, useEffect } from 'react'

type Props = {
  data: number[] | string[]
  defaultValue: string | number
  dirUp: boolean
  selectedValue: (value: string | number) => void
  disable?: boolean
  isAdmin?: boolean
  type?: 'card' | 'customer'
}

export const SelectDropDown: FC<Props> = ({
  data,
  defaultValue,
  dirUp,
  selectedValue,
  disable,
  isAdmin,
  type,
}) => {
  const [show, setShow] = useState<boolean>(false)
  const [po, setPo] = useState<number | string>('')
  const [selected, setSelected] = useState<number | string>('')

  const handleSelect = (item: string | number) => {
    selectedValue(item)
    setSelected(item)
    setShow(false)
  }

  useEffect(() => {
    setSelected(defaultValue)
  }, [defaultValue])

  return (
    <div className={`position-relative select2-body ${disable && 'pe-none'}`}>
      <span className='select2-container' onClick={() => setShow(!show)}>
        <span className='select2-selection select2-selection--single form-select form-select-solid fw-bolder min-h-43px'>
          <div className='select2-search select2-search--dropdown'>{selected}</div>
        </span>
      </span>
      <div
        className={`select2-container select2-container--bootstrap5 search-dropdown-container ${
          dirUp && 'bottom-100'
        } ${!show && 'd-none'}`}
      >
        <div className='select2-dropdown'>
          <span className='select2-results'>
            <ul className='select2-results__options'>
              {/* {data.length > 0 && !isAdmin && type === 'card' ? (
                <li className='m-4'>
                  <label>Enter P.O.number</label>
                  <input
                    onChange={(e) => {
                      setPo(e.target.value)
                      selectedValue(e.target.value)
                    }}
                    value={po}
                  />
                </li>
              ) : null} */}
              {data?.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={`${
                      selected === item && 'select2-results__option--selected'
                    } select2-results__option select2-results__option--selectable`}
                    onClick={() => handleSelect(item)}
                  >
                    {item}
                  </li>
                )
              })}
            </ul>
          </span>
        </div>
      </div>
    </div>
  )
}
