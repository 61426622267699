import React, { useState } from 'react'
import { GetServiceNowModal } from '../../components/_modal/GetServiceNowModal'

const NeedService: React.FC = () => {
  const [show, setShow] = useState<boolean>(false)

  const handleHide = () => {
    setShow(false)
  }
  return (
    <>
      <div className="card card-xl-stretch mb-xl-8 bg-primary">
        <div className="card-body d-flex flex-column pt-13 pb-14">
          <div className="m-0">
            <h1 className="fw-bold text-white text-center lh-lg mb-9">
              <span className="fw-boldest">Need Service?</span>
            </h1>
            <div
              className="flex-grow-1 bgi-no-repeat bgi-size-contain bgi-position-x-center card-rounded-bottom h-200px mh-200px my-20"
              style={{backgroundImage: 'url("/media/svg/need-service.svg")'}}
            >
            </div>
          </div>
          <div className="text-center">
            <button
              className="btn btn-sm bg-white btn-color-white bg-opacity-20"
              onClick={() => setShow(true)}
            >
              Get Service Now
            </button>
          </div>
        </div>
      </div>
      <GetServiceNowModal show={show} onHide={handleHide}  />
    </>
    
  )
}

export default NeedService