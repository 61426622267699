import React from 'react'
import { useTable, useFilters, useSortBy } from 'react-table'
import { KTSVG } from '../../../../../_metronic/helpers'
import { getCompanyName } from '../../../../../helper'

function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      includes: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]

          return rowValue !== undefined
            ? String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase())
            : true
        })
      },
      company: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id].customerIds
            .map((id) => id.customerId + id.companyName)
            .join(' ')

          return rowValue !== undefined
            ? String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase())
            : true
        })
      },
      name: (rows, id, filterValue) => {
        const ret = rows.filter((row) => {
          const rowValue = row.values[id].name

          return rowValue !== undefined
            ? String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase())
            : true
        })
        return ret
      },
      selectIncludes: (rows, id, filterValue) => {
        if (!filterValue.length) return rows

        return rows.filter((row) => {
          const rowValue = row.values[id]

          return rowValue !== undefined ? filterValue.includes(String(rowValue)) : true
        })
      },
    }),
    []
  )

  const sortTypes = React.useMemo(
    () => ({
      // case-insensitive sort
      alphanumeric: (row1, row2, columnName) => {
        let rowOneColumn = row1.values[columnName]
        let rowTwoColumn = row2.values[columnName]

        if (columnName === 'basic-info') {
          rowOneColumn = rowOneColumn.name
          rowTwoColumn = rowTwoColumn.name
        } else if (columnName === 'company-info') {
          rowOneColumn = getCompanyName(rowOneColumn.customerIds)
          rowTwoColumn = getCompanyName(rowTwoColumn.customerIds)
        }

        if (isNaN(rowOneColumn)) {
          return rowOneColumn.toUpperCase() > rowTwoColumn.toUpperCase() ? 1 : -1
        }

        return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1
      },
    }),
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      filterTypes,
      sortTypes,
    },
    useFilters,
    useSortBy
  )

  const renderSortIcon = (column) => {
    if (!column.canSort || column.disableSortBy) return null
    if (!column.isSorted) {
      return <KTSVG path='/media/icons/duotone/Navigation/Arrows-v.svg' />
    }
    if (column.isSortedDesc) {
      return <KTSVG path='/media/icons/duotone/Navigation/Up-2.svg' />
    }
    return <KTSVG path='/media/icons/duotone/Navigation/Down-2.svg' />
  }

  // Render the UI for your table
  return (
    <table
      {...getTableProps()}
      className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
    >
      <thead>
        {headerGroups.map((group, i) => {
          return (
            <tr
              key={`cust-thead-tr-${i}`}
              {...group.getHeaderGroupProps()}
              className='fw-bolder text-muted'
            >
              {group.headers.map((column, j) => {
                return (
                  <th key={`cust-thead-th-${i}-${j}`}>
                    <div
                      {...column.getHeaderProps([
                        { className: column.className },
                        column.getSortByToggleProps(),
                      ])}
                    >
                      {column.render('Header')}
                      {renderSortIcon(column)}
                    </div>
                  </th>
                )
              })}
            </tr>
          )
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()} key={`cust-tbody-tr-${i}`}>
              {row.cells.map((cell, j) => {
                return (
                  <td {...cell.getCellProps()} key={`cust-tbody-td-${i}-${j}`}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default Table
