import React, { useState, useEffect } from 'react'
import { updateAssets } from '../AssetManagementCrud'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../_metronic/helpers'
import { Formik, Form, Field } from 'formik'
import { EquipmentDropDown } from './EquipmentDropDown'
import { getAllCustomerIds } from '../AssetManagementCrud'
import { SearchDropDown } from './SearchDropDown'
import { Asset, Customer, EquipmentType, formAsset } from '../../../../types'
import toast from 'react-hot-toast'

type Props = {
  modalData: { show: boolean; actionType: string }
  asset: Asset
  isNBS: boolean
  customerProp: Customer[]
  onHide: any
  userId: number | undefined
  getAssets: () => void
  eqTypes: EquipmentType[]
}

const AssetActionModal: React.FC<Props> = (props) => {
  const { modalData, asset, isNBS, customerProp, onHide, userId, getAssets, eqTypes } = props
  const [eqType, setEqType] = useState<string>('')
  const [customerId, setCustomerId] = useState<string>('')
  const [customers, setCustomers] = useState<Customer[]>([])
  const [validate, setValidate] = useState<boolean>(false)
  const [submit, setSubmit] = useState<boolean>(false)

  const initialValues = {
    customer_id: asset.CustomerID,
    make: asset.make,
    model: asset.model,
    serial: asset.serial,
    unit_id: asset.unitid,
    department: asset.dept,
  }

  const handleSubmit = (values: formAsset) => {
    const customerIdPayload = modalData.actionType === 'Edit' ? asset.CustomerID : customerId
    setValidate(false)

    if (customerIdPayload === '') return setValidate(true)

    setSubmit(true)

    const newAsset = {
      dbkey: asset.dbkey,
      userId: userId,
      customer_id: customerIdPayload,
      seq: asset.seq,
      make: values.make,
      model: values.model,
      serial: values.serial,
      unit_id: values.unit_id,
      department: values.department,
      equipment_type: eqType,
      actionType: modalData.actionType,
    }

    updateAssets(newAsset)
      .then((res) => {
        if (res.status === 400) {
          console.log('response', res)
        }
        toast.success(res.data)
        getAssets()
        setSubmit(false)
        onHide(false)
      })
      .catch((error) => {
        if (error.response && error.response.status) {
          if (error.response?.status === 400 && error.response?.data === 'Not Unique') {
            toast.error(`Error: The CustomerID and Serial are non unique.`)
            setSubmit(false)
          }
        } else {
          // Handle errors not coming with a standard HTTP error response
          console.error('Unexpected Error:', error)
          toast.error('An unexpected error occurred')
        }
      })
  }

  const handleSelectEqtype = (eqType: string) => {
    setEqType(eqType)
  }

  const selectCustomer = (value: Customer) => {
    setCustomerId(value.id)
    setValidate(false)
  }

  useEffect(() => {
    if (isNBS)
      getAllCustomerIds().then((res) => {
        setCustomers(res.data)
      })
    else setCustomers(customerProp)
  }, [isNBS, customerProp])

  useEffect(() => {
    setEqType(asset.equipment_type)
  }, [asset])

  return (
    <Modal
      size='lg'
      show={modalData.show}
      dialogClassName='w-50'
      aria-labelledby='contained-modal-title-vcenter'
      onHide={onHide}
      centered
    >
      <Modal.Header>
        <Modal.Title className='w-100' id='contained-modal-title-vcenter'>
          <div className='d-flex justify-content-between'>
            <h2>{modalData.actionType} Asset</h2>
            <span className='text-hover-primary cursor-pointer' onClick={onHide}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-10 pb-15 px-lg-17'>
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
          {({ handleSubmit, errors, touched, values }) => (
            <Form>
              <div className='d-flex flex-column me-n7 pe-7 max-height-60'>
                <div className='fv-row mb-7 fv-plugins-icon-container'>
                  <label className='required fw-bold fs-6 mb-2'>Customer ID</label>
                  <SearchDropDown
                    data={customers}
                    edit={modalData.actionType === 'Edit' ? asset.CustomerID : ''}
                    isNBS={false}
                    selectedValue={selectCustomer}
                  />
                </div>
                <div className='row fv-plugins-icon-container'>
                  <div className='col-lg-6 mb-7'>
                    <label className='fw-bold fs-6 mb-2'>Make</label>
                    <Field
                      as='input'
                      name='make'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                    />
                  </div>
                  <div className='col-lg-6 mb-7'>
                    <label className='fw-bold fs-6 mb-2'>Model</label>
                    <Field
                      as='input'
                      name='model'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                    />
                  </div>
                </div>
                <div className='row fv-plugins-icon-container'>
                  <div className='col-lg-6 mb-7'>
                    <label className='fw-bold fs-6 mb-2'>Serial</label>
                    <Field
                      as='input'
                      name='serial'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                    />
                  </div>
                  <div className='col-lg-6 mb-7'>
                    <label className='fw-bold fs-6 mb-2'>Unit ID</label>
                    <Field
                      as='input'
                      name='unit_id'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                    />
                  </div>
                </div>
                <div className='fv-row mb-7 fv-plugins-icon-container'>
                  <label className='fw-bold fs-6 mb-2'>Department</label>
                  <Field
                    as='input'
                    name='department'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                  />
                </div>
                <div className='fv-row mb-7 fv-plugins-icon-container d-flex align-item-center'>
                  <label className='fw-bold fs-6 mb-2 pt-1'>Equipment type</label>
                  <EquipmentDropDown
                    equipmentType={eqType}
                    equipmentTypes={eqTypes}
                    selectEquipmentType={handleSelectEqtype}
                  />
                </div>
                <div
                  className={`fv-row mb-7 fv-plugins-icon-container ${
                    validate ? 'd-block' : 'd-none'
                  }`}
                >
                  <label className='fs-6 mb-2 pt-1 required text-danger'>
                    Customer id was not selected, please select customer id.
                  </label>
                </div>
              </div>
              <div className='text-center pt-15'>
                <button type='button' className='btn btn-light me-3' onClick={() => onHide(true)}>
                  Discard
                </button>
                <button type='submit' className={`btn ${submit ? 'btn-light' : 'btn-primary'}`}>
                  Submit
                  {submit && (
                    <div className='spinner-border w-17px h-17px ms-2 text-primary' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export { AssetActionModal }
