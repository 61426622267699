import React, { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap-v5'
import { Formik, Form, Field } from 'formik'
import { CustomerFriendlyStatusCodeModel } from '../../../../../types'
import { KTSVG } from '../../../../../_metronic/helpers'
import { updateCustomerFriendlyStatusCodes } from '../CustomerFriendlyStatusCodesCrud'

type Props = {
  show: boolean
  customerFriendlyStatusCode: CustomerFriendlyStatusCodeModel
  onHide: any,
  setCustomerFriendlyStatusCodes: any
}

const CustomerEditSchema = Yup.object().shape({
})

const EditCustomerFriendlyStatusCodeModal: React.FC<Props> = (props) => {
  const { show, customerFriendlyStatusCode, onHide, setCustomerFriendlyStatusCodes } = props

  const initialValues = useMemo(() => customerFriendlyStatusCode, [customerFriendlyStatusCode])

  const [submit, setSubmit] = useState<boolean>(false)

  const handleSubmit = (values: any) => {
    setSubmit(true)

    updateCustomerFriendlyStatusCodes(
      customerFriendlyStatusCode.dbkey_tables,
      customerFriendlyStatusCode.dbkey,
      values.customer_freindly_code,
      values.customer_hover_text
    ).then((res) => {
      toast.success('The customer friendly status code has been updated.')
      setSubmit(false)
      onHide(false)

      setCustomerFriendlyStatusCodes(res.data)
    });
  }

  useEffect(() => {
  }, [initialValues])

  return (
    <Modal
      size='lg'
      show={show}
      aria-labelledby='contained-modal-title-vcenter'
      onHide={() => onHide(true)}
      centered
    >
      <Modal.Header>
        <Modal.Title className='w-100' id='contained-modal-title-vcenter'>
          <div className='d-flex justify-content-between'>
            <h2>Edit Customer Friendly Status Codes</h2>
            <span className='text-hover-primary cursor-pointer' onClick={() => onHide(true)}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-10 pb-15 px-lg-17'>
        <Formik
          validationSchema={CustomerEditSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {({ handleSubmit, setFieldValue, errors, touched, values }) => (
            <Form>
              <div className='d-flex flex-column scroll-y me-n7 pe-7 max-height-60'>
                <div className='fv-row mb-7 fv-plugins-icon-container'>
                  <h5 className='fw-bold mb-2'>{`DB key tables: ${customerFriendlyStatusCode?.dbkey_tables}`}</h5>
                  <h5 className='fw-bold mb-2'>{`DB key: ${customerFriendlyStatusCode?.dbkey}`}</h5>
                </div>
                <div className='row fv-plugins-icon-container'>
                  <div className='col-lg-4 mb-7'>
                    <label className='fw-bold fs-6 required mb-2'>Description</label>
                    <Field
                      as='input'
                      name='description'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                    />
                    {touched.description && errors.description ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{errors.description}</div>
                      </div>
                    ) : null}
                  </div>
                  <div className='col-lg-4 mb-7'>
                    <label className='fw-bold fs-6 required mb-2'>Customer Friendly Code</label>
                    <Field
                      as='input'
                      name='customer_freindly_code'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                    />
                    {touched.customer_freindly_code && errors.customer_freindly_code ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{errors.customer_freindly_code}</div>
                      </div>
                    ) : null}
                  </div>
                  <div className='col-lg-4 mb-7'>
                    <label className='fw-bold fs-6 required mb-2'>Customer Hover Text</label>
                    <Field
                      as='input'
                      name='customer_hover_text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                    />
                    {touched.customer_hover_text && errors.customer_hover_text ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{errors.customer_hover_text}</div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className='text-center pt-15'>
                <button type='button' className='btn btn-light me-3' onClick={() => onHide(true)}>
                  Discard
                </button>
                <button type='submit' className={`btn ${submit ? 'btn-light' : 'btn-primary'}`}>
                  Submit
                  {submit && (
                    <div className='spinner-border w-17px h-17px ms-2 text-primary' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export { EditCustomerFriendlyStatusCodeModal }
