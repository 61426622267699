import NBSAdminForm from './NBSAdminForm'

export interface NBSAdminFormData {
  firstname: string
  lastname: string
  email: string
  level: string
  title: string
  invite_status: string
  quotePrefix: string
  signature: string
}

export default NBSAdminForm
