import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { TableColumn } from 'react-data-table-component'
import { ActionDropDown } from '../customers/component/ActionDropDown'
import { ContactModel } from '../../../types'
import { RootState } from '../../../setup'
import { ConnectedProps, connect } from 'react-redux'
import * as contactRedux from './ContactsRedux'
import DataTableBase from '../../components/DataTableBase'
import ContactModal from './component/ContactModal'
import ContactDialog from './component/ContactDialog'

interface ReduxStateValueProps {
  loading: boolean
  CustomerID: string
  nbsContacts: ContactModel
  handleNew: (state: boolean) => void
  add: boolean
}

const mapState = (state: any) => ({
  nbsContacts: state.nbsContacts.nbsContacts,
  loading: state.nbsContacts.loading,
})

const connector = connect(mapState, { ...contactRedux.actions })

type PropsFromRedux = ConnectedProps<typeof connector>
type ContactsProps = ReduxStateValueProps & PropsFromRedux

const ContactsPage: FC<ContactsProps> = (props) => {
  const { getNBSContacts, nbsContacts, loading, CustomerID, handleNew, add } = props

  const [showModal, setShowModal] = useState<'New' | 'Edit' | 'View' | 'Delete' | 'None'>('None')
  const [contact, setContact] = useState<ContactModel | {}>({})
  // const [showViewModal, setShowViewModal] = useState<boolean>(false)
  const [column, setColumn] = useState('')
  const [sort, setSort] = useState('')

  useEffect(() => {
    getNBSContacts(CustomerID)
  }, [getNBSContacts, CustomerID])

  useEffect(() => {
    if (add) {
      setShowModal('New')
      setContact({ CustomerID })
    }
  }, [add])

  const handleEdit = useCallback((data: ContactModel) => {
    setShowModal('Edit')
    setContact(data)
  }, [])

  const handleView = useCallback((data: ContactModel) => {
    setShowModal('View')
    setContact(data)
  }, [])
  const handleDelete = useCallback((data: ContactModel) => {
    setShowModal('Delete')
    setContact(data)
  }, [])

  const columns: TableColumn<ContactModel>[] = useMemo(
    () => [
      {
        name: 'Actions',
        cell: (row) => (
          <ActionDropDown
            data={row}
            handleEdit={handleEdit}
            handleView={handleView}
            handleDelete={handleDelete}
            canEdit={true}
            canDelete={true}
          />
        ),
        width: '80px',
        style: {
          minWidth: '80px !important',
          maxWidth: '80px !important',
        },
      },
      {
        id: 'First',
        name: 'First',
        selector: (row) => row.FirstName,
        sortable: true,
        width: '12%',
        style: {
          minWidth: '12% !important',
          maxWidth: '12% !important',
        },
      },
      {
        id: 'Last',
        name: 'Last',
        selector: (row) => row.LastName,
        sortable: true,
        width: '12%',
        style: {
          minWidth: '12% !important',
          maxWidth: '12% !important',
        },
      },
      {
        id: 'Email',
        name: 'Email',
        selector: (row) => row.email ?? '',
        sortable: true,
        width: '25%',
        style: {
          minWidth: '25% !important',
          maxWidth: '25% !important',
        },
      },
      {
        id: 'Phone',
        name: 'Phone',
        selector: (row) => row.Phone ?? '',
        sortable: true,
        width: '15%',
        style: {
          minWidth: '15% !important',
          maxWidth: '15% !important',
        },
      },
      {
        id: 'Title',
        name: 'Title',
        selector: (row) => row.Title ?? '',
        sortable: true,
        width: '15%',
        style: {
          minWidth: '15% !important',
          maxWidth: '15% !important',
        },
      },
    ],
    [handleEdit, handleView]
  )

  const handleSort = (column: any, direction: any) => {
    setColumn(column.id)
    setSort(direction)
  }

  return (
    <>
      <DataTableBase columns={columns} data={nbsContacts} progressPending={loading} />
      <ContactDialog
        type={showModal}
        handleClose={() => {
          setShowModal('None')
          handleNew(false)
        }}
        contacts={contact}
      />
    </>
  )
}

export default connector(ContactsPage)
