import React, { useEffect, useState, useRef } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import { PageTitle } from '../../../_metronic/layout/core';
import { getValidCustomerShippingData, getHeatmapInfo } from './TerritoryMapCrud';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.js';
import { KTSVG } from '../../../_metronic/helpers'
import MapFilter from './components/MapFilter';
import { Card } from 'react-bootstrap-v5'
import { CustomerModel, UserModel } from '../../../types';
import { Customer } from '../../../types';
import CustomerDetails from '../nbs-admin/NewNBSAdmin';
import nameToAbbreviation from "us-state-converter" //used to convert our back end state data to 
import { scaleLinear } from 'd3-scale'
import toast from 'react-hot-toast';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../setup';
import { isCustomerTechnician} from '../../../helper/level.helper';
import { QuestionTooltip } from './components/QuestionToolTip';

let customerShippingData: Array<any | null>;

let markerArray: Array<L.Marker | null > = []; // Initialize as an empty array

interface CustomerDetails {
  CustomerID: string;
  CompanyName: string;
  ShipAddres: string;
  ShipCity: string;
  ShipState: string;
  ShipZip: string;
  ShipLatitude: string;
  ShipLongitude: string;
  ShipAddressUpdated: string;
}

interface SelectedCustomers {
  [key: string]: CustomerDetails | undefined;
}


function createRedPin(desiredWidth: number): L.Icon {
  // Descrition and review needed
  const originalWidth = 66;
  const originalHeight = 143;
  const scaleFactor = desiredWidth / originalWidth;
  const desiredHeight = Math.round(originalHeight * scaleFactor);

  const pin = L.icon({
    iconUrl: '/media/logos/pin.png',
    iconSize: [desiredWidth, desiredHeight],
    iconAnchor: [desiredWidth >> 1, desiredHeight], //bitshift of 1 
    popupAnchor: [-3, -76]
  });
  return pin;
}

const TerritoryMapPage: React.FC = () => {

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel


  const [map, setMap] = useState<L.Map | null>(null);
  const [slug, setSlug] = useState<string>('');
  
  const [geojsonData, setGeojsonData] = useState<any>(null); // State for GeoJSON data
  const geoJsonHandlersAddedRef = useRef(false); // Ref to track if handlers have been added
  const geojsonRef = useRef<L.GeoJSON | null>(null);

  const [heatMapStates, setHeatMapStates] = useState<any>([])
  const heatmapStatesRef = useRef(heatMapStates);

  const[isSetHeatMapStates, setIsSetHeatMapStates] = useState<boolean>(false)
  const[isSetHeatMapStatesRef, setIsSetHeatMapStatesRef] = useState<number>(0)

  const [heatMapAverage, setHeatMapAverage] = useState<number>(0)
  const heatMapAverageRef = useRef(0)
  const [heatMapAverageUpdated, setHeatMapAverageUpdated] = useState<boolean>(false)  

  const [currentFilters, setCurrentFilters] = useState({});
  const filterRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const optionsRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const [dropDownCustomers, setDropDownCustomers] = useState<Customer[]>([{ id: '', name: '' }])
  const [dropDownStartDate, setDropDownStartDate] = useState<any>('')
  const [dropDownEndDate, setDropDownEndDate] = useState<any>()
  const [selectedCustomers, setSelectedCustomers] = useState<SelectedCustomers>({});
  const [isDeveloper, setIsDeveloper] = useState<boolean>(false)
  const [isTechnician, setIsTechnician] = useState<boolean>(true)

  const [showPins, setShowPins] = useState<boolean>(true)



  useEffect(() => {
    const userIsTech = isCustomerTechnician(user?.level)
    setIsTechnician(userIsTech)
    if(userIsTech)
      {
        setShowPins(true)
      }
    if(user.id == 2)
      {
        setIsDeveloper(true)
      }
  }, [user])

  useEffect(() => {
    // Fetch the GeoJSON data for US states
    fetch('https://raw.githubusercontent.com/PublicaMundi/MappingAPI/master/data/geojson/us-states.json')
      .then(response => response.json())
      .then(data => {
        setGeojsonData(data); // Set the GeoJSON data
      })
      .catch(error => console.error('Error fetching GeoJSON data:', error));
  }, []);

const addGeoJsonEventListeners = (geojsonLayer: L.GeoJSON) => {
  if( geoJsonHandlersAddedRef.current == true) return
  console.log("I'm making handlers!!!")
  geojsonLayer.eachLayer((layer) => {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: clickState,
    });
  });
  geoJsonHandlersAddedRef.current = true; // Mark handlers as added
};

  useEffect(() => {
    // Fetch the financial data by state
    const fetchHeatmapInfo = async () => {
      try {
        //setHeatMapAverageUpdated(false)
        const response = await getHeatmapInfo(currentFilters);

        //const cleanUpDate = heatMapStates

        if (response && response.data) {
          const data = response.data;

          setHeatMapStates(data); // Set the transformed data
          setIsSetHeatMapStates(true)
          
          if(data.length != 0)
          {
            let average:number = 0;
            data.forEach((currentState: { state: string; totals: number }) => {
              average += Number(currentState.totals);
            });
            average = average / data.length;
            setHeatMapAverage(average)
          }
          else
          {
            toast.error("There is no revenue data associated with this range!")
          }
        }
      } catch (error) {
        console.error('Error fetching financial data:', error);
      }
    };
    if(!isTechnician)
      {
        fetchHeatmapInfo();
      }
  }, [currentFilters, isTechnician]);

useEffect(() => {
  if(isSetHeatMapStates){
    heatmapStatesRef.current = heatMapStates;
    setIsSetHeatMapStatesRef(prev => prev + 1)
  }
}, [heatMapStates, isSetHeatMapStates]);

useEffect(() => {
  if(isSetHeatMapStates){
    heatMapAverageRef.current = heatMapAverage;
    setHeatMapAverageUpdated(true)
    //console.log("heat average", heatMapAverageRef.current)
  }
}, [heatMapAverage]);

  function getColor(stateName: string,from:any): string {
    const stateAbbreviationObject = nameToAbbreviation(stateName);
    console.log("from",from)
    const stateAbbreviation = stateAbbreviationObject?.usps; // Use optional chaining to handle undefined
    // Incorrect
    //console.log("heatMap",heatMapStates)
    // correct
    //console.log("heatMapRef",heatmapStatesRef)
    var stateData = heatmapStatesRef.current.find((item: { state: string, totals: number; }) => {
      return item.state == stateAbbreviation;
    });
    // Correct so far
    if(stateAbbreviation == "MN")
    console.log("StateData",stateData)
    if(stateAbbreviation == "MN")
    console.log("heatMapAverageRef",heatMapAverage)
    const total = stateData ? stateData.totals : 0;

    if(total==0){return '#FFFFFF'}
  // Something serious good found, clicking apply sort twice makes it work.
    const colorScale = scaleLinear()
      .domain([0, 1, 2]) // Scale from 0 to 200% of the average
      //.range(['#4575b4', '#ffffbf', '#d73027']) // Blue to yellow to red
      .range(['#ffffd6', '#5045b4', '#d73027']) // Blue to light blue to yellow to light orange to orange to red color option 1
      // .range(['#ffffbf', '#5045b4', '#d73027']) // collor option 2 //Shades of red
      // .range(['#ffffbf', '#5045b4', '#d73027']) // collor option 3 //light blue to royal purple!
      .clamp(true); // Clamp to ensure colors stay within the defined range
    
    //console.log("State data color selector:", stateData, total, heatMapAverageRef)
    if(stateAbbreviation == "MN")
    console.log("returnedval",colorScale(total / heatMapAverageRef.current))
    return colorScale(total / heatMapAverageRef.current);
  }


  function highlightFeature(e: any) {
    var layer = e.target;
    //console.log("Layer", layer)
    layer.originalStyle = { ...layer.options };

    layer.setStyle({
      weight: 5,
      color: '#666',
      dashArray: '',
      fillOpacity: 0.7
    });
  }
    function calculateStyle(feature: any) {
      const stateName = feature?.properties?.name || '';
      console.log(stateName)
      return {
        fillColor: getColor(stateName,"calc"), // Use the getColor function
        weight: 2,
        opacity: 1,
        color: 'white',
        dashArray: '3',
        fillOpacity: 0.7
      };
    }

    function resetHighlight(e: any) {
      // probably update state data prior 
      const layer = e.target;
      // const stateName = layer.feature?.properties?.name || '';
      // const style = {        
      //   fillColor: getColor(stateName), // Use the getColor function
      //   weight: 2,
      //   opacity: 1,
      //   color: 'white',
      //   dashArray: '3',
      //   fillOpacity: 0.7} 
      // Starts here passing in data at this point we can assume data coming in here is correct its just the state name
      //console.log("reset",layer.feature)
      layer.setStyle(calculateStyle(layer.feature));
    }

    function clickState(e: any) {
      const layer = e.target;
      const stateAbbreviation = nameToAbbreviation(layer.feature?.properties?.name || '').usps;
    
      const stateData = heatmapStatesRef.current.find((item: { state: string; totals: number }) => {
        return item.state === stateAbbreviation;
      });
    
      // Ensure totals is a number before formatting
      const stateTotals = stateData ? Number(stateData.totals) : 0;
    
      // Format the heatMapAverageRef.current and stateData.totals to two decimal places using string formatting
      const formattedAverageRevenue = heatMapAverageRef.current.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    
      const formattedStateRevenue = stateData ? stateTotals.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) : 'No data available';
    
      const differenceFromAverage = stateData ? "%"+((stateTotals/heatMapAverageRef.current * 100) - 100).toFixed(2) : 'No data available'; //gets the average

      // Set the content for the popup with left-aligned text
      const popupContent = `
      <div style="text-align: left; max-width: 100vw;">
        <h4>${layer.feature.properties.name}</h4>
        <ul style="list-style-type: disc; padding-left: 20px;">
          <li>Revenue: ${formattedStateRevenue}</li>
          <li>Average Revenue: ${formattedAverageRevenue}</li>
          <li>Difference From Average: ${differenceFromAverage}</li>
        </ul>
        <p>*Please give us feedback on what stats you would like to see!*</p>
      </div>
    `;
    
      // Bind the popup to the layer and open it
      layer.bindPopup(popupContent, { maxWidth: 600 }).openPopup();
    }


  

  function bindPopupOnClick(marker: L.Marker, customerData: CustomerDetails) {
    const isChecked = !!selectedCustomers[customerData.CustomerID];  // Convert presence of customer data to a boolean
    //see if we can conditionally render it if the 
    let customerPopupInfo = `
      <div style="text-align: left; font-size: 14px; line-height: 1.5;">
        <b>${customerData.CompanyName}</b><br />
        Shipping ID: ${customerData.CustomerID}<br />
        Shipping Address: ${customerData.ShipAddres}<br /> 
        Ship City: ${customerData.ShipCity}<br />
        Ship State: ${customerData.ShipState}<br />
        Ship Zip: ${customerData.ShipZip}<br />
        Ship Latitude: ${customerData.ShipLatitude}<br />
        Ship Longitude: ${customerData.ShipLongitude}<br />
        Ship Address Updated: ${customerData.ShipAddressUpdated}<br /><br />
      </div>
    `;
    return customerPopupInfo;
}
  
  const handleCancelCustomer = () => {
    setTimeout(() => {
      filterRef.current.click()
    }, 200)
  }

  const fetchCustomerData = async (filters: any, from: any) => {
    try {
      if(!showPins)
        {
          if (markerArray && markerArray.length > 0) { 
            markerArray.forEach(marker => {
                if (map && marker) {
                    marker.removeFrom(map);
                    marker = null;
                }
            });
            markerArray.length = 0; //used to help coerce the garbage collector into cleaning up memory.
            customerShippingData.length = 0 //used to help coerce the garbage collector into cleaning up memory.
            //markerArray = [];
          }
          return
        }

        //customerShippingData.length = 0 //used to help coerce the garbage collector into cleaning up memory.

        const response = await getValidCustomerShippingData(filters);
        customerShippingData = response.data.customerDetails;

        if (typeof customerShippingData === 'string') {
            console.log("There are no valid pin locations");
            return;
        }
      
        // Clear existing markers from the map
        if (markerArray && markerArray.length > 0) {
          markerArray.forEach(marker => {
            if (map && marker) {
              marker.removeFrom(map);
              marker = null;
            }
          });
          markerArray.length = 0;
        }

        //const existingMarkers = new Map(markerArray.map(marker => [marker.options.customerID, marker]));

        // Clear existing markers from the map
        if (markerArray && markerArray.length > 0) {
            markerArray.forEach(marker => {
                if (map && marker) {
                    marker.removeFrom(map);
                    marker.off('click'); //unbinds event listener -> can lead to memory leak
                    marker.unbindPopup(); // unbinds popup
                    marker = null;
                }
            });
            markerArray = [];
        }

        customerShippingData.forEach((location: any) => {
            if (location != undefined && location.ShipAddressUpdated) {
                const pin = createRedPin(15);
                const latitude = parseFloat(location.ShipLatitude);
                const longitude = parseFloat(location.ShipLongitude);
                const marker = L.marker([latitude, longitude], { icon: pin });

                marker.on('click', () => {
                    if (marker.getPopup()) {
                        marker.unbindPopup();
                    }

                    const popupContent = bindPopupOnClick(marker, location);
                    /*const popup =*/ marker.bindPopup(popupContent).openPopup();
                    /*
                    popup.on('popupopen', () => {
                        const checkbox = document.getElementById(`select-${location.CustomerID}`) as HTMLInputElement;

                        if (checkbox) {
                            checkbox.addEventListener('change', () => {
                                handleCheckboxChange(
                                    location.CompanyName,
                                    location.CustomerID,
                                    location.ShipAddress,
                                    location.ShipCity,
                                    location.ShipState,
                                    location.ShipZip,
                                    location.ShipLatitude,
                                    location.ShipLongitude,
                                    location.ShipAddressUpdated,
                                    checkbox.checked
                                );
                            });
                        }
                    });
                    */
                });

                if (map) {
                    //marker.addTo(map);
                    markerArray.push(marker);
                    markerArray[markerArray.length-1]?.addTo(map)
                }
            }
        });
    } catch (error) {
        console.error('Error fetching customer data:', error);
    }
};
/*
useEffect(() => {
  if (map === null) {

    return;
  }

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  }).addTo(map);
  
  //console.log(selectedCustomers2)
  fetchCustomerData(currentFilters,"2");
  console.log("Weird one called!!!!!")
}, [selectedCustomers2]);*/
var addtoMap = false

useEffect(() => {
  if (map === null) {
      return;
  }
  console.log("addtomap", addtoMap)
  
  if(addtoMap){
    addtoMap = false

  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  }).addTo(map);
  }

  fetchCustomerData(currentFilters, "1"); 
}, [map, currentFilters, showPins]);

  /*const handleCheckboxChange = (
    CompanyName: string, // Use specific types instead of 'any' for better type checking
    CustomerID: string,
    ShipAddress: string,
    ShipCity: string,
    ShipState: string,
    ShipZip: string,
    ShipLatitude: string,
    ShipLongitude: string,
    ShipAddressUpdated: string,
    isChecked: boolean
  ) => {
    //console.log(isChecked)
    //console.log(CompanyName)
    setSelectedCustomers2(prev => {
      const newState = { ...prev };
      if (isChecked) {
        // Store the full customer data with relevant properties only
        newState[CustomerID] = {
          CompanyName: CompanyName,
          CustomerID: CustomerID,
          ShipAddres: ShipAddress,
          ShipCity: ShipCity,
          ShipState: ShipState,
          ShipZip: ShipZip,
          ShipLatitude: ShipLatitude,
          ShipLongitude: ShipLongitude,
          ShipAddressUpdated: ShipAddressUpdated
          // Add other necessary properties if required
        };
      } else {
        
        // Use 'CustomerID' directly to delete the entry if unchecked
       delete newState[CustomerID];
      }
      return newState;
    });
  };*/
  
  // Adjust handleFilter to use async-await
  const handleFilter = async (dropdownFilters: any, fromSearch: boolean) => {
    function wait(ms: any) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    
    (async () => {
      for (let i = 0; i < 2; i++) {
        if (!fromSearch) {
          // Directly using dropdownFilters to set currentFilters
          const newFilters = { // 1. Filters is getting created here
            searchBar: slug,
            startDate: dropdownFilters.startDate,
            endDate: dropdownFilters.endDate,
            selectedCustomers: dropdownFilters.selectedCustomers
          };
    
          setDropDownCustomers(dropdownFilters.selectedCustomers);
          setDropDownEndDate(dropdownFilters.endDate);
          setDropDownStartDate(dropdownFilters.startDate);
          setCurrentFilters(newFilters);
        } else {
          // If fromSearch is true, use existing dropdown states
          const filters = {
            searchBar: slug,
            startDate: dropDownStartDate,
            endDate: dropDownEndDate,
            selectedCustomers: dropDownCustomers
          };
    
          setCurrentFilters(filters);
        }
        await wait(1000); // Wait for 1 second
      }
    })();
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Territory Map</PageTitle>
      <Card className='min-vh-80'>
        <Card.Header className='border-0 pt-6; padding: 0.5rem 1rem; margin: 0'>
          <Card.Title>
            Territory Map - Beta Version
            <QuestionTooltip 
                hoverText={`This is the Beta version of the Territory Map.
                  Please note, since it is a beta, there may be inaccuracies or performance issues. 

                  Please submit a ticket if you find any issues!

                  In its current state, the map has two views: 

                  The Heatmap view (only available to NBS Admin users) by default grabs all records from the last year using stored quotes and generates a heatmap based on total revenue by state. 
                  
                  The heatmap coloring is based on what percentage of the average the data source is. Currently, there is one coloring mode, Yellow-Blue-Red. 
                  This is a color blind friendly option that scales lowest rev at yellow and highest rev at red. White means there is no sales data for that particular state.
                  
                  The Pins view places a pin on all customer locations using the saved shipping data for each customer. By default it only includes location and identification information. 
                  The locationes rely on the address in the system being accurate, so the actual location of the customer may differ.
                  
                  Planned Features:
                  * More statistics on state revenue.
                  * By-state customer info
                  * More heatmap color options (There is a planned shades of red and light blue to dark purple.)
                  * More filtering options for pins
                  Stretch Features:
                  * Customer route planning using google maps integration
                  
                  ~~Let us know what other features you would like to see~~`}
              />

            {/* //removed search feature!
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-customer-table-filter='search'
                className='form-control form-control-solid w-250px ps-15 border-0'
                placeholder='Search'
                value={slug}
                onChange={(e: any) => setSlug(e.target.value)}
              />
               <button className='btn btn-sm btn-primary' style={{ marginLeft: '10px' }} onClick={() => handleFilter({}, true)}>Search</button>
            </div>
                      */}
          </Card.Title>
          <div className='card-toolbar'>
            <div className='card-toolbar d-flex justify-content-end w-100'> {/* Added w-100 to make the container take full width */}
              <div className='ms-auto'> {/* Changed ms-3 to ms-auto to push the button to the right */}
              <button
                type='button'
                className='btn btn-light-primary me-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Map Options'
                ref={filterRef}
              >
                <KTSVG
                  path='/media/icons/duotone/Text/Toggle-Right.svg'
                  className='svg-icon-2 text-light'
                  />
                  Map Options
                </button>
                <MapFilter
                  handleCancelCustomer={handleCancelCustomer}
                  handleFilter={handleFilter}
                  isDev={isDeveloper}
                  showPins={showPins}
                  setShowPins={setShowPins}
                />
                {/*
                <button
                  type='button'
                  className='btn btn-light-primary me-3'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Filter options'
                  ref={filterRef}
                >
                  <KTSVG
                    path='/media/icons/duotone/Text/Filter.svg'
                    className='svg-icon-2 text-light'
                  />
                  Filter
                </button>
                <MapFilter
                  handleCancelCustomer={handleCancelCustomer}
                  handleFilter={handleFilter}
                  isDev={isDeveloper}
                />
                */}
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body className='pt-0' style={{ padding: '0.5' }}> {/* Adjusted inline styles here */}
          <div>
            <MapContainer
              id="map"
              center={[37.8, -96]}
              zoom={4}
              style={{ height: '70vh' }}
              whenCreated={setMap}
              //</div>maxBounds={[
              //    [5.0, -167.5], // Southwest corner of bounding box (including Hawaii)
              //  [83.0, -40.0]  // Northeast corner of bounding box
              //]} // Restrict the map bounds to North America
              //maxBoundsViscosity={1.0} // How strictly the map adheres to the bounds
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {heatMapStates && geojsonData && isSetHeatMapStates && heatMapAverageUpdated && (isSetHeatMapStatesRef > 0) && (
              <GeoJSON
                data={geojsonData}
                style={(feature) => {
                  const stateName = feature?.properties?.name || '';
                  return {
                    fillColor: getColor(stateName,"geo"), // Use the getColor function
                    weight: 2,
                    opacity: 1,
                    color: 'white',
                    dashArray: '3',
                    fillOpacity: 0.7
                  };
                }}
                /*
                onEachFeature={(feature, layer) => {
                  console.log("New event listener added!!!")
                  layer.on({
                    mouseover: highlightFeature,
                    mouseout: resetHighlight,
                    click: clickState,
                  });
                }}*/
                  ref={(el) => {
                    if (el && !geojsonRef.current) {
                      geojsonRef.current = el;
                      addGeoJsonEventListeners(geojsonRef.current)
                    }
                  }}
              />
            )}
          </MapContainer>
        </div>
      </Card.Body>
    </Card>
  </>
  );
};


export default TerritoryMapPage;