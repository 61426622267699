/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from '../../../../setup'
import { capitalize, toServerUrl } from '../../../helpers'
import { UserModel } from '../../../../types'
import { CUSTOMER_STATUS, USER_TYPES } from '../../../../data/constants'
import { getTimeInCentralTime } from '../../../../helper/time.helper'
import { getAvatar } from '../../../../helper'
import * as auth from '../../../../../src/app/modules/auth/redux/AuthRedux'
import { logout } from '../../../../../src/app/modules/auth/redux/AuthCRUD'

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const dispatch = useDispatch()

  const getHelperText = () => {
    if (user.type === USER_TYPES.NBS) return user.level

    const level = capitalize(user.level)
    let statusText = 'Invite sent on '
    let time = getTimeInCentralTime(user.createdAt)
    if (user.status === CUSTOMER_STATUS.ACTIVE) {
      statusText = capitalize(user?.status) + ' since '
      time = getTimeInCentralTime(user.activatedAt)
    }

    return `${level} - ${statusText} ${time}`
  }

  const doLogout = async () => {
    await logout()
    dispatch(auth.actions.logoutSuccess())
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='me-5'>
            <img
              alt='Logo'
              src={toServerUrl('/media/avatar/' + getAvatar(user.avatar))}
              className='mw-50px mh-50px w-auto h-auto'
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user.firstname} {user.lastname}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2 text-uppercase'>
                {user.type}
              </span>
            </div>
            <a href='#' className='fw-bold text-muted fs-7'>
              {user.title}
            </a>
            <a href='#' className='fw-bold text-muted fs-7 text-uppercase'>
              {getHelperText()}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/crafted/account/overview'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <a className='menu-link px-5' onClick={doLogout}>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
