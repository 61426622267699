import React, { useState, useRef, useEffect } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { Formik, Form, Field } from 'formik'
import { shallowEqual, useSelector } from 'react-redux'
import { KTSVG } from '../../../_metronic/helpers'
import { SelectDropDown } from '../SelectDropDown'
import { getPaymentOptions, createPaymentOption } from '../../services/payment'
import { getCustomerById, getCustomersByOrderId } from '../../services/service'
import { AddCardModal } from '../../modules/accounts/components/AddCardModal'
import { getErrorMessage } from '../../../helper'
import { CONFIRM_APPROVE_CONTENT } from '../../../data/constants'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import * as helper from '../../../helper'
import { TextField } from '@material-ui/core'

// Validation schema
const CustomerEditSchema = Yup.object().shape({
  po: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('PO is required'),
  paymentType: Yup.string().required('Payment Option is required')

})

const initialValues = {
  po: '',
  po1:'',
  paymentType: '',
  hardCopyAvailable: '',
  comment: '',
  attachment: '',
}

const ApproveModal = ({
  submit,
  status,
  quoteNumber,
  quoteId,
  customerId,
  show,
  onHide,
  showConfirmAlert,
}) => {
  const [initValue, setInitValue] = useState(initialValues)
  const [loading, setLoading] = useState(false)
  const [isfileSelected, setIsFileSelected] = useState(false)
  const [showThisModal, setShowThisModal] = useState(true)
  const [showAddModal, setAddModal] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [options, setOptions] = useState([])
  const [customer, setCustomer] = useState()
  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [inValidMessage, setInValidMessage] = useState('')
  const [customers, setCustomers] = useState([])
  const [cardNumber, setCardNumber] = useState('No Number')
  const [create, setCreate] = useState(false)
  const [allFiles, setAllFiles] = useState([])
  const [isAdmin, setIsAdmin] = useState(false)

  const formikRef = useRef()
  const inputFileRef = useRef()

  const user = useSelector(({ auth }) => auth.user, shallowEqual)

  const openInputFile = () => {
    inputFileRef.current.click()

    return false
  }

  const handleCardNumber = (value) => {
    setCardNumber(value)
  }

  const handleChangeFiles = (files) => {
    let fetchFiles = []

    for (let file of files) fetchFiles.push({ name: file.name, size: file.size })

    setSelectedFiles([...selectedFiles, ...fetchFiles])
    setIsFileSelected(true)
  }

  const handleCancelFile = (name) => {
    setSelectedFiles(selectedFiles.filter((file) => file.name !== name))

    let removedFiles = []

    for (let file of allFiles) {
      if (file.name !== name) removedFiles.push(file)
    }

    setAllFiles(removedFiles)
  }

  const handleSubmit = (values) => {
    if (isAdmin && values.paymentType == 'cc' && customers.length === 0) {
      toast.error(
        'You can not approve with the credit card because there is not assigned customer.'
      )
      return
    }

    if (isAdmin && values.paymentType == 'cc' && !selectedCustomer.id) {
      toast.error('Select a customer')
      return
    }

    if (values.po == 'No Number' || values.po == 'Select Number') {
      toast.error('Select card number')
      return
    }

    setLoading(true)
    submit({ customerId: selectedCustomer.id ? selectedCustomer.id : '', ...values })
  }

  const handleHide = () => {
    setAddModal(false)
    setShowThisModal(true)
  }

  const handleSelectCustomer = (value) => {
    let sCustomer = customers.find(
      (c) => c.firstname == value.split(' ')[0] && c.lastname == value.split(' ')[1]
    )
    setSelectedCustomer(sCustomer)

    getPaymentOptions(quoteId, sCustomer.id)
      .then((res) => {
        const cardNumbers = res.data
          .flat()
          .map((options) => options.profile?.payment.creditCard.cardNumber)
          .flat()
        setOptions(cardNumbers)
        setCardNumber(cardNumbers.length === 0 ? 'No Number' : 'Select Number')
        setLoading(false)
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        console.log(errMsg)
      })
  }

  const handleCreate = (payload) => {
    setCreate(true)
    setInValidMessage('')
    createPaymentOption(payload)
      .then((res) => {
        if (typeof res.data === 'string') {
          setCreate(false)
          toast.success(res.data)
          setAddModal(false)
          setShowThisModal(true)
          setLoading(true)

          getPaymentOptions(quoteId, selectedCustomer.id)
            .then((res) => {
              const cardNumbers = res.data
                .flat()
                .map((options) => options.profile?.payment.creditCard.cardNumber)
                .flat()
              setOptions(cardNumbers)
              setCardNumber(
                cardNumbers.length === 0 ? 'No Number' : cardNumbers[cardNumbers.length - 1]
              )
              if (formikRef)
                formikRef.current.setFieldValue(
                  'po',
                  cardNumbers.length === 0 ? 'No Number' : cardNumbers[cardNumbers.length - 1]
                )
              setLoading(false)
            })
            .catch((error) => {
              const errMsg = getErrorMessage(error)
              console.log(errMsg)
            })
        } else {
          setCreate(false)
          setInValidMessage(res.data[0].text)
          toast.error(res.data[0].text)
        }
      })
      .catch((error) => {
        const errMsg = getErrorMessage(error)
        toast.error(errMsg)
      })
  }

  useEffect(() => {
    if (status) setLoading(false)
  }, [status])

  useEffect(() => {
    if (show) {
      setInitValue(initialValues)
    }
  }, [show])

  useEffect(() => {
    if (customerId !== '') {
      if (selectedCustomer.billInfo) {
        setCustomer(selectedCustomer.billInfo)
      } else {
        getCustomerById(customerId).then((res) => {
          setCustomer(res.data[0])
        })
      }
    }
  }, [selectedCustomer, customerId])

  useEffect(() => {
    setLoading(true)
    setShowThisModal(true)
    setAddModal(false)
    setOptions([])
    setIsAdmin(helper.isNBS(user?.type))

    if (quoteId !== 0) {
      if (helper.isNBS(user?.type)) {
        getCustomersByOrderId(quoteId).then((res) => {
          setCustomers(res.data)

          let customerId = 0

          if (res.data.length !== 0) {
            setSelectedCustomer(res.data[0])
            customerId = res.data[0].id
          } else {
            setSelectedCustomer({})
          }
          getPaymentOptions(quoteId, customerId)
            .then((res) => {
              const cardNumbers = res.data
                .flat()
                .map((options) => options.profile?.payment.creditCard.cardNumber)
                .flat()
              setOptions(cardNumbers)
              setCardNumber(cardNumbers.length === 0 ? 'No Number' : cardNumbers[0])
              setLoading(false)
            })
            .catch((error) => {
              const errMsg = getErrorMessage(error)
              console.log(errMsg)
            })
        })
      } else {
        getPaymentOptions(quoteId, user.id)
          .then((res) => {
            const cardNumbers = res.data
              .flat()
              .map((options) => options.profile?.payment.creditCard.cardNumber)
              .flat()
            setOptions(cardNumbers)
            setCardNumber(cardNumbers.length === 0 ? 'No Number' : cardNumbers[0])
            setLoading(false)
          })
          .catch((error) => {
            const errMsg = getErrorMessage(error)
            console.log(errMsg)
          })
      }
    }
  }, [quoteId, user])

  return (
    <>
      <Modal
        size='lg'
        show={show}
        onHide={onHide}
        className={`${!showThisModal && 'opacity-0'}`}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <Modal.Title>Service Quote-Approval</Modal.Title>
          <span className='text-hover-primary cursor-pointer' onClick={onHide}>
            <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
          </span>
        </Modal.Header>
        <Formik
          innerRef={formikRef}
          validationSchema={CustomerEditSchema}
          onSubmit={handleSubmit}
          initialValues={initValue}
        >
          {({ handleSubmit, handleChange, setFieldValue, errors, touched, values }) => (
            <>
              <Modal.Body className='overlay cursor-default'>
                {loading && (
                  <div className='overlay-layer bg-transparent'>
                    <div className='spinner spinner-lg spinner-success' />
                  </div>
                )}
                <Form className='form form-label-right'>
                  <div className='form-group row'>
                    <div className='col-lg-12'>
                      <span>Service Quote: {quoteNumber}</span>
                    </div>
                    <div
                      className={`col-lg-12 mt-10 ${!isAdmin ? 'd-none' : values.paymentType !== 'cc' && 'd-none'
                        }`}
                    >
                      <div className='row'>
                        <div className='col-lg-6'>
                          <span className='required'>Select customer</span>
                          <SelectDropDown
                            data={customers.map((c) => c.firstname + ' ' + c.lastname)}
                            defaultValue={
                              selectedCustomer.firstname
                                ? selectedCustomer.firstname + ' ' + selectedCustomer.lastname
                                : 'No customer assigned'
                            }
                            dirUp={false}
                            selectedValue={(value) => handleSelectCustomer(value)}
                          />
                        </div>
                      </div>
                    </div>
                    {/* PO */}
                    <div className={`col-lg-6 mt-10 ${values.paymentType !== 'cc' && 'd-none'}`}>
                      <span className='required'>Credit Card Number</span>
                      <SelectDropDown
                        isAdmin={isAdmin}
                        data={options}
                        defaultValue={cardNumber}
                        dirUp={false}
                        type='card'
                        selectedValue={(value) => {
                          handleCardNumber(value)
                          setFieldValue('po', value)
                        }}
                      />
                      {touched.po && errors.po ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>Card Number is required</div>
                        </div>
                      ) : null}
                      <div
                        className={`mt-2 text-primary text-decoration-underline cursor-pointer ${customers.length === 0 && 'd-none'
                          }`}
                        onClick={() => {
                          setShowThisModal(false)
                          setAddModal(true)
                        }}
                      >
                        Create new payment option
                      </div>
                    </div>
                    <div className={`col-lg-6 mt-10 ${values.paymentType === 'cc' && 'd-none'}`}>
                      <span className='required'>
                        {values.paymentType === 'cc'
                          ? 'Credit Card Number'
                          : values.paymentType === 'po'
                            ? 'Purchase Order Number'
                            : 'Other'}
                      </span>
                      <Field
                        name='po'
                        as='input'
                        placeholder={
                          values.paymentType === 'cc'
                            ? 'Enter C.C Number'
                            : values.paymentType === 'po'
                              ? 'Enter P.O Number'
                              : 'Other'
                        }
                        className='form-control'
                      />
                      {touched.po && errors.po ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.po}</div>
                        </div>
                      ) : null}
                    </div>
                    {/* Payment Type */}
                    <div className='col-lg-6 mt-10 position-relative'>
                      {values.paymentType === 'cc' && (
                        <span className='fs-7 fst-italic position-absolute bottom-100'>
                          **Credit cards will not be charged until the invoice is processed and the
                          service has completed.
                        </span>
                      )}
                      <span className='required'>Payment Option</span>
                      <select
                        className='form-control'
                        name='paymentType'
                        data-control='select2'
                        label='Payment Type'
                        onChange={handleChange}
                        required
                        // defaultValue=""
                        value={values.paymentType}
                      >
                        <option value="" disabled >Select Payment Option</option>
                        <option value='po'>Purchase Order</option>
                        <option value='cc'>Credit Card</option>
                        <option value='other'>Other</option>
                      </select>
                      {touched.paymentType && errors.paymentType ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.paymentType}</div>
                        </div>
                      ) : null}
                      {
                        values.paymentType === 'cc' && !isAdmin && options.length > 0 ? <TextField label="PO#" onChange={(e) => setFieldValue('po1',e.target.value)} style={{marginTop:"12px"}}  fullWidth/> : ''
                      }
                    </div>
                  </div>

                  {/* Comment */}
                  <div className='form-group mt-5'>
                    <span>Approval Comments</span>
                    <Field
                      name='comment'
                      as='textarea'
                      rows='5'
                      placeholder='Enter Approval Comments'
                      className='form-control'
                    />
                    {touched.comment && errors.comment ? (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{errors.comment}</div>
                      </div>
                    ) : null}
                  </div>

                  {/* Attachment */}
                  <div className='form-group mt-5'>
                    <div className='d-flex justify-content-between'>
                      <span>Attachment</span>
                      <span className='btn btn-primary btn-elevate p-2' onClick={openInputFile}>
                        <KTSVG
                          path='/media/icons/duotone/Files/UPload-folder.svg'
                          className='svg-icon-2 '
                        />
                        <span>Upload Files</span>
                      </span>
                    </div>
                    <input
                      type='file'
                      name='attachment[]'
                      className='form-control d-none'
                      ref={inputFileRef}
                      onChange={(event) => {
                        let files = event.currentTarget.files
                        let attachedFiles = [...allFiles, ...files]
                        let dt = new DataTransfer()

                        attachedFiles.forEach((file) => {
                          dt.items.add(file)
                        })

                        setFieldValue('attachment', dt.files)
                        setAllFiles(attachedFiles)

                        handleChangeFiles(files)
                      }}
                      multiple
                    />
                    <div className='my-5'>
                      {isfileSelected &&
                        selectedFiles?.map((file, index) => {
                          return (
                            <div
                              key={index}
                              className='d-flex justify-content-between p-3 background-light-dark my-2 cursor-pointer'
                            >
                              <span>
                                {file.name} {file.size > 0 && `(${file.size} KB)`}
                              </span>
                              <KTSVG
                                path='/media/icons/duotone/Navigation/Close.svg'
                                className='svg-icon-1'
                                onClick={() => handleCancelFile(file.name)}
                              />
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type='button'
                  onClick={() => {
                    onHide()
                    setIsFileSelected(false)
                    setSelectedFiles([])
                    setAllFiles([])
                  }}
                  className='btn btn-light btn-elevate'
                >
                  Cancel
                </button>
                <> </>
                <button
                  type='submit'
                  onClick={() => {
                    if (values.po !== '' && values.comment !== '') {
                      const paymentType =
                        values.paymentType === 'po'
                          ? 'PO'
                          : values.paymentType === 'other'
                            ? 'Other'
                            : 'CC'
                      const CONFIRM_ALERT_CONTENT = CONFIRM_APPROVE_CONTENT.replace(
                        '@quoteNumber',
                        quoteNumber
                      )
                        .replace('@paymentType', paymentType)
                        .replace('@number', values.po)
                      showConfirmAlert(CONFIRM_ALERT_CONTENT, handleSubmit())
                    } else {
                      handleSubmit()
                    }
                  }}
                  className='btn btn-primary btn-elevate'
                  disabled={loading}
                >
                  {!loading && <span className='indicator-label'>Approve</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
      <AddCardModal
        show={showAddModal}
        onHide={handleHide}
        title='Add New Card'
        invalidMessage={inValidMessage}
        quoteId={quoteId}
        option={options}
        submit={create}
        customer={customer}
        sCustomer={selectedCustomer}
        onCreate={handleCreate}
      />
    </>
  )
}

export default ApproveModal
