import React from 'react'
import { Dropdown } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../_metronic/helpers'
import { remToPx } from '../../../../helper'

type Props = {
  handleOpen: () => void
}

const ActionDropDown = ({ handleOpen }: Props) => {
  return (
    <Dropdown>
      <Dropdown.Toggle id='dropdown-basic' variant='light' size='sm'>
        <KTSVG path='/media/icons/duotone/General/gen052.svg' className='svg-icon-5 m-0' />
      </Dropdown.Toggle>
      <Dropdown.Menu
        popperConfig={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: () => [0, remToPx(0.5)], // mt-2
              },
            },
          ],
        }}
      >
        <Dropdown.Item href='#' onClick={handleOpen}>
          View
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default ActionDropDown
