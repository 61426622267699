/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'

import './CalReport.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/src/stylesheets/datepicker.scss'
import moment from 'moment'
import { nextPrev, showTab, currentTab } from './neededFunctions'
import { useFormik } from 'formik'

const CalReport: React.FC = () => {
  // This function creates the cal report wizzard
  const [startDate, setStartDate] = useState<any>(moment().startOf('day').toDate())
  const [endDate, setEndDate] = useState<any>(moment().endOf('day').toDate())

  // const validate = values =>{
  //   const errors = {
  //     if(!values.)
  //   }
  // }

  const formik = useFormik({
    initialValues: {
      customer: '',
      city: '',
      customerid: '',
      dateofcal: '',
      nextcaldate: '',
      manufacturer: '',
      model: '',
      serial: '',
      equipment_type: '',
      dept: '',
      location: '',
      equipment_id: '',
      capacity: '',
      divisionsize: '',
      unitofmeasure: '',
      scaleclass: '',
      equip_condition_working: '',
      equip_condition_not_working: '',
      equip_condition_clean: '',
      equip_condition_not_clean: '',
      out_of_level: '',
      verified_0: '',
      equipment_ol_1: '',
      equipment_ol_2: '',
      equipment_ol_3: '',
      equipment_ol_4: '',
      pc_site_cal: '',
      pc_in_tolerance: '',
      cust_supplied_tol: '',
      adjust_cal: '',
      adjust_corn: '',
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2))
    },
  })

  useEffect(() => {
    // Load the first tab once the page loads
    showTab(currentTab)
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <form onSubmit={formik.handleSubmit} id='regForm' action=''>
        <p></p>

        <h2>Calibration Report Proof of Concept</h2>

        <div className='tab'>
          {/* Tab 1. customer, city, customerid */}
          <p>
            *Customer:
            <input
              className='form-control'
              name='customer'
              onChange={formik.handleChange}
              value={formik.values.customer}
              placeholder='Customer...'
            ></input>
          </p>
          <p>
            *City
            <input
              className='form-control'
              name='city'
              onChange={formik.handleChange}
              value={formik.values.city}
              placeholder='City...'
            ></input>
          </p>
          <p>
            *Customer ID
            <input
              className='form-control'
              name='customerid'
              onChange={formik.handleChange}
              value={formik.values.customerid}
              placeholder='Customer ID...'
            ></input>
          </p>
        </div>

        <div className='tab'>
          {/* Need to fix add onchange to date selector */}
          <label className='form-label fw-bold'>*Date of Cal:</label>
          <DatePicker
            className='form-control'
            placeholderText='Select a date'
            selected={startDate}
            dateFormat='MM/dd/yy'
            showTimeSelect
            onChange={(date) => {
              setStartDate(date)
            }}
          />

          <label className='form-label fw-bold'>*Next Cal Date:</label>
          <DatePicker
            className='form-control'
            placeholderText='Select a date'
            selected={endDate}
            dateFormat='MM/dd/yy'
            showTimeSelect
            onChange={(date) => {
              setEndDate(date)
            }}
          />

          <p>
            *Technician: <input className='form-control' placeholder='Technition...'></input>
          </p>
        </div>

        <div className='tab'>
          {' '}
          Equipment For Calibration
          <p>
            *Manufacturer<input className='form-control' name='manufacturer' onChange={formik.handleChange} value={formik.values.manufacturer} placeholder='Manufacturer...'></input>
          </p>
          <p>
            *Model Number<input className='form-control' name='model' onChange={formik.handleChange} value={formik.values.model} placeholder='Model Number...'></input>
          </p>
          <p>
            *Serial Number<input className='form-control' name='serial' onChange={formik.handleChange} value={formik.values.serial}  placeholder='Serial Number...'></input>
          </p>
          <p>
            *Equipment Type<input className='form-control' name='equipment_type' onChange={formik.handleChange} value={formik.values.equipment_type} placeholder='Equipment Type...'></input>
          </p>
          <p>
            *Department<input className='form-control' name='dept' onChange={formik.handleChange} value={formik.values.dept}  placeholder='Department...'></input>
          </p>
          <p>
            *Location<input className='form-control' name='location' onChange={formik.handleChange} value={formik.values.location}  placeholder='Location...'></input>
          </p>
          <p>
            *Equipment ID<input className='form-control' name='equipment_id' onChange={formik.handleChange} value={formik.values.equipment_id} placeholder='Equipment ID...'></input>
          </p>
        </div>

        <div className='tab'>
          {' '}
          Equipment For Calibration continued
          <p>
            *Capacity<input className='form-control' name='capacity' onChange={formik.handleChange} value={formik.values.capacity}  placeholder='Manufacturer...'></input>
          </p>
          <p>
            *Division size<input className='form-control' name='divisionsize' onChange={formik.handleChange} value={formik.values.divisionsize}  placeholder='Model Number...'></input>
          </p>
          <p>
            *Unit of Measure<input className='form-control' name='unitofmeasure' onChange={formik.handleChange} value={formik.values.unitofmeasure}  placeholder='Serial Number...'></input>
          </p>
          <p>
            *Scale Class<input className='form-control' name='scaleclass' onChange={formik.handleChange} value={formik.values.scaleclass}  placeholder='Equipment Type...'></input>
          </p>
        </div>

        <div className='tab'>
          {' '}
          <h3>Equipment Condition</h3>
          <label className='checkBoxFloat form-control'>
            Working
            <input
              className='form-check-input w-17 h-17 mt-2'
              type='checkbox'
              name='equip_condition_working'
              onChange={formik.handleChange}
              value={formik.values.equip_condition_working}
            ></input>
          </label>
          <label className='checkBoxFloat form-control'>
            Not Working
            <input
              className='form-check-input w-17 h-17 mt-2'
              type='checkbox'
              name='equip_condition_not_working'
              onChange={formik.handleChange}
              value={formik.values.equip_condition_not_working}
            ></input>
          </label>
          <label className='checkBoxFloat form-control'>
            clean
            <input
              className='form-check-input w-17 h-17 mt-2'
              type='checkbox'
              name='equip_condition_clean'
              onChange={formik.handleChange}
              value={formik.values.equip_condition_clean}
            ></input>
          </label>
          <label className='checkBoxFloat form-control'>
            not clean
            <input
              className='form-check-input w-17 h-17 mt-2'
              type='checkbox'
              name='equip_condition_not_clean'
              onChange={formik.handleChange}
              value={formik.values.equip_condition_not_clean}
            ></input>
          </label>
          <label className='checkBoxFloat form-control'>
            out of level
            <input
              className='form-check-input w-17 h-17 mt-2'
              type='checkbox'
              name='out_of_level'
              onChange={formik.handleChange}
              value={formik.values.out_of_level}
            ></input>
          </label>
        </div>

        <div className='tab'>
          {' '}
          MISC
          <select>
            <option value='yes'>yes</option>
            <option value='no'>no</option>
          </select>
          <label className='checkBoxFloat form-control'>
            *Verified Zero
            <input
              className='form-check-input w-17 h-17 mt-2'
              type='checkbox'
              name='verified_0'
              onChange={formik.handleChange}
              value={formik.values.verified_0}
            ></input>
          </label>
        </div>

        <div className='tab'>
          {' '}
          Performance Checks
          <h1>Wt.1</h1>
          <p>
            *Cal Mass Val<input className='form-control' placeholder='Cal Mass Val...'></input>
          </p>
          <p>
            *As Found<input className='form-control' placeholder='As Found...'></input>
          </p>
          <p>
            *Equip TOl***<input className='form-control' placeholder='Equip Tol...'></input>
          </p>
          <p>
            *In Tol<input className='form-control' placeholder='In Tol...'></input>
          </p>
          <p>
            *As Left<input className='form-control' placeholder='As Left...'></input>
          </p>
          <label className='checkBoxFloat form-control'>
            OverLoad
            <input
              className='form-check-input w-17 h-17 mt-2'
              type='checkbox'
              name='equipment_ol_1'
              onChange={formik.handleChange}
              value={formik.values.equipment_ol_1}
            ></input>
          </label>
        </div>

        <div className='tab'>
          {' '}
          Performance Checks
          <h1>Wt.2</h1>
          <p>
            *Cal Mass Val<input className='form-control' placeholder='Cal Mass Val...'></input>
          </p>
          <p>
            *As Found<input className='form-control' placeholder='As Found...'></input>
          </p>
          <p>
            *Equip TOl***<input className='form-control' placeholder='Equip Tol...'></input>
          </p>
          <p>
            *In Tol<input className='form-control' placeholder='In Tol...'></input>
          </p>
          <p>
            *As Left<input className='form-control' placeholder='As Left...'></input>
          </p>
          <label className='checkBoxFloat form-control'>
            OverLoad
            <input
              className='form-check-input w-17 h-17 mt-2'
              type='checkbox'
              name='equipment_ol_2'
              onChange={formik.handleChange}
              value={formik.values.equipment_ol_2}
            ></input>
          </label>
        </div>

        <div className='tab'>
          {' '}
          Performance Checks
          <h1>Wt.3</h1>
          <p>
            *Cal Mass Val<input className='form-control' placeholder='Cal Mass Val...'></input>
          </p>
          <p>
            *As Found<input className='form-control' placeholder='As Found...'></input>
          </p>
          <p>
            *Equip TOl***<input className='form-control' placeholder='Equip Tol...'></input>
          </p>
          <p>
            *In Tol<input className='form-control' placeholder='In Tol...'></input>
          </p>
          <p>
            *As Left<input className='form-control' placeholder='As Left...'></input>
          </p>
          <label className='checkBoxFloat form-control'>
            OverLoad
            <input
              className='form-check-input w-17 h-17 mt-2'
              type='checkbox'
              name='equipment_ol_3'
              onChange={formik.handleChange}
              value={formik.values.equipment_ol_3}
            ></input>
          </label>
        </div>

        <div className='tab'>
          {' '}
          Performance Checks
          <h1>Wt.4</h1>
          <p>
            *Cal Mass Val<input className='form-control' placeholder='Cal Mass Val...'></input>
          </p>
          <p>
            *As Found<input className='form-control' placeholder='As Found...'></input>
          </p>
          <p>
            *Equip TOl***<input className='form-control' placeholder='Equip Tol...'></input>
          </p>
          <p>
            *In Tol<input className='form-control' placeholder='In Tol...'></input>
          </p>
          <p>
            *As Left<input className='form-control' placeholder='As Left...'></input>
          </p>
          <label className='checkBoxFloat form-control'>
            OverLoad
            <input
              className='form-check-input w-17 h-17 mt-2'
              type='checkbox'
              name='equipment_ol_4'
              onChange={formik.handleChange}
              value={formik.values.equipment_ol_4}
            ></input>
          </label>
        </div>

        <div className='tab'>
          {' '}
          Performance Checks info
          <label className='checkBoxFloat form-control'>
            Site Calibration
            <input
              className='form-check-input w-17 h-17 mt-2'
              type='checkbox'
              name='pc_site_cal'
              onChange={formik.handleChange}
              value={formik.values.pc_site_cal}
            ></input>
          </label>
          <label className='checkBoxFloat form-control'>
            In tolerance
            <input
              className='form-check-input w-17 h-17 mt-2'
              type='checkbox'
              name='pc_in_tolerance'
              onChange={formik.handleChange}
              value={formik.values.pc_in_tolerance}
            ></input>
          </label>
          <label className='checkBoxFloat form-control'>
            Customer supplied tolerances
            <input
              className='form-check-input w-17 h-17 mt-2'
              type='checkbox'
              name='cust_supplied_tol'
              onChange={formik.handleChange}
              value={formik.values.cust_supplied_tol}
            ></input>
          </label>
          <label className='checkBoxFloat form-control'>
            Adjusted Calibration
            <input
              className='form-check-input w-17 h-17 mt-2'
              type='checkbox'
              name='adjust_cal'
              onChange={formik.handleChange}
              value={formik.values.adjust_cal}
            ></input>
          </label>
        </div>

        <div className='tab'>
          {' '}
          Eccentric Load
          <p>
            *Eccentric Load Mass Value<input className='form-control'></input>
          </p>
        </div>

        <div className='tab'>
          {' '}
          Performance Checks
          <h1>LB/RB</h1>
          <p>
            LB *As Found<input className='form-control'></input>
          </p>
          <p>
            RB *As Found<input className='form-control'></input>
          </p>
          <p>
            In Tol<input className='form-control'></input>
          </p>
          <p>
            LB *As Left<input className='form-control'></input>
          </p>
          <p>
            RB *As Left<input className='form-control'></input>
          </p>
        </div>

        <div className='tab'>
          {' '}
          Performance Checks
          <h1>LF/RF</h1>
          <p>
            LF *As Found<input className='form-control'></input>
          </p>
          <p>
            RF *As Found<input className='form-control'></input>
          </p>
          <p>
            In Tol<input className='form-control'></input>
          </p>
          <p>
            LF *As Left<input className='form-control'></input>
          </p>
          <p>
            RF *As Left<input className='form-control'></input>
          </p>
        </div>

        <div className='tab'>
          {' '}
          Performance Checks
          <h1>Other</h1>
          <p>
            Eccentric Load in Tol<input className='form-control'></input>
          </p>
          <p>
            Temp C<input className='form-control'></input>
          </p>
          <p>
            In Tol<input className='form-control'></input>
          </p>
          <label className='checkBoxFloat form-control'>
            Adjusted Cornerload
            <input
              className='form-check-input w-17 h-17 mt-2'
              type='checkbox'
              name='adjust_corn'
              onChange={formik.handleChange}
              value={formik.values.adjust_corn}
            ></input>
          </label>
        </div>

        <div className='tab'>
          {' '}
          Standards used to calibrate your equipment
          <p>
            *Weight set and temp device<input className='form-control'></input>
          </p>
          <p>
            *weight set next due for calibration<input className='form-control'></input>
          </p>
        </div>

        <div className='tab'>
          {' '}
          <h3>At this point the form would have been submitted. Refresh the page to start again!</h3>
          Remarks:
          <p>

            <input className='form-control'></input>
          </p>
        </div>

        <div className='button1'>
          <div className='button2'>
            <button
              className='btn btn-primary ms-3'
              type='button'
              id='prevBtn'
              onClick={(event) => nextPrev(-1)}
            >
              Previous
            </button>
            <button
              className='btn btn-primary ms-3'
              type='button'
              id='nextBtn'
              onClick={(event) => nextPrev(1)}
            >
              Next
            </button>
          </div>
        </div>

        <div className='stepSyle'>
          <span className='step'></span>
          <span className='step'></span>
          <span className='step'></span>
          <span className='step'></span>
          <span className='step'></span>
          <span className='step'></span>
          <span className='step'></span>
          <span className='step'></span>
          <span className='step'></span>
          <span className='step'></span>
          <span className='step'></span>
          <span className='step'></span>
          <span className='step'></span>
          <span className='step'></span>
          <span className='step'></span>
          <span className='step'></span>
          <span className='step'></span>
        </div>
      </form>
    </div>
  )
}

export { CalReport }
