import axios from 'axios'

export const GET_REPORTS_API_URL = `${process.env.REACT_APP_API_URL}/accredit-reports`

export function getAccreditReports(searchFilter = {}) {
    return axios.get(`${GET_REPORTS_API_URL}`, { params: searchFilter })
}

export function getAccreditReportsCustomerIds() {
    return axios.get(`${GET_REPORTS_API_URL}/getCustomerIds`)
}

export function updateAccreditedCrud(id: string | number, values: any) {
    const API_URL = `${GET_REPORTS_API_URL}/UpdateAccredited`
    console.log(id,values)
    return axios.post(API_URL, { ...values, id });
  }