import {CUSTOMER_PHONE_TYPE} from '../data/constants'
import {CustomerID, UserModel} from '../types'

const getPrimaryPhone = (user: UserModel) => {
  if (!user?.primaryPhone) return ''

  switch (user.primaryPhone) {
    case CUSTOMER_PHONE_TYPE.WORK_CELL:
      return user?.workCell
    case CUSTOMER_PHONE_TYPE.WORK_PHONE:
      return user?.workPhone
    case CUSTOMER_PHONE_TYPE.OTHER:
      return user?.other
  }
}

const getCompanyName = (customerIds: any) => {
  let result: any = {}
  customerIds.forEach((customerId: CustomerID) => {
    result[customerId.companyName] = result[customerId.companyName]
      ? result[customerId.companyName] + ', ' + customerId.customerId
      : customerId.customerId
  })

  return Object.keys(result)
    .map((c: any) => `${c}(${result[c]})`)
    .join(', ')
}

const getUniqueCompanyNames = (customerIds: any) => {
  if (!customerIds) return ''

  let result: string[] = []
  customerIds.forEach((customerId: CustomerID) => {
    result.push(customerId.companyName)
  })

  return [...new Set(result)].join(', ')
}
export {getPrimaryPhone, getCompanyName, getUniqueCompanyNames}
