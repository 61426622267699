/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
import React, { FC, useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Card } from 'react-bootstrap-v5'
import { PageTitle } from '../../../_metronic/layout/core'
import { TableColumn } from 'react-data-table-component'
import { UserModel, BillingCompany } from '../../../types'
import { KTSVG } from '../../../_metronic/helpers'
import { RootState } from '../../../setup'
import { IAuthState } from '../../modules/auth'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import { ActionDropDown } from './component/ActionDropDown'
import { ConfirmModal } from '../../components/_modal/ConfirmModal'
import { BillingCompanyModal } from './component/BillingCompanyModal'
import DataTableBase from '../../components/DataTableBase'
import FilterDropDown from './component/FilterDropDown'
import * as BillingCompanyRedux from './BillingCompanyRedux'

import { isNBS } from '../../../helper/level.helper'
import { STATES } from '../../../data/constants'
import { getBillingCompaniesWithSpecCol, getBillingCompanyID } from './BillingCompanyCRUD'
interface ReduxStateValueProps {
  user: UserModel | undefined
  billingCompanies: BillingCompanyRedux.IBillingCompanyResponse
  loading: boolean
  userId: number | undefined
  auth: IAuthState & PersistPartial
}

const mapState = (state: RootState) => ({
  user: state.auth?.user,
  billingCompanies: state.billingCompany.billingCompanies,
  loading: state.billingCompany.loading,
  customerIds: state.auth.activeCustomerIds,
  auth: state.auth,
})

const connector = connect(mapState, { ...BillingCompanyRedux.actions })

type PropsFromRedux = ConnectedProps<typeof connector>
type BillingComapaniesProps = ReduxStateValueProps & PropsFromRedux

const BillingCompanyPage: FC<BillingComapaniesProps> = (props) => {
  const { getBillingCompanies, billingCompanies, loading } = props
  const initialFilter = {
    status: ['active', 'inactive'],
    isReset: false,
  }

  const initialBillingCompany = useMemo(() => {
    return {
      CompanyName: '',
      BillingCompanyID: '',
      BillAttn_email: '',
      BillPhone: '',
      BillFax: '',
      BillAddress: '',
      BillAddress2: '',
      BillCity: '',
      BillState: '',
      BillZip: '',
      BillNotes: '',
      taxExempt: 'No',
      netTerms: 30,
      expirationDate: '',
    }
  }, [])
  const [perPage, setPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(1)
  const [slug, setSlug] = useState<string>('')
  const [column, setColumn] = useState('')
  const [sort, setSort] = useState('')
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [allBillingCompanies, setAllBillingCompanies] = useState<BillingCompany[]>([])
  const [currentBillingCompany, setCurrentBillingCompany] =
    useState<BillingCompany>(initialBillingCompany)
  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false)
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const [isViewMode, setIsViewMode] = useState<boolean>(false)

  const filterRef = useRef() as React.MutableRefObject<HTMLButtonElement>

  const handleEdit = useCallback((billingCompany: BillingCompany) => {
    setIsShowModal(true)
    setIsViewMode(false)
    setCurrentBillingCompany({
      ...billingCompany,
      taxExempt: billingCompany.taxExempt === 1 ? 'Yes' : 'No',
      expirationDate: billingCompany.expirationDate?.toString(),
    })
  }, [])

  const handleView = useCallback((billingCompany: BillingCompany) => {
    setIsShowModal(true)
    setIsViewMode(true)
    setCurrentBillingCompany({
      ...billingCompany,
      taxExempt: billingCompany.taxExempt === 1 ? 'Yes' : 'No',
      expirationDate: billingCompany.expirationDate?.toString(),
    })
  }, [])

  const handleCancelCompany = () => {
    setTimeout(() => {
      filterRef.current.click()
    }, 200)
  }

  const handleCloseModal = useCallback((isShowConfirm: boolean | undefined) => {
    if (isShowConfirm) {
      setIsShowConfirmModal(true)
    } else {
      setIsShowModal(false)
      setIsShowModal(false)
    }
  }, [])

  const handleConfirmCancel = () => {
    setIsShowModal(false)
    setIsShowConfirmModal(false)
  }

  const handleConfirmReturn = () => {
    setIsShowConfirmModal(false)
  }

  const columns: TableColumn<BillingCompany>[] = useMemo(
    () => [
      {
        name: 'Actions',
        cell: (row) => (
          <ActionDropDown
            data={row}
            handleEdit={handleEdit}
            handleView={handleView}
            canEdit={isAdmin}
          />
        ),
        width: '100px',
        style: {
          minWidth: '100px !important',
          maxWidth: '100px !important',
        },
      },
      {
        id: 'BillingCompanyID',
        name: 'Company ID',
        selector: (row) => row.BillingCompanyID ?? '',
        sortable: true,
        width: '10%',
        style: {
          minWidth: '10% !important',
          maxWidth: '10% !important',
        },
      },
      {
        id: 'CompanyName',
        name: 'Company Name',
        selector: (row) => row.CompanyName ?? '',
        sortable: true,
        width: '15%',
        style: {
          minWidth: '15% !important',
          maxWidth: '15% !important',
        },
      },
      {
        id: 'BillAttn_email',
        name: 'Billing Attention',
        selector: (row) => `${row.BillAttn_email ?? ''}`,
        sortable: true,
        width: '15%',
        style: {
          minWidth: '15% !important',
          maxWidth: '15% !important',
        },
      },
      {
        id: 'BillPhone',
        name: 'Billing Phone',
        selector: (row) => row.BillPhone ?? '',
        sortable: true,
        width: '10%',
        style: {
          minWidth: '10% !important',
          maxWidth: '10% !important',
        },
      },
      {
        id: 'BillFax',
        name: 'Fax Number',
        selector: (row) => row.BillFax ?? '',
        sortable: true,
        width: '10%',
        style: {
          minWidth: '10% !important',
          maxWidth: '10% !important',
        },
      },
      {
        id: 'BillCity',
        name: 'City',
        selector: (row) => `${row.BillCity ?? ''}`,
        sortable: true,
        width: '9%',
        style: {
          minWidth: '9% !important',
          maxWidth: '9% !important',
        },
      },
      {
        id: 'BillState',
        name: 'State',
        selector: (row) => `${row.BillState ?? ''}`,
        sortable: true,
        width: '6%',
        style: {
          minWidth: '6% !important',
          maxWidth: '6% !important',
        },
      },
      {
        id: 'BillZip',
        name: 'Zip Code',
        selector: (row) => `${row.BillZip ?? ''}`,
        sortable: true,
        width: '10%',
        style: {
          minWidth: '10% !important',
          maxWidth: '10% !important',
        },
      },
      {
        id: 'BillAddress',
        name: 'Address',
        selector: (row) => `${row.BillAddress ?? ''}`,
        sortable: true,
        width: 'calc(15% - 120px)',
        style: {
          minWidth: 'calc(15% - 120px) !important',
          maxWidth: 'calc(15% - 120px) !important',
        },
      },
    ],
    [isAdmin, handleEdit, handleView]
  )

  useEffect(() => {
    getBillingCompanies(page, perPage, slug, column, sort)
  }, [page, perPage, slug, column, sort])

  // useEffect(() => {
  //   setAllBillingCompanies(billingCompanies)
  // }, [billingCompanies])

  const handleFilter = (filters: any) => {
    const { selectedCompanies, isReset } = filters
    if (isReset === true) {
      getBillingCompanies(page, perPage, slug, column, sort)
    } else {
      let filterIds: string[] = []
      selectedCompanies.forEach((company: BillingCompany) => {
        filterIds.push(company.BillingCompanyID)
      })

      getBillingCompanies(page, perPage, slug, column, sort, filterIds)
    }
  }

  useEffect(() => {
    getBillingCompaniesWithSpecCol().then((res) => {
      setAllBillingCompanies(res.data)
    })
  }, [])

  useEffect(() => {
    setIsAdmin(isNBS(props.user?.type))
  }, [])

  const handleSort = (column: any, direction: any) => {
    setColumn(column.id)
    setSort(direction)
  }

  const debounce = (func: Function, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  
  const handleSearch = (value: string) => {
    setSlug(value);
  }

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 500), []);

  return (
    <>
      <PageTitle breadcrumbs={[]}>Billing Company</PageTitle>
      <Card className='min-vh-100'>
        <Card.Header className='border-0 pt-6'>
          <Card.Title>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-customer-table-filter='search'
                className='form-control form-control-solid w-250px ps-15 border-0'
                placeholder='Search'
                onChange={(e: any) => debouncedHandleSearch(e.target.value)}
              />
            </div>
          </Card.Title>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-customer-table-toolbar='base'>
              {isAdmin && (
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={() => {
                    getBillingCompanyID()
                      .then((res) => {
                        console.log('res===', res.data)
                        setCurrentBillingCompany({
                          ...initialBillingCompany,
                          BillingCompanyID: res.data,
                        })
                        setIsShowModal(true)
                        setIsViewMode(false)
                      })
                      .catch((err) => {
                        console.log(err)
                      })
                  }}
                >
                  <KTSVG path='/media/icons/duotone/Navigation/Plus.svg' className='svg-icon-2' />
                  New Billing Company
                </button>
              )}
              <div className='filter-dropdown ms-3'>
                <button
                  type='button'
                  className='btn btn-light-primary me-3'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Filter options'
                  ref={filterRef}
                >
                  <KTSVG
                    path='/media/icons/duotone/Text/Filter.svg'
                    className='svg-icon-2 text-light'
                  />
                  Filter
                </button>
                <FilterDropDown
                  companies={allBillingCompanies}
                  handleFilter={handleFilter}
                  handleCancelCompany={handleCancelCompany}
                  initialFilterStatus={initialFilter.status}
                />
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body className='pt-0 service-quotes-body'>
          <DataTableBase
            columns={columns}
            data={billingCompanies.data}
            progressPending={loading}
            pagination
            onSort={handleSort}
            paginationTotalRows={billingCompanies.total}
            paginationPerPage={perPage}
            paginationDefaultPage={1}
            paginationServer
            sortServer
            onChangePage={(m, n) => {
              setPage(m)
            }}
            onChangeRowsPerPage={(e, n) => {
              setPerPage(e)
            }}
          />
        </Card.Body>
      </Card>
      {isShowConfirmModal && (
        <ConfirmModal
          content='Are you sure you would like to cancel?'
          yesText='Yes, cancel it!'
          noText='No, return'
          selectYes={handleConfirmCancel}
          selectNo={handleConfirmReturn}
        />
      )}
      <BillingCompanyModal
        billingCompanies={billingCompanies.data}
        isShow={isShowModal}
        isViewMode={isViewMode}
        onHide={handleCloseModal}
        billingCompany={currentBillingCompany}
        getBillingCompanies={() => getBillingCompanies(page, perPage, slug, column, sort)} fetchBillingCompanies={function (): void {
          throw new Error('Function not implemented.')
        } } handleChangeCompanyId={function (companyId: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void): void {
          throw new Error('Function not implemented.')
        } } setCustomerFieldValue={function (field: string, value: any, shouldValidate?: boolean | undefined): void {
          throw new Error('Function not implemented.')
        } }      />
      {/* <ViewCustomerModal show={showViewModal} customer={currentBillingCompany} onHide={handleCloseModal} /> */}
    </>
  )
}

export default connector(BillingCompanyPage)
