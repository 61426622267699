import React from 'react'
import {connect} from 'react-redux'
import {UserModel} from '../../../types'
import {RootState} from '../../../setup'
import {Link, Redirect, useParams} from 'react-router-dom'
import * as levelHelper from '../../../helper/level.helper'


type Props = {
  nbsAdmins: UserModel[]
  
}
type ID = {
  id: string
}
const ViewNBSAdmin: React.FC<Props> = ({nbsAdmins}) => {
  const {id} = useParams<ID>()
  const nbsAdmin = nbsAdmins.find((nbsAdmin: UserModel) => nbsAdmin.id === parseInt(id))
  //const isAdmin =

  if (!id) return <Redirect to='/dashboard' />

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
        <Link to={`/nbs-admins/edit/${nbsAdmin?.id}`} className='btn btn-primary align-self-center'>
          Edit Profile
        </Link>
         
        </div>

      <div className='card-body p-9'>
        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>Full Name</label>

          <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-dark'>{`${nbsAdmin?.firstname} ${nbsAdmin?.lastname}`}</span>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 fw-bold text-muted'>Email</label>

          <div className='col-lg-8'>
            <span className='fw-bolder fs-6 me-2'> {nbsAdmin?.email}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapState = (state: RootState) => ({
  nbsAdmins: state.nbsAdmins?.nbsAdmins,
})
const connector = connect(mapState, null)
export default connector(ViewNBSAdmin)
