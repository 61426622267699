import axios from "axios";

export const APP_URL = process.env.REACT_APP_API_URL;
export const QUOTES_URL = `${process.env.REACT_APP_API_URL}/sales-quotes`;
export const ACTION_QUOTES_URL = `${process.env.REACT_APP_API_URL}/service-sales-quotes`;
export const APPROVE_QUOTE_URL = `${process.env.REACT_APP_API_URL}/sales-quotes/approve`

export function getSalesQuotes(searchFilter: any = {}) {
  return axios.post(QUOTES_URL, { searchFilter });
}

export function updateSalesQuote(values: any) {
  const API_URL = `${ACTION_QUOTES_URL}/${values.dbkey}}`

  return axios.post(API_URL, values)
}

export function getQuoteFilterStatus() {
  return axios.get(`${process.env.REACT_APP_API_URL}/quote-filter-status`)
}

export function openSalesQuotePdf(values: any) {
  const API_URL = `${APP_URL}/get-quote-view-url/${values.dbkey}`

  return axios.get(API_URL)
}

export function approveQuote(id: number, values: any) {
  let formData = new FormData()
  formData.append('quoteId', id.toString())

  for (let i = 0; i < values.attachment.length; i++) {
    formData.append(`attachment[${i}]`, values.attachment[i])
  }

  Object.keys(values).forEach((key) => formData.append(key, values[key]))

  return axios.post(APPROVE_QUOTE_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function rejectQuote(values: any = {}) {
  return axios.post(`${QUOTES_URL}/reject`, { values })
}

export function commentQuote(values: any = {}) {
  return axios.post(`${QUOTES_URL}/comment`, { values })
}

export function getQuoteAttachment(dbkey: number) {
  const API_URL = `${APP_URL}/get-quote-attachment/${dbkey}`

  return axios.get(API_URL)
}

export function getTrnTypes(){
  const API_URL = `${APP_URL}/NBSTablesData/get/trnTypes`

  return axios.get(API_URL)
}
