import React, { FC } from 'react'
import { Dropdown } from 'react-bootstrap-v5'

type Props = {
  extension: string
  extensions: string[]
  selectExtension: (extension: string) => void
}

const ExtensionDropDown: FC<Props> = (props) => {
  const { extension, extensions, selectExtension } = props

  return (
    <Dropdown>
      <Dropdown.Toggle id='dropdown-basic' className='w-100' variant='light'>
        {extension}
      </Dropdown.Toggle>
      <Dropdown.Menu className='w-100 text-center'>
        {extensions.map((et, index) => {
          return (
            <Dropdown.Item
              key={index}
              className='py-2'
              href='#'
              onClick={() => {
                selectExtension(et)
              }}
            >
              {et}
            </Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export { ExtensionDropDown }
