const USER_TYPES = {
  NBS: 'nbs',
  CUSTOMER: 'customer',
}

const CUSTOMER_LEVELS = {
  ADMIN: 'admin',
  APPROVER: 'approver',
  VIEWER: 'viewer',
  TECHNICIAN: 'technician',
}

const CUSTOMER_STATUS = {
  ACTIVE: 'active',
  INVITED: 'invited',
  INACTIVE: 'inactive',
}

const CUSTOMER_PHONE_TYPE = {
  WORK_CELL: 'work_cell',
  WORK_PHONE: 'work_phone',
  OTHER: 'other',
}

const NBS_ADMIN_LEVEL = {
  ADMIN: 'admin',
  TECHINICIAN: 'technician',
}

const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again later.'
const CONFIRM_SUBMIT = 'Are you sure to submit?'
const CONFIRM_APPROVE_CONTENT =
  'You are about to approve quote # @quoteNumber. A new order with @paymentType # @number will be created and will be submitted to our billing department. Would you like to continue?'
const CONFIRM_UPDATE_CONTENT =
  'You are about to update payment option of order # @quoteNumber. Would you like to continue?'

const CONFIRM_BILLING_COMPLETE =
  'You are about to complete billing of order # @quoteNumber. Would you like to continue?'

const CONFIRM_CHARGE_PAYMENT =
  'You are about to pay payment of order # @quoteNumber. Card Number: @cardNumber and Amount: @amount. Would you like to continue?'
const REQUEST_SUPPORT_TITLE =
  'Please enter the information below. Be very specific with details so we can efficiently fix any problems that you have encountered.'

const STATES = [
  {
    name: 'Alabama',
    code: 'AL',
  },
  {
    name: 'Alaska',
    code: 'AK',
  },
  {
    name: 'Arizona',
    code: 'AZ',
  },
  {
    name: 'Arkansas',
    code: 'AR',
  },
  {
    name: 'California',
    code: 'CA',
  },
  {
    name: 'Colorado',
    code: 'CO',
  },
  {
    name: 'Connecticut',
    code: 'CT',
  },
  {
    name: 'Delaware',
    code: 'DE',
  },
  {
    name: 'Florida',
    code: 'FL',
  },
  {
    name: 'Georgia',
    code: 'GA',
  },
  {
    name: 'Hawaii',
    code: 'HI',
  },
  {
    name: 'Idaho',
    code: 'ID',
  },
  {
    name: 'Illinois',
    code: 'IL',
  },
  {
    name: 'Indiana',
    code: 'IN',
  },
  {
    name: 'Iowa',
    code: 'IA',
  },
  {
    name: 'Kansas',
    code: 'KS',
  },
  {
    name: 'Kentucky',
    code: 'KY',
  },
  {
    name: 'Louisiana',
    code: 'LA',
  },
  {
    name: 'Maine',
    code: 'ME',
  },
  {
    name: 'Maryland',
    code: 'MD',
  },
  {
    name: 'Massachusetts',
    code: 'MA',
  },
  {
    name: 'Michigan',
    code: 'MI',
  },
  {
    name: 'Minnesota',
    code: 'MN',
  },
  {
    name: 'Mississippi',
    code: 'MS',
  },
  {
    name: 'Missouri',
    code: 'MO',
  },
  {
    name: 'Montana',
    code: 'MT',
  },
  {
    name: 'Nebraska',
    code: 'NE',
  },
  {
    name: 'Nevada',
    code: 'NV',
  },
  {
    name: 'New Hampshire',
    code: 'NH',
  },
  {
    name: 'New Jersey',
    code: 'NJ',
  },
  {
    name: 'New Mexico',
    code: 'NM',
  },
  {
    name: 'New York',
    code: 'NY',
  },
  {
    name: 'North Carolina',
    code: 'NC',
  },
  {
    name: 'North Dakota',
    code: 'ND',
  },
  {
    name: 'Ohio',
    code: 'OH',
  },
  {
    name: 'Oklahoma',
    code: 'OK',
  },
  {
    name: 'Oregon',
    code: 'OR',
  },
  {
    name: 'Pennsylvania',
    code: 'PA',
  },
  {
    name: 'Rhode Island',
    code: 'RI',
  },
  {
    name: 'South Carolina',
    code: 'SC',
  },
  {
    name: 'South Dakota',
    code: 'SD',
  },
  {
    name: 'Tennessee',
    code: 'TN',
  },
  {
    name: 'Texas',
    code: 'TX',
  },
  {
    name: 'Utah',
    code: 'UT',
  },
  {
    name: 'Vermont',
    code: 'VT',
  },
  {
    name: 'Virginia',
    code: 'VA',
  },
  {
    name: 'Washington',
    code: 'WA',
  },
  {
    name: 'West Virginia',
    code: 'WV',
  },
  {
    name: 'Wisconsin',
    code: 'WI',
  },
  {
    name: 'Wyoming',
    code: 'WY',
  },
]

export {
  USER_TYPES,
  CUSTOMER_PHONE_TYPE,
  CUSTOMER_LEVELS,
  DEFAULT_ERROR_MESSAGE,
  CUSTOMER_STATUS,
  NBS_ADMIN_LEVEL,
  CONFIRM_SUBMIT,
  CONFIRM_APPROVE_CONTENT,
  CONFIRM_UPDATE_CONTENT,
  CONFIRM_BILLING_COMPLETE,
  CONFIRM_CHARGE_PAYMENT,
  REQUEST_SUPPORT_TITLE,
  STATES,
}
