import { USER_TYPES, CUSTOMER_LEVELS,NBS_ADMIN_LEVEL } from '../data/constants'

const isNBS = (type: string | undefined) => {
    const result = type === USER_TYPES.NBS;
    return result;
  };

const isNBSAdmin = (type: string | undefined) => type == NBS_ADMIN_LEVEL.ADMIN

const isCustomerAdmin = (level: string | undefined) => level === CUSTOMER_LEVELS.ADMIN

const isCustomerTechnician = (level: string | undefined) => level === CUSTOMER_LEVELS.TECHNICIAN

const isCustomerViewer = (level: string | undefined) => level === CUSTOMER_LEVELS.VIEWER

const isOnlyViewable = (level: string | undefined) => isCustomerTechnician(level) || isCustomerViewer(level)

export { isNBS, isCustomerAdmin, isCustomerTechnician, isCustomerViewer, isOnlyViewable, isNBSAdmin }
