import React, { useState, useRef, useEffect, useMemo } from 'react'
import { Accordion, Modal } from 'react-bootstrap-v5'
import { Formik, Form, Field } from 'formik'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
// import { SelectDropDown } from '../SelectDropDown'
// import { getPaymentOptions, createPaymentOption } from '../../services/payment'
// import { getCustomerById, getCustomersByOrderId } from '../../services/service'

// import { AddCardModal } from '../../modules/accounts/components/AddCardModal'
import { getErrorMessage } from '../../../../helper'
import toast from 'react-hot-toast'
import * as Yup from 'yup'
import * as helper from '../../../../helper'
import { KTSVG } from '../../../../_metronic/helpers'
import { SelectDropDown } from '../../../components/SelectDropDown'
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Dialog,
  TextField,
  Typography,
} from '@material-ui/core'
import ReactDatePicker from 'react-datepicker'
// import { addOtherCalReports, getCustomers } from '../OtherCalReportsCrud'
import moment from 'moment'
import * as CalReportsRedux from '../CalibrationReportsRedux'
// import * as OtherCalReportsRedux from '../../other-cal-reports/OtherCalReportsRedux'
// import * as OtherCalReportsRedux from '../OtherCalReportsRedux'
import { getAccreditReportsCustomerIds } from '../../accredit-reports/AccreditReportsCrud'
import {
  getCalibrationRepotCustomerIds,
  getCalibrationTypes,
} from '../../calibration-reports/CalibrationReportsCrud'
import * as levelHelper from '../../../../helper/level.helper'
import { Autocomplete, Skeleton, createFilterOptions } from '@material-ui/lab'
import { GET_REPORTS_API_URL,  addOtherCalReports,  getCustomers,} from '../../other-cal-reports/OtherCalReportsCrud'
import SearchDropDown from '../../admin-configuration-setting/component/SearchDropDown'
import { RootState } from '../../../../setup'
import { CalibrationReportModel, UserModel } from '../../../../types'
import { getAllUsers } from '../../admin-configuration-setting/ReceiveAlertsCrud'
import axios from 'axios'
// Validation schema
const CustomerEditSchema = Yup.object().shape({
  customer_id: Yup.string().required('CustomerID is required'),
  calibration_date: Yup.date().required('Calibration Date is required'),
  calibration_type: Yup.string().required('Calibration Type Option is required'),
  // technician: Yup.string().required('Technician is required'),
  // mfg: Yup.string().required('MFG is required'),
  // model: Yup.string().required('Model is required'),
  // serial: Yup.string().required('Serial is required'),
})

type Props = {
  handleClose: () => void
  data: CalibrationReportModel
  type: 'add' | 'edit'
  open: boolean
}

export function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number; count: number }
) {
  return (
    <Box position='relative' display='inline-flex'>
      {props.count > 0 ? (
        <CircularProgress
          variant='determinate'
          color='secondary'
          size={60}
          {...props}
          value={(props.value / props.count) * 100}
        />
      ) : (
        <CircularProgress variant='determinate' {...props} />
      )}
      {/* <CircularProgress variant='determinate' {...props} /> */}
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        {props.count > 0 ? (
          <Typography variant='body1' component='div' style={{ color: 'white' }}>
            {props.value} / {props.count}
          </Typography>
        ) : (
          <Typography variant='body1' component='div' style={{ color: 'white' }}>{`${Math.round(
            props.value
          )}%`}</Typography>
        )}
      </Box>
    </Box>
  )
}

const AddCalReportModal = ({
  handleClose,
  data,
  open,
  type,
}: // submit,
// status,
// orderId,
// show,
// onHide,
// actions,
// customerId,
// showConfirmAlert,
Props) => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const initialValues = useMemo(() => {
    if (type === 'edit' && data.dbkey_id) {
      return {
        calibration_date: data.dateofcal,
        customer_id: data.customerid,
        calibration_type: data.caltype,
        shipping_company_name: data.customer,
        mfg: data.mfg ? data.mfg : '',
        model: data.model ?? '',
        technician: data.technician_fullname ?? 1,
        attachment: data.attachment ?? '',
        serial: data.serial ?? '',
        id: data.dbkey_id,
      }
    } else {
      return {
        calibration_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        customer_id: '',
        calibration_type: '',
        shipping_company_name: '',
        mfg: '',
        model: '',
        serial: '',
        attachment: '',
        technician: user.id,
      }
    }
  }, [data.dbkey_id, type, user.id])
  // po: '',
  // attachmentFile: '',

  // const [initValue, setInitValue] = useState(initialValues)
  const [loading, setLoading] = useState(false)
  const [showThisModal, setShowThisModal] = useState(true)
  const [showAddModal, setAddModal] = useState(false)
  const [options, setOptions] = useState([])
  const [cardNumber, setCardNumber] = useState('No Number')
  const [inValidMessage, setInValidMessage] = useState('')
  const [calibration_type, setPaymentType] = useState()
  const [create, setCreate] = useState(false)
  const [customer, setCustomer] = useState()
  // const [selectedCustomer, setSelectedCustomer] = useState({})
  const [customers, setCustomers] = useState<any>([])
  const [allFiles, setAllFiles] = useState<any>([])
  const [selectedFiles, setSelectedFiles] = useState<any>([])
  const [attachmentFileNames, setAttachmentFileNames] = useState<any>([])
  const [isAdmin, setIsAdmin] = useState(false)
  const [selectedCustomers, setSelectedCustomers] = useState<any>([])
  const [isNBS, setIsNBS] = useState(false)
  const [value, setValue] = useState<any>('')
  const [selectedUserId, setSelectedUserId] = useState<string>('')
  const [users, setUsers] = useState<any>([])
  const [nbsCustomerIds, setNbsCustomerIds] = useState<string[]>(['nbs'])
  const [nbsCustomerNames, setNbsCustomerNames] = useState<string[]>(['nbs'])
  const [caltype, setCaltype] = useState<{ name: string }[]>([])
  const [date, setDate] = useState<any>()
  const [uploading, setUploading] = useState<boolean>(false)
  const [process, setProcess] = useState<number>(0)
  const [removedFiles, setRemovedFiles] = useState<string[]>([])

  const [autoCompleteDisabled, setAutoCompleteDisabled] = useState<boolean>(false)

  const dispatch = useDispatch()

  const formikRef: any = useRef()
  const inputFileRef: any = useRef()
  const filter = createFilterOptions()
  
  const auth: any = useSelector<RootState>(({ auth }) => auth, shallowEqual)
  const openInputFile = () => {
    inputFileRef.current?.click()

    return false
  }

  // const handleSelect = (customer: any) => {
  //   const isExist = selectedCustomers.find(
  //     (c: any) => c.id === customer.id && c.name === customer.name
  //   )

  //   if (!isExist) setSelectedCustomers([...selectedCustomers, ...[customer]])
  //   // setIsReset(false)
  // }

  const handleCancel = (customerId: number) => {
    setSelectedCustomers(selectedCustomers.filter((customer: any) => customer.id !== customerId))
    // handleCancelCustomer()
  }

  useEffect(() => {
    if (open) {
      setIsNBS(levelHelper.isNBS(user?.type))

      if (levelHelper.isNBS(user?.type)) {
        setSelectedCustomers([])
        // if (accredited) {

        //     getAccreditReportsCustomerIds().then((res) => {
        //         setCustomers(
        //             res.data.map((d) => {

        //                 return {
        //                     id: d,
        //                     name: d,
        //                 }
        //             })
        //         )
        //     })
        // } else {

        getCustomers().then((res: any) => {
          // console.log('res==', res)
          setCustomers(
            res.data.map((d: any) => {
              return {
                id: d.CustomerID,
                name: d.ShipCompanyName,
              }
            })
          )
        })
        // }
      } else {
        let uCustomers: any[] = []

        if (user.customerIds) {
          user.customerIds.forEach((c: any) => {
            uCustomers.push({ id: c.customerId, name: c.companyName })
          })
        }
        setCustomers(uCustomers)
      }
    }
  }, [user, open])

  const handleSubmit = (values: any) => {
    let formData = new FormData()
    if (values.attachment) {
      let size = 0
      let length = 0
      let files =
        typeof values.attachment === 'string' ? JSON.parse(values.attachment) : values.attachment

        for (let i = 0; i < files.length; i++) {
        // removedFiles.map((item) => {
        if (!removedFiles.includes(files[i].name)) {
          size += files[i].size
          length += 1
          formData.append(`attachment[${i}]`, files[i])
        }
      }
      // )
      // }
      if (length > 100) {
        toast.error("you can't upload above 100 files")
        return
      }
      if (size > 250000000) {
        toast.error("you can't upload above 250MB files")
        return
      }
    }
    setUploading(true)

    Object.keys(values).forEach((key) => formData.append(key, values[key]))
    axios
      .post(`${GET_REPORTS_API_URL}/add`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress(progressEvent) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          // console.log(`${percentCompleted}%`)
          setProcess(percentCompleted)
        },
      })
      .then((res: any) => {
        if (values.attachment) {
          toast.success('Upload successful!')
        }
        // handleClose()
        dispatch(
          CalReportsRedux.actions.getCalibrationReports({
            customerIds: levelHelper.isNBS(user?.type) ? nbsCustomerIds : auth.customerIds,
            nbsCustomerNames,
            from: '',
            to: '',
          })
        )
      })
      .catch((err: any) => {
        toast.error('Something is wrong!')
      })
      .finally(() => {
        setUploading(false)
        handleClose()
      })
  }

  const handleFileSize = (files: any) => {
    let size = 0

    files.map((item: any) => {
      size += item.size
    })
    return Math.round((size * 100) / (1024 * 1024)) / 100
  }

  const handleHide = () => {
    setAddModal(false)
    setShowThisModal(true)
    setSelectedFiles([])
  }

  const handlePaymentOption = (e: any) => {
    formikRef.current.setFieldValue('calibration_type', e.target.value)
    setPaymentType(e.target.value)
  }

  const handleChangeFiles = (files: any) => {
    let fetchFiles = []
    let fileNames = []

    for (let file of files) {
      fetchFiles.push({ name: file.name, size: file.size })
      fileNames.push(file.name)
    }

    setSelectedFiles([...selectedFiles, ...fetchFiles])
    setAttachmentFileNames([...attachmentFileNames, ...fileNames])
  }

  const handleCancelFile = (name: any) => {
    setSelectedFiles(selectedFiles.filter((file: any) => file.name !== name))
    setAttachmentFileNames(attachmentFileNames.filter((fn: any) => fn !== name))
    let removed = removedFiles

    removed.push(name)
    setRemovedFiles(removed)

    // setRemovedFiles([...removedFiles,])

    let canceledFiles: any = []

    for (let file of allFiles) {
      if (file.name !== name) canceledFiles.push(file)
    }

    setAllFiles(canceledFiles)
  }

  const handleCreate = (payload: any) => {
    setCreate(true)
    setInValidMessage('')
  }

  useEffect(() => {
    // setLoading(true)
    setShowThisModal(true)
    setAddModal(false)
    setSelectedFiles([])
    // setSelectedCustomer({})
    setOptions([])
    setIsAdmin(helper.isNBS(user?.type))
    setCardNumber('No Number')

    let customerId = 0
    let selectedCustomerId = -1
  }, [user])

  useEffect(() => {
    getAllUsers(user.id).then((res) => {
      res.data = res.data.filter((user: { status: string }) => user.status === 'A');
      setUsers(res.data)
      setSelectedUserId(user.id.toString()) //defaults to the currently logged in user.
    })
  }, [user.id])

  useEffect(() => {
    getCalibrationTypes().then((res) => {
      setCaltype(res.data.data)
    })
  }, [])

  useEffect(() => {
    setAutoCompleteDisabled(false)
    formikRef.current.setFieldValue('technician', selectedUserId)
  }, [selectedUserId])

  const selectUser = (value: any) => {
    setSelectedUserId(value)
    //formikRef.current.setFieldValue('technician', value)
  }
  return (
    <>
      <Modal.Header>
        <Modal.Title> Upload Reports</Modal.Title>
        <span className='text-hover-primary cursor-pointer' onClick={handleClose}>
          <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
        </span>
      </Modal.Header>
      <Formik
        innerRef={formikRef}
        validationSchema={CustomerEditSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit, setFieldValue, errors, touched, values }) => {
          return (
            <>
              <Modal.Body className='overlay cursor-default scroll-y'>
                {loading && (
                  <div className='overlay-layer bg-transparent'>
                    <div className='spinner spinner-lg spinner-success' />
                  </div>
                )}
                <Form className='form form-label-right'>
                  <div className='form-group row'>
                    <div className='row'>
                      {users.length > 0 ? (
                        <div className='col-lg-12'>
                          <label className='required fw-bold fs-6 mb-2'>Author</label>
                          <SearchDropDown
                            data={users}
                            selectedValue={selectUser}
                            defaultValue={selectedUserId}
                          />
                          {touched.technician && errors.technician ? (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{errors.technician}</div>
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <Skeleton variant='text' style={{ fontSize: '3rem' }} />
                      )}
                      <div className='col-lg-6'>
                        <label className='required fw-bold fs-6 mb-2'>Select Customer</label>
                        <div>
                          {customers.length > 0 ? (
                            <Autocomplete
                              disabled={autoCompleteDisabled}
                              defaultValue={''}
                              value={
                                value
                                  ? value
                                  : {
                                      id: initialValues.customer_id,
                                      name: initialValues.shipping_company_name,
                                    }
                              }
                              onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                  setValue({
                                    name: newValue,
                                  })
                                } else if (newValue && newValue.inputValue) {
                                  // Create a new value from the user input
                                  setValue({
                                    name: newValue.inputValue,
                                  })
                                } else {
                                  setValue(newValue)
                                  setFieldValue('customer_id', newValue?.id)
                                  setFieldValue('shipping_company_name', newValue?.name)
                                }
                              
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params)

                                // Suggest the creation of a new value
                                if (params.inputValue !== '') {
                                  filtered.push({
                                    inputValue: params.inputValue,
                                    name: `Add "${params.inputValue}"`,
                                  })
                                }

                                return filtered
                              }}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              id='free-solo-with-text-demo'
                              options={customers}
                              getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                  return option
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return option.inputValue
                                }
                                // Regular option
                                return option.id && option.name ? `${option.id}-${option.name}` : ''
                              }}
                              renderOption={(option) => `${option.id}-${option.name}`}
                              style={{ width: 300, marginTop: 3 }}
                              freeSolo
                              renderInput={(params) => {
                                return <TextField {...params} variant='outlined' />
                              }}
                            />
                          ) : (
                            <Skeleton variant='text' style={{ fontSize: '3rem' }} />
                          )}
                        </div>
                        {touched.customer_id && errors.customer_id ? (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{errors.customer_id}</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='col-lg-6 mt-10 position-relative'>
                      <span className='required'>Calibration Type</span>
                      <select
                        className='form-control'
                        name='calibration_type'
                        data-control='select2'
                        required
                        onChange={handlePaymentOption}
                        defaultValue={initialValues.calibration_type}
                        value={values.calibration_type}
                      >
                        <option value='' disabled>
                          Select Calibration Type
                        </option>
                        {caltype.map((item, index) => (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                        {/* <option value='Calibration'>Calibration</option>
                        <option value='Thermometer'>Thermometer</option> */}
                      </select>
                      {touched.calibration_type && errors.calibration_type ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.calibration_type}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className={`col-lg-6 mt-10`}>
                      <span className='required'>Calibration Date Submitted</span>
                      <ReactDatePicker
                        dateFormat='MM/dd/yy hh:mma'
                        showTimeSelect
                        onChange={(date: any) => {
                          // console.log('date==', date)
                          setDate(date)
                          setFieldValue(
                            'calibration_date',
                            moment(date).format('YYYY-MM-DD HH:mm:ss')
                          )
                        }}
                        onSelect={(date) => {
                          setDate(date)
                          setFieldValue(
                            'calibration_date',
                            moment(date).format('YYYY-MM-DD HH:mm:ss')
                          )
                        }}
                        selected={
                          date
                            ? date
                            : type === 'edit'
                            ? new Date(initialValues.calibration_date)
                            : new Date()
                        }
                      />
                      {touched.calibration_date && errors.calibration_date ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.calibration_date}</div>
                        </div>
                      ) : null}
                    </div>
                    {/* <div className='col-lg-6 mt-10'>
                      <label className=' fw-bold fs-6 mb-2'>MFG</label>
                      <Field name='mfg' type='text' />
                    </div>
                    <div className='col-lg-6 mt-10'>
                      <label className=' fw-bold fs-6 mb-2'>Model</label>
                      <Field name='model' type='text' />
                    </div>

                    <div className='col-lg-6 mt-10'>
                      <label className=' fw-bold fs-6 mb-2'>Serial</label>
                      <Field name='serial' type='text' />
                    </div> */}
                    {/* Payment Type */}
                  </div>
                  {/* {type === 'add' && ( */}
                  <div className='form-group mt-5'>
                    <div className='d-flex justify-content-between'>
                      <span>Attachment</span>
                      <span className='btn btn-primary btn-elevate p-2' onClick={openInputFile}>
                        <KTSVG
                          path='/media/icons/duotone/Files/UPload-folder.svg'
                          className='svg-icon-2 '
                        />
                        <span>Upload Files</span>
                      </span>
                    </div>
                    <input
                      type='file'
                      name='attachment[]'
                      className='form-control d-none'
                      ref={inputFileRef}
                      onChange={(event) => {
                        let files: any = event.currentTarget.files
                        let attachedFiles = [...allFiles, ...files]
                        let dt = new DataTransfer()

                        attachedFiles.forEach((file) => {
                          dt.items.add(file)
                        })

                        setFieldValue('attachment', dt.files)
                        setAllFiles(attachedFiles)

                        handleChangeFiles(files)
                      }}
                      multiple
                    />
                    <div className='my-5'>
                      {selectedFiles?.map((file: any, index: number) => {
                        let fileSize = 0
                        fileSize += file.size
                        //console.log(fileSize + " | " + file.size)
                        return (
                          <div
                            key={index}
                            className='d-flex justify-content-between p-3 background-light-dark my-2 cursor-pointer'
                            onClick={() => handleCancelFile(file.name)}
                          >
                            <span>
                              {file.name}
                              {'    '}
                            </span>
                            <div>
                              <span>
                                {Math.round((file.size * 100) / (1024 * 1024)) / 100}Mbyte
                              </span>

                              <KTSVG
                                path='/media/icons/duotone/Navigation/Close.svg'
                                className='svg-icon-1'
                              />
                            </div>
                          </div>
                        )
                      })}
                      <span className='form-text fs-6 text-muted'>
                        The maximum number of files you can upload is 100, and the total combined
                        file size must not exceed 250MB.{' '}
                      </span>
                      {selectedFiles.length > 0 && (
                        <Typography>
                          Selected file size is {handleFileSize(selectedFiles)}Mbyte and files is{' '}
                          {selectedFiles.length}
                        </Typography>
                      )}
                    </div>
                  </div>
                  {/* )} */}
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type='button'
                  onClick={() => {
                    // onHide()
                    setSelectedFiles([])

                    handleClose()
                  }}
                  className='btn btn-light btn-elevate'
                >
                  Cancel
                </button>
                <> </>
                <button
                  type='submit'
                  onClick={() => {
                    handleSubmit()
                  }}
                  className='btn btn-primary btn-elevate'
                  disabled={loading}
                >
                  {!loading && <span className='indicator-label'>Submit</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </Modal.Footer>
            </>
          )
        }}
      </Formik>
      <Dialog
        open={uploading}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <CircularProgressWithLabel value={process} count={0} />
      </Dialog>
    </>
  )
}

export default AddCalReportModal
