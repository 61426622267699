import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Accordion } from 'react-bootstrap-v5'
import { Customer, UserModel, BillingCompany } from '../../../../types'
import { getAllCustomerIds } from '../../../pages/asset-mangement/AssetManagementCrud'
import { SearchDropDown as CustomerSearchDropDown } from '../../../components/SearchDropDown'
import { SearchDropDown as BillingCompanySearchDropDown } from '../../billing-companies/component/SearchDropDown'
import { RootState } from '../../../../setup'
import CustomToggle from '../../../components/CustomToggle'
import FilterList from '../../../components/FilterList'
import * as levelHelper from '../../../../helper/level.helper'
import 'react-datepicker/src/stylesheets/datepicker.scss'
import { KTSVG } from '../../../../_metronic/helpers'
import { getBillingCompaniesWithSpecCol } from '../../billing-companies/BillingCompanyCRUD'
// import * as billingActions from '../../billing-companies/BillingCompanyRedux'

type FilterDropDownProps = {
  handleFilter: (filter: any) => void
  handleCancelCustomer: () => void
  handleCancelCompany: () => void
  companies?: BillingCompany[]

  initialFilterStatus?: any
}

const FilterDropDown: React.FC<FilterDropDownProps> = ({
  handleFilter,
  handleCancelCustomer,
  handleCancelCompany,
  initialFilterStatus = ['active'],
  companies,
}) => {
  const [isNBS, setIsNBS] = useState<boolean>(false)
  const [isReset, setIsReset] = useState<boolean>(false)
  const [customers, setCustomers] = useState<Customer[]>([{ id: '', name: '' }])
  const [status, setStatus] = useState<Array<string>>(initialFilterStatus)
  const [selectedCustomers, setSelectedCustomers] = useState<
    { id: string; name: string | undefined }[]
  >([])
  const [selectedCompanies, setSelectedCompanies] = useState<BillingCompany[]>([])

  const filterStatus = ['active', 'inactive']
  const dispatch = useDispatch()

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const handleChange = (state: string, isChecked: boolean) => {
    if (isChecked === true) setStatus([...status, state])
    else setStatus(status.filter((s) => s !== state))
  }

  const handleCustomerSelect = (customer: { id: string; name: string | undefined }) => {
    const isExist = selectedCustomers.find((c) => c.id === customer.id)

    if (!isExist) setSelectedCustomers([...selectedCustomers, ...[customer]])
    setIsReset(false)
  }

  const handleCustomeCancel = (customerId: string) => {
    setSelectedCustomers(selectedCustomers.filter((customer) => customer.id !== customerId))
    handleCancelCustomer()
  }

  const handleCompanySelect = (company: BillingCompany) => {
    const isExist = selectedCompanies.find((c) => c.BillingCompanyID === company.BillingCompanyID)

    if (!isExist) setSelectedCompanies([...selectedCompanies, ...[company]])
    setIsReset(false)
  }

  const handleCompanyCancel = (companyId: string) => {
    setSelectedCompanies(
      selectedCompanies.filter((company) => company.BillingCompanyID !== companyId)
    )
    handleCancelCompany()
  }

  const handleReject = () => {
    setStatus(initialFilterStatus)
    setIsReset(true)
    setSelectedCompanies([])

    handleFilter({
      isReset: true,
    })
  }

  useEffect(() => {
    setIsNBS(levelHelper.isNBS(user?.type))
  }, [user])

  useEffect(() => {
    if (isNBS) {
      getAllCustomerIds().then((res) => {
        setCustomers(res.data)
      })
      // dispatch(billingActions.actions.getBillingCompanies())

      // getBillingCompanies().then((res) => {
      //   setCompanies(res.data)
      // })
    }
  }, [isNBS, dispatch])

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>
      <div className='separator border-gray-200'></div>
      <div className='px-7 py-5'>
        <Accordion defaultActiveKey='0'>
          {isNBS && (
            <>
              <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
                <CustomToggle eventKey='filter-0'>Customer ID</CustomToggle>
              </div>
              <Accordion.Collapse eventKey='filter-0' className='ms-8'>
                <>
                  <div>
                    {selectedCustomers?.map((customer, index) => {
                      return (
                        <div
                          key={index}
                          className='d-flex justify-content-between px-3 py-2 background-light-dark my-2 cursor-pointer'
                        >
                          <span>
                            {customer.id} - {customer?.name}
                          </span>
                          <span
                            className='text-hover-primary cursor-pointer'
                            onClick={() => handleCustomeCancel(customer.id)}
                          >
                            <KTSVG
                              path='/media/icons/duotone/Navigation/Close.svg'
                              className='svg-icon-1'
                            />
                          </span>
                        </div>
                      )
                    })}
                  </div>
                  <CustomerSearchDropDown
                    data={customers}
                    edit={isReset ? 'reset' : ''}
                    isNBS={isNBS}
                    selectedValue={(customer) => handleCustomerSelect(customer)}
                  />
                </>
              </Accordion.Collapse>
              <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
                <CustomToggle eventKey='filter-2'>Billing Company ID</CustomToggle>
              </div>
              <Accordion.Collapse eventKey='filter-2' className='ms-8'>
                <>
                  <div>
                    {selectedCompanies?.map((company, index) => {
                      return (
                        <div
                          key={index}
                          className='d-flex justify-content-between px-3 py-2 background-light-dark my-2 cursor-pointer'
                        >
                          <span>
                            {company.BillingCompanyID} - {company?.CompanyName}
                          </span>
                          <span
                            className='text-hover-primary cursor-pointer'
                            onClick={() => handleCompanyCancel(company.BillingCompanyID)}
                          >
                            <KTSVG
                              path='/media/icons/duotone/Navigation/Close.svg'
                              className='svg-icon-1'
                            />
                          </span>
                        </div>
                      )
                    })}
                  </div>
                  <BillingCompanySearchDropDown
                    data={companies}
                    edit={isReset ? 'reset' : ''}
                    isNBS={isNBS}
                    selectedValue={(company) => handleCompanySelect(company)}
                  />
                </>
              </Accordion.Collapse>
            </>
          )}
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='filter-1'>Status</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='filter-1' className='ms-8'>
            <ul className='list-unstyled'>
              {filterStatus.map((state, index) => {
                return (
                  <FilterList
                    key={`status-${index}`}
                    status={state}
                    isChecked={!!status.includes(state)}
                    handleChange={handleChange}
                  />
                )
              })}
            </ul>
          </Accordion.Collapse>
          <div className='separator border-gray-200'></div>
          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-white btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
              onClick={handleReject}
            >
              Reset
            </button>
            <button
              type='button'
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={() => {
                setIsReset(false)
                handleFilter({
                  selectedCustomers,
                  selectedCompanies,
                  status,
                })
              }}
            >
              Apply
            </button>
          </div>
        </Accordion>
      </div>
    </div>
  )
}

export default FilterDropDown
