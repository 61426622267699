import { Action } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { Asset } from '../../../types'
import { getAssets } from './AssetManagementCrud'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  AssetsRequested: '[Request Assets] Action',
  AssetsLoaded: '[Load Assets] Action',
}

const initialAssetsState: IAssetsState = {
  loading: false,
  assets: [],
  pagination: [],
}

export interface IAssetsState {
  loading: boolean
  assets: any
  pagination: any
}

export const reducer = (
  state: IAssetsState = initialAssetsState,
  action: ActionWithPayload<IAssetsState>
) => {
  switch (action.type) {
    case actionTypes.AssetsRequested: {
      return { ...state, loading: true }
    }
    case actionTypes.AssetsLoaded: {
      const assets = action.payload?.assets.data
      const pagination = action.payload?.pagination
      return { loading: false, assets, pagination}
    }

    default:
      return state
  }
}

export const actions = {
  getAssets: (filter: any = {}) => ({
    type: actionTypes.AssetsRequested,
    payload: { filter },
  }),
  assetsLoaded: (assets: Asset[], pagination: any) => ({
    type: actionTypes.AssetsLoaded,
    payload: { assets, pagination },
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.AssetsRequested, function* AssetsRequested(action: any) {
    const { filter } = action.payload;
    const { data: assets, pagination: pagination } = yield getAssets(filter); // Assuming pagination data is returned along with assets

    yield put(actions.assetsLoaded(assets, pagination));
  });
}
