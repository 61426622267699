import axios from 'axios'
import { fileType } from '../../../types';

export const GET_REPORTS_API_URL = `${process.env.REACT_APP_API_URL}/other-cal-reports`

export function getOtherCalReports(searchFilter = { sort: 'asc', column: 'id' }) {
    return axios.get(`${GET_REPORTS_API_URL}/get`, { params: searchFilter })
}

export function addOtherCalReports(data: any) {
  // let formData = new FormData();
  // for (let i = 0; i < data.attachment.length; i++) {
  //     formData.append(`attachment[${i}]`, data.attachment[i])
  // }

  // Object.keys(data).forEach((key) => formData.append(key, data[key]))

  return axios.post(`${GET_REPORTS_API_URL}/add`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function getAttachment(id: number) {
    return axios.get(`${GET_REPORTS_API_URL}/getAttachment/${id}`)
}

export function uploadOtherAttachment(
  quoteId: number,
  oldAttachment: fileType[],
  files: any,
  onProgress: any
) {
  let formData = new FormData()

  formData.append('quoteId', JSON.stringify(quoteId))
  formData.append('oldAttachment', JSON.stringify(oldAttachment))

  for (let i = 0; i < files.files.length; i++) {
    formData.append(`files[${i}]`, files.files[i])
  }

  Object.keys(files).forEach((key) => formData.append(key, files[key]))

  return axios.post(`${GET_REPORTS_API_URL}/uploadAttachment`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      onProgress(percentCompleted)
    },
  })
}


export function getCustomers() {
    return axios.get(`${GET_REPORTS_API_URL}/getCustomers`)
}
export function deleteReport(id: number) {
    return axios.get(`${GET_REPORTS_API_URL}/deleteReport/${id}`)
}

export function getOtherCalReportAttachments(searchFilter ={}) {
  return axios.get(`${GET_REPORTS_API_URL}/getFiles`,{params:searchFilter})
}

export function deleteAttachments(
  deleteFiles: { quoteId: number; name: string; calibration_date: string }[]
) {
  let formData = new FormData()
  deleteFiles.map((item, index) => {
    formData.append(`quoteId[${index}]`, JSON.stringify(item.quoteId))
    formData.append(`name[${index}]`, JSON.stringify(item.name))
    formData.append(`calibration_date[${index}]`, JSON.stringify(item.calibration_date))
  })

  return axios.post(`${GET_REPORTS_API_URL}/deleteAttachment`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

// export function getAccreditReportsCustomerIds() {
//     return axios.get(`${GET_REPORTS_API_URL}/getCustomerIds`)
// }
