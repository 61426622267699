import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap-v5';
import DashboardCompletedOrders from '../dashboard/CompletedOrders'
import { PageTitle } from '../../../_metronic/layout/core';
/*
This page will only show the Orders to be paid section
*/
const CompletedOrdersPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    
  }, []);
  return (
    <><PageTitle breadcrumbs={[]}>Orders to be Paid</PageTitle>
    <Card className="min-vh-100">
          <Card.Header className="border-0 pt-10">
              <Card.Title>Completed Orders</Card.Title>
          </Card.Header>
          <Card.Body>
              <DashboardCompletedOrders />
          </Card.Body>
      </Card>
      </>
  );
};

export default CompletedOrdersPage;