import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { checkIsActive, KTSVG } from '../../../helpers'
import { useLayout } from '../../core'
import { CustomerID } from '../../../../types'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  data?: CustomerID
  blank?: boolean
}

const AsideMenuItem: React.FC<Props> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  blank = false,
  hasBullet = false,
  data
}) => {
  const { pathname } = useLocation()
  const isActive = checkIsActive(pathname, to)
  const { config } = useLayout()
  const { aside } = config

  return (
    <div className='menu-item'>
      {
        data ? <>
          <form target='_blank' action={"http://calibration.northernbalance.com/report-list/"} method="post">
            <input style={{ display: 'none' }} placeholder='hello' type="text" name="FromCustomerIdShipping" value={data.IdShipping} />
            <button className={clsx('menu-link without-sub ms-8', { active: isActive })} style={{ background: 'none', border: 'none' }} type="submit" value={`${data?.customerId}-${data?.companyName}`} >
              <span className='menu-title'> {`${data?.customerId}-${data?.companyName}`}</span>
            </button>
          </form>
        </> :
          <>
            <Link className={clsx('menu-link without-sub', { active: isActive })} to={blank ? { pathname: to } : to} target={blank ? '_blank' : '_self'}>
              {hasBullet && (
                <span className='menu-bullet'>
                  <span className='bullet bullet-dot'></span>
                </span>
              )}
              {icon && aside.menuIcon === 'svg' && (
                <span className='menu-icon'>
                  <KTSVG path={icon} className='svg-icon-2' />
                </span>
              )}
              {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
              <span className='menu-title'>{title}</span>
            </Link>
            {children}
          </>
      }
    </div>
  )
}

export { AsideMenuItem }
