import axios from 'axios'

export const APP_URL = process.env.REACT_APP_API_URL

export async function createPaymentOption(payload: any) {
  const Service_URL = `${APP_URL}/payment-option/${payload.isAdd ? 'create' : 'edit'}`

  return axios.post(Service_URL, { payload })
}

export async function getPaymentOptions(quoteId = 0, customerId = 0) {
  const Service_URL = `${APP_URL}/payment-option/get-profiles/${quoteId}/${customerId}`

  return axios.get(Service_URL)
}

export async function deletePaymentOption(paymentId: string) {
  const Service_URL = `${APP_URL}/payment-option/delete-profile/${paymentId}`

  return axios.get(Service_URL)
}

export function getQuotePaymentOption(quoteId: number) {
  const Service_URL = `${APP_URL}/quote-order/quote-action/${quoteId}`

  return axios.get(Service_URL)
}

export function updateQuotePaymentOption(id: number, values: any) {
  const Service_URL = `${APP_URL}/quote-order/update-payment-option`
  let formData = new FormData()
  formData.append('orderId', id.toString())

  if (values.attachmentFile !== '') {
    for (let i = 0; i < values.attachmentFile.length; i++) {
      formData.append(`attachmentFile[${i}]`, values.attachmentFile[i])
    }
  }

  Object.keys(values).forEach((key) => formData.append(key, values[key]))

  return axios.post(Service_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function completeOrderBilling(id: number, values: any) {
  const Service_URL = `${APP_URL}/quote-order/billing-complete`
  let formData = new FormData()
  formData.append('orderId', id.toString())

  if (values.attachmentFile !== '') {
    for (let i = 0; i < values.attachmentFile.length; i++) {
      formData.append(`attachmentFile[${i}]`, values.attachmentFile[i])
    }
  }

  Object.keys(values).forEach((key) => formData.append(key, values[key]))

  return axios.post(Service_URL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function chargeQuotePayment(payload: any) {
  const Service_URL = `${APP_URL}/payment-option/charge-payment`

  return axios.post(Service_URL, payload)
}
