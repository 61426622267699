import React, { FC, useCallback, useState } from 'react'
import { CalibrationReportModel, fileType } from '../../../../types'
import { Dropdown } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../_metronic/helpers'
import { AttachmentModal } from '../../service-quotes/components/AttachmentModal'
import { getAttachment } from '../../other-cal-reports/OtherCalReportsCrud'
import { getErrorMessage, isNBS } from '../../../../helper'
import toast from 'react-hot-toast'
import { IEquipmentReportsModel } from '../../../../types/equipment-reports/EquipmentReportModel'


type Props = {
  data: any
  handlePDFAction?: any
  handleDeleteReport?: any
  handleAttachmentModal?: any
  handleEdit?: any
  isNBS?: boolean
  isNBSAdmin?: boolean
}

const ActionDropDownNewBalance: FC<Props> = (props: any) => {
  const { handleAttachmentModal, handleEdit, isNBS,isNBSAdmin, handlePDFAction, handleDeleteReport } = props
  const { dbkey, dbkey_quote_hdr, caltype, dbkey_id, attachment } = props.data
  const pdfLink = (() => {
    const type =
      caltype === 'Microscope'
        ? 'microsope_reports_portal'
        : caltype === 'Calibration'
        ? 'cal_reports_portal'
        : 'therm_reports_portal'
    return `processing_pdf.php?pdf=on&dbkey_quote_b=${dbkey_quote_hdr}&dbkey_calreports=${dbkey}&order_type=${type}`
  })()
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle id='dropdown-basic' variant='light' size='sm'>
          <KTSVG path='/media/icons/duotone/General/gen052.svg' className='svg-icon-5 m-0' />
        </Dropdown.Toggle>
        <Dropdown.Menu className='mt-1'>
          {isNBS ? (
            <>
                {isNBSAdmin ? (
                  
                <><Dropdown.Item key={0} onClick={() => handleDeleteReport(props.data)}>
                  Delete Report
                </Dropdown.Item><Dropdown.Item key={1} onClick={() => { handleEdit(props.data.ReportNo, true) } }>
                    Edit
                  </Dropdown.Item></>
              ) : null}

              {isNBS && ( 
                <>
                <Dropdown.Item key={2} onClick={() => handlePDFAction(props.data)}>
                    <span className='btn btn-icon btn-sm btn-clean btn-active-light-primary me-2 dz-clickable'>
                      <KTSVG
                        path='/media/icons/duotone/Communication/paperslip.svg'
                        className='svg-icon-2' />
                      PDF
            
                    </span>
                  </Dropdown.Item>
                  </>
              )}
            </>
          ) : (
            <>
            <Dropdown.Item key={3} onClick={() => handlePDFAction(props.data)}>
                <span className='btn btn-icon btn-sm btn-clean btn-active-light-primary me-2 dz-clickable'>
                  <KTSVG
                    path='/media/icons/duotone/Communication/paperslip.svg'
                    className='svg-icon-2' />
                  PDF
          
                </span>
              </Dropdown.Item>
              
              </>
          )}
        </Dropdown.Menu>
        {/* <Dropdown.Menu className='mt-1'> */}
        {/* </Dropdown.Menu> */}
      </Dropdown>
    </>
  )
}

export { ActionDropDownNewBalance }