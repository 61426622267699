import { forceLogout } from '../../app/modules/auth/helpers'

export default function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: { accessToken },
      } = store.getState()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (next: any) => {
      return Promise.resolve(next)
    },
    (error: any) => {
      if (error?.response) {
        const { status } = error?.response

        if (status === 401) {
          forceLogout()
        }
      }

      return Promise.reject(error)
    }
  )
}
