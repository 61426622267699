import { useRef } from 'react'
import toast from 'react-hot-toast'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { RootState } from '../../../../setup'

import { getUserByToken } from '../redux/AuthCRUD'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
// import { RootState } from 'g:/wamp/www/nbsonline/portal_frontendv2/src/setup/index'
// import { getUserByToken } from 'g:/wamp/www/nbsonline/portal_frontendv2/src/app/modules/auth/redux/AuthCRUD'
// import * as auth from 'g:/wamp/www/nbsonline/portal_frontendv2/src/app/modules/auth/redux/AuthRedux'

const useAuthCheck = () => {
  const dispatch = useDispatch()
  const accessToken = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual)
  const didRequest = useRef(false)

  const checkAuth = async (callback: () => void) => {
    try {
      if (accessToken) {
        const { data: user } = await getUserByToken()
        dispatch(auth.actions.fulfillUser(user))
        callback()
      } else {
        dispatch(auth.actions.logout())
        toast.error('Your session has expired. Please log in again.')
      }
    } catch (error) {
      console.error(error)
      if (!didRequest.current) {
        dispatch(auth.actions.logout())
        toast.error('Your session has expired. Please log in again.')
      }      
    } finally {
      didRequest.current = true
    }
  }
  return checkAuth
}

export default useAuthCheck
