import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../_metronic/helpers'
import toast from 'react-hot-toast'
import { getErrorMessage } from '../../../../helper/response.helper'
import { NBSCustomer } from '../../../../types'
import { createCustomer, updateCustomer } from '../CustomersCRUD'
import Table from './Table'

type Props = {
  type: string
  data: NBSCustomer
  customerKey: number
  show: boolean
  onHide: any
  submit: any
}

const ConfirmCustomerModal: React.FC<Props> = (props) => {
  const { type, data, customerKey, show, onHide, submit } = props
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const [submiting, setSubmiting] = useState<boolean>(false)
  const headers = [
    'Customer ID',
    'Company ID',
    'Contact First Name',
    'Contact Last Name',
    'Tax Exempt',
    'Status',
    'ID Shipping',
    'Ship Company Name',
    'Ship Phone Number',
    'Ship Fax Number',
    'Ship Address',
    'Ship City',
    'Ship State',
    'Ship Zip',
    'Billing Company Name',
    'Bill Contact',
    'Bill Phone Number',
    'Bill Fax Number',
    'Bill Address',
    'Bill City',
    'Bill State',
    'bill Zip',
  ]

  useEffect(() => {
    if (show) {
      setSubmiting(false) // Ensure submit is false when the modal appears
    }
  }, [show])


  const handleSubmit = () => {
    setIsSubmit(true)
    setSubmiting(true)

    if (type === 'edit') {
      updateCustomer(customerKey, data)
        .then((res) => {
          onHide(false)
          toast.success(res.data.message)
          submit()
        })
        .catch((error) => {
          const errMsg = getErrorMessage(error)
          toast.error(errMsg)
        })
    } else {
      createCustomer(data)
        .then((res) => {
          setIsSubmit(false)

          if (res.data.message !== 'exist') {
            onHide(false)
            toast.success(res.data.message)
            submit()
          } else {
            toast.success('The Customer ID is existing.')
            onHide()
          }
        })
        .catch((error) => {
          const errMsg = getErrorMessage(error)
          toast.error(errMsg)
        })
    }
  }

  return (
    <Modal
      size='lg'
      show={show}
      dialogClassName='w-50'
      aria-labelledby='contained-modal-title-vcenter'
      onHide={() => onHide(true)}
      centered
    >
      <Modal.Header>
        <Modal.Title className='w-100' id='contained-modal-title-vcenter'>
          <div className='d-flex justify-content-between'>
            <h2>{`${type} Customer`}</h2>
            <span className='text-hover-primary cursor-pointer' onClick={() => {
                onHide(true); 
                setSubmiting(false)
                }}>
              <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='pt-10 pb-15 px-lg-17'>
        <div className='d-flex flex-column scroll-y me-n7 pe-7 max-height-60'>
          <div className='fv-row mb-7 fv-plugins-icon-container'>
            <p className='fw-bold fs-6 mb-2 text-center'>
              You are about to {type} a customer with the below data. Please verify and select
              <b> "Submit"</b> to {type} your customer list or <b>"Discard"</b> to cancel.
            </p>
          </div>
          <div className='fv-row mb-7 fv-plugins-icon-container max-height-60 scroll-y'>
            <Table headers={headers} row={data} />
          </div>
        </div>
        <div className='text-center pt-15'>
          <button type='button' className='btn btn-light me-3' onClick={() => {
                onHide(true); 
                setSubmiting(false)
                }}>
            Discard
          </button>
          <button
            type='submit'
            className={`btn ${isSubmit ? 'btn-light' : 'btn-primary'}`}
            onClick={handleSubmit}
            disabled={submiting}
          >
            Submit
            {isSubmit && (
              <div className='spinner-border w-17px h-17px ms-2 text-primary' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export { ConfirmCustomerModal }
