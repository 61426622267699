import CustomerForm from './CustomerForm'

export interface CustomerFormData {
  firstname: string
  lastname: string
  companyName: string
  email: string
  title: string
  customerIds: (string | undefined)[]
  level: string
  status: string
}

export default CustomerForm
