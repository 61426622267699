import React, { FC } from 'react'
import { Asset } from '../../../../types'
import { Dropdown } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../_metronic/helpers'

type Props = {
  data: Asset
  handleEdit: (data: Asset) => void
  handleDelete: (data: Asset) => void
}

const ActionDropDown: FC<Props> = (props: any) => {
  const { data, handleEdit, handleDelete } = props

  return (
    <Dropdown>
      <Dropdown.Toggle id='dropdown-basic' variant='light' size='sm'>
        <KTSVG path='/media/icons/duotone/General/gen052.svg' className='svg-icon-5 m-0' />
      </Dropdown.Toggle>
      <Dropdown.Menu className='mt-1'>
        <Dropdown.Item href='#' onClick={() => handleEdit(data)}>
          Edit
        </Dropdown.Item>
        <Dropdown.Item href='#' onClick={() => handleDelete(data)}>
          Delete
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export { ActionDropDown }
