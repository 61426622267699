import React from 'react'
import PropTypes from 'prop-types'
import {default as ReactSelect} from 'react-select'

const MultiSelect = (props) => {
  let options = []
  let initialValue = []
  if (props.options && props.options.length) {
    options = props.options.map((option) => ({
      value: option,
      label: option,
    }))

    props.options.forEach((option) => {
      if (props.value.includes(option)) {
        initialValue.push({
          value: option,
          label: option,
        })
      }
    })
  }

  return (
    <ReactSelect
      {...props}
      isMulti
      value={initialValue}
      options={[props.allOption, ...options]}
      onChange={(selected) => {
        let selectedOptions = selected
        if (
          selected !== null &&
          selected.length !== 0 &&
          selected[selected.length - 1].value === props.allOption.value
        ) {
          selectedOptions = options
        }
        const selectedCustomerIds = selectedOptions.map((id) => id.value)
        return props.onChange(selectedCustomerIds)
      }}
      styles={{marginRight: 16}}
    />
  )
}

MultiSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
}

MultiSelect.defaultProps = {
  allOption: {
    label: 'Select all',
    value: '*',
  },
}

export default MultiSelect
