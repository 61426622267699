/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { login } from '../redux/AuthCRUD'
import { UserModel } from '../../../../types'
import * as Yup from 'yup'
import * as auth from '../redux/AuthRedux'
import clsx from 'clsx'
import { KTSVG } from '../../../../_metronic/helpers'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { IconButton } from '@material-ui/core'

const connector = connect(null, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

const Login: FC<PropsFromRedux> = (props) => {
  const [loading, setLoading] = useState(false)
  const [authToken, setAuthToken] = useState<string>('')
  const [user, setUser] = useState<UserModel | null>(null)
  const [error, setError] = useState<any>(null)
  const [show, setShow] = useState<boolean>(false)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      login(values.email, values.password)
        .then(({ data: { authToken, data: user } }) => {
          setAuthToken(authToken)
          setUser(user)
          // playNotificationSound()
        })
        .catch((err: any) => {
          setError(err)
          const errMsg = err?.response?.data?.message || 'The login detail is incorrect'
          setStatus(errMsg)
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
  })

  React.useEffect(() => {
    if (authToken && user && props && props.login) {
      setLoading(false)
      props.login(authToken, user as UserModel)
    }
  }, [props, authToken, user])

  React.useEffect(() => {
    setLoading(false)
  }, [error])

  return (
    <form
      className={`form w-100 ${loading && 'overlay overlay-block'}`}
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In to NBS Calibrations Portal</h1>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>Enter your email and password</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{ marginLeft: '5px' }}
            >
              Forgot Password ?
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <div className='inner-password'>
          <input
            type={show ? 'text' : 'password'}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid input-field',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <IconButton
            className='inner-icon'
            size='small'
            onClick={() => {
              setShow(!show)
            }}
          >
            {show ? (
              <KTSVG path={toAbsoluteUrl('/media/icons/duotone/General/Eye-slash.svg')} />
            ) : (
              <KTSVG path={toAbsoluteUrl('/media/icons/duotone/General/Eye.svg')} />
            )}
          </IconButton>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          <span className='indicator-label'>Continue</span>
        </button>
      </div>
      <div className='d-flex justify-content-between mt-5'>
        <div className='d-flex flex-stack mb-2'>
          {/* begin::Label */}
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Have not registered? </label>
          {/* end::Label */}
          {/* begin::Link */}
          <Link
            to={{ pathname: 'https://www.nbscalibrations.com/nbs-portal-invite-request-form/' }}
            href='https://www.nbscalibrations.com/nbs-portal-invite-request-form/'
            target='_blank'
            rel='noopener noreferrer'
            className='link-primary fs-6 fw-bolder'
            style={{ marginLeft: '5px' }}
          >
            Sign up here!
          </Link>
          {/* end::Link */}
        </div>
      </div>
      {/* end::Action */}

      {/* begin::Overlay */}
      {loading && (
        <div className='overlay-layer bg-opacity-5'>
          <div className='spinner-border text-primary' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      )}
      {/* end::Overlay */}
    </form>
  )
}

export default connector(Login)
