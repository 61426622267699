import React, { useEffect, useMemo, useRef, useState } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { Card } from 'react-bootstrap-v5'
import { KTSVG } from '../../../_metronic/helpers'
import FilterDropDown from '../../modules/calibration/FilterDropDown'
import { IEquipmentReportsModel } from '../../../types/equipment-reports/EquipmentReportModel'
import { IAuthState } from '../../modules/auth'
import { PersistPartial } from 'redux-persist/es/persistReducer'
import { RootState } from '../../../setup'
import { ConnectedProps, connect } from 'react-redux'
import * as equipmentReportsRedux from './EquipmentReportsRedux'
import { TableColumn } from 'react-data-table-component'
import { ActionDropDown } from '../calibration-reports/component/ActionDropDown'
import moment from 'moment'
import * as levelHelper from '../../../helper/level.helper'
import DataTableBase from '../../components/DataTableBase'
import { Customer } from '../../../types'
import { useDebounce } from 'use-debounce'

interface ReduxStateValueProps {
  equipmentReports: IEquipmentReportsModel[]
  loading: boolean
  customerIds: any
  auth: IAuthState & PersistPartial
}

const mapState = (state: RootState) => ({
  equipmentReports: state.equipmentReports.equipmentReports,
  loading: state.equipmentReports.loading,
  customerIds: state.auth?.activeCustomerIds,
  auth: state.auth,
})

const connector = connect(mapState, { ...equipmentReportsRedux.actions })
type PropsFromRedux = ConnectedProps<typeof connector>
type Props = ReduxStateValueProps & PropsFromRedux

const EquipmentReportsPage = (props: Props) => {
  const { equipmentReports, getEquipmentReports, auth, customerIds, loading } = props

  const [isNBS, setIsNBS] = useState<boolean>(false)
  const [nbsCustomerIds, setNbsCustomerIds] = useState<string[]>(['nbs'])
  const [nbsCustomerNames, setNbsCustomerNames] = useState<string[]>(['nbs'])
  const [keyword, setKeyword] = useState<string>('')
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const filterRef = useRef() as React.MutableRefObject<HTMLButtonElement>
  const [debouncedKeyword] = useDebounce(keyword, 500)
  const initialFilter = {
    from: '',
    to: '',
    isReset: false,
  }
  useEffect(() => {
    setIsNBS(levelHelper.isNBS(auth.user?.type))
    const { from, to } = initialFilter
    getEquipmentReports({
      customerIds: levelHelper.isNBS(props.auth.user?.type) ? nbsCustomerIds : customerIds,
      nbsCustomerNames,
      from,
      to,
    })
  }, [getEquipmentReports, customerIds, auth])
  const reportsData = useMemo(
    () =>
      equipmentReports.filter((report: IEquipmentReportsModel) => {
        const keyword = debouncedKeyword.toLowerCase();
        return (
          report.customerid?.toLowerCase().includes(keyword) ||
          report.caltype?.toLowerCase().includes(keyword) ||
          report.customer?.toLowerCase().includes(keyword) ||
          report.equipment_description?.toLowerCase().includes(keyword) ||
          report.manufacturer?.toLowerCase().includes(keyword) ||
          report.model?.toLowerCase().includes(keyword) ||
          report.serial?.toLowerCase().includes(keyword) ||
          report.technician_fullname?.toLowerCase().includes(keyword)
        );
      }),
    [equipmentReports, debouncedKeyword]
  );

  const handleFilter = (filters: any) => {
    const { selectedCustomers, from, to, types, isReset } = filters

    let filterIds: string[] = isNBS ? ['nbs'] : []
    let filterNames: string[] = []

    if (!isReset) {
      selectedCustomers.forEach((customer: Customer) => {
        filterIds.push(customer.id)
        filterNames.push(customer.name ?? '')
      })
    }
    else
    {
      customerIds.forEach((customer: string) => {
        filterIds.push(customer)
        //filterNames.push(customer.name ?? '')
      })
      console.log(filterIds)

    }
      filterIds = isNBS && filterIds.length === 0 ? ['nbs'] : filterIds

      if(isNBS){setNbsCustomerIds(filterIds)}
      if(isNBS){setNbsCustomerNames(filterNames)}

    getEquipmentReports({
      customerIds: isNBS ? filterIds : filterIds,
      customerNames: filterNames,
      from,
      to,
      types,
    })
  }

  const handleCancelCustomer = () => {
    setTimeout(() => {
      filterRef.current.click()
      setOpenFilter(false)
    }, 200)
  }
  const columns: TableColumn<IEquipmentReportsModel>[] = useMemo(
    () => [
      {
        name: 'Actions',
        cell: (row) => <ActionDropDown data={row} />,
        width: '100px',
        style: {
          minWidth: '100px !important',
          maxWidth: '100px !important',
        },
      },
      {
        name: 'Calibration Type',
        selector: (row: IEquipmentReportsModel) => row.equipment_description,
        cell: (row: IEquipmentReportsModel) => <span>{row.equipment_description}</span>,
        sortable: true,
        width: '10%',
        style: {
          minWidth: '10% !important',
          maxWidth: '10% !important',
        },
      },
      {
        name: 'Customer ID',
        selector: (row: IEquipmentReportsModel) => row.customerid,
        cell: (row: IEquipmentReportsModel) => <span>{row.customerid}</span>,
        sortable: true,
        width: '10%',
        style: {
          minWidth: '10% !important',
          maxWidth: '10% !important',
        },
      },
      {
        name: 'Date of Calibration',
        selector: (row: IEquipmentReportsModel) => row.dateofcal,
        cell: (row: IEquipmentReportsModel) => (
          <span>{moment(row.dateofcal).format('MM/DD/YY')}</span>
        ),
        sortable: true,
        width: '12%',
        style: {
          minWidth: '12% !important',
          maxWidth: '12% !important',
        },
      },
      {
        name: 'Customer Name',
        selector: (row: IEquipmentReportsModel) => row.ship_company_name,
        cell: (row: IEquipmentReportsModel) => <span>{row.ship_company_name}</span>,
        sortable: true,
        width: '12%',
        style: {
          minWidth: '12% !important',
          maxWidth: '12% !important',
        },
      },
      {
        name: 'Technician',
        selector: (row: IEquipmentReportsModel) => row.technician_fullname,
        cell: (row: IEquipmentReportsModel) => <span>{row.technician_fullname}</span>,
        sortable: true,
        width: '13%',
        style: {
          minWidth: '13% !important',
          maxWidth: '13% !important',
        },
      },
      {
        name: 'MFG',
        selector: (row: IEquipmentReportsModel) => row.manufacturer,
        cell: (row: IEquipmentReportsModel) => <span>{row.manufacturer}</span>,
        sortable: true,
        width: '13%',
        style: {
          minWidth: '13% !important',
          maxWidth: '13% !important',
        },
      },
      {
        name: 'Model',
        selector: (row: IEquipmentReportsModel) => row.model,
        cell: (row: IEquipmentReportsModel) => <span>{row.model}</span>,
        sortable: true,
        width: '10%',
        style: {
          minWidth: '10% !important',
          maxWidth: '10% !important',
        },
      },
      {
        name: 'Serial #',
        selector: (row: IEquipmentReportsModel) => row.serial,
        cell: (row: IEquipmentReportsModel) => <span>{row.serial}</span>,
        sortable: true,
        width: '10%',
        style: {
          minWidth: '10% !important',
          maxWidth: '10% !important',
        },
      },
      // {
      //   name: 'Equipment Type',
      //   selector: (row: IEquipmentReportsModel) => row.equipment_description,
      //   cell: (row: IEquipmentReportsModel) => <span>{row.equipment_description}</span>,
      //   sortable: true,
      //   width: '10%',
      //   style: {
      //     minWidth: '10% !important',
      //     maxWidth: '10% !important',
      //   },
      // },
    ],
    [reportsData]
  )

  return (
    <>
      <PageTitle breadcrumbs={[]}>Other Reports</PageTitle>
      <Card className='min-vh-100'>
        <Card.Header className='border-0 pt-6'>
          <Card.Title>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-customer-table-filter='search'
                className='form-control form-control-solid w-250px ps-15 border-0'
                placeholder='Search Other Reports'
                value={keyword}
                onChange={(e: any) => setKeyword(e.target.value)}
              />
            </div>
          </Card.Title>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-customer-table-toolbar='base'>
              <div className='filter-dropdown ms-3'>
                <button
                  type='button'
                  onClick={() => {
                    setOpenFilter(true)
                  }}
                  className='btn btn-light-primary me-3'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  data-bs-trigger='hover'
                  title='Filter options'
                  ref={filterRef}
                >
                  <KTSVG
                    path='/media/icons/duotone/Text/Filter.svg'
                    className='svg-icon-2 text-light'
                  />
                  Filter
                </button>
                <FilterDropDown
                  open={openFilter}
                  handleFilter={handleFilter}
                  handleCancelCustomer={handleCancelCustomer}
                />
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body className='pt-0 calibration-reports-body'>
          <DataTableBase
            pagination
            columns={columns}
            data={reportsData}
            progressPending={loading}
          />
        </Card.Body>
      </Card>
    </>
  )
}

export default connector(EquipmentReportsPage)
