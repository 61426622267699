import axios from 'axios'

export const CUSTOMER_FRIENDLY_STATUS_CODES_URL = `${process.env.REACT_APP_API_URL}/admin-configuration-setting/customer-friendly-codes`

export function getCustomerFriendlyStatusCodes(slug: string) {
  return axios.get(`${CUSTOMER_FRIENDLY_STATUS_CODES_URL}`, {
    params: {
      slug
    }
  })
}

export function updateCustomerFriendlyStatusCodes(dbkey_tables: string, dbkey: string, customer_freindly_code: string, customer_hover_text: string) {
  return axios.put(CUSTOMER_FRIENDLY_STATUS_CODES_URL, {
    dbkey_tables,
    dbkey,
    customer_freindly_code,
    customer_hover_text
  })
}
