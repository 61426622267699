import axios from 'axios'

export const BILLING_COMPANY_URL = `${process.env.REACT_APP_API_URL}/nbs-billing-company`

export function getBillingCompanies(searchFilter: any = {}) {
  const params: any = { ...searchFilter }
  return axios.get(BILLING_COMPANY_URL, { params })
}

export function createBillingCompany(billingCompany: any) {
  const API_URL = `${BILLING_COMPANY_URL}`

  return axios.post(API_URL, { billingCompany })
}

export function updateBillingCompany(billingCompany: any) {
  const API_URL = `${BILLING_COMPANY_URL}`

  return axios.put(API_URL, { billingCompany })
}

export function removeBillingCompany(id: string | number) {
  if (!id) {
    return
  }

  const API_URL = `${BILLING_COMPANY_URL}`
  const params: any = { id }

  return axios.delete(API_URL, params)
}

export function getBillingCompanyIDs() {
  return axios.get(BILLING_COMPANY_URL + '/ids')
}

export function getBillingCompaniesWithSpecCol() {
  return axios.get(BILLING_COMPANY_URL + '/spec-column')
}

export function getBillingCompanyWithId(company_id: string) {
  return axios.get(BILLING_COMPANY_URL + `/get/${company_id}`)
}

export function getBillingCompanyID() {
  return axios.get(BILLING_COMPANY_URL + `/id`)
}