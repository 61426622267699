/* eslint-disable jsx-a11y/anchor-is-valid */
import './CalReport.css'
import 'react-datepicker/src/stylesheets/datepicker.scss';

var currentTab = 0;

function showTab (n: number) {
  // This function will display the specified tab of the form...
  var x = document.getElementsByClassName("tab") as HTMLCollectionOf<HTMLElement>;
  x[n].style.display  = "block";

  //... and fix the Previous/Next buttons:
  if (n === 0) {
    const box = document.getElementById('prevBtn') as HTMLDivElement | null;
    if (box != null){
        box.style.display = "none";
    }
  } else {
    const box = document.getElementById('prevBtn') as HTMLDivElement | null;
    if (box != null){
        box.style.display = "inline";
  }
  if (n === (x.length - 1)) {
    const box = document.getElementById('nextBtn') as HTMLDivElement | null;
    const box1 = document.getElementById('prevBtn') as HTMLDivElement | null;
    if(box != null){
        box.style.display = "Submit";
        box.setAttribute('type', 'submit');
        box.style.display = "none"
        if(box1 != null){box1.style.display = "none"}



  }
  } else {
    const box = document.getElementById('nextBtn') as HTMLDivElement | null;
    if (box != null) {
    }
  }
  //... and run a function that will display the correct step indicator:
  fixStepIndicator(n)
}
}

function nextPrev(n: number) {
  // This function will figure out which tab to display
  var x = document.getElementsByClassName("tab") as HTMLCollectionOf<HTMLElement>;
  // Exit the function if any field in the current tab is invalid:
  if (n === 1 && !validateForm()) return false;
  // Hide the current tab:
  x[currentTab].style.display = "none";
  // Increase or decrease the current tab by 1:
  currentTab = currentTab + n;
  // if you have reached the end of the form...
  if (currentTab >= x.length) {
    // ... the form gets submitted:
    const box = document.getElementById('regForm') as HTMLDivElement | null;
    if(box != null){
       //alert("submit fix")

  }

    return false;
  }
  // Otherwise, display the correct tab:
  showTab(currentTab);
}

function validateForm() {
  // This function deals with validation of the form fields
  var x, y, i, valid = true;
  x = document.getElementsByClassName("tab");
  y = x[currentTab].getElementsByTagName("input");
  // A loop that checks every input field in the current tab:
  for (i = 0; i < y.length; i++) {
    // If a field is empty...
    if (y[i].value === "") {
      // add an "invalid" class to the field:
      //y[i].className += " invalid";
      // and set the current valid status to false
      //valid = false;
    }
  }
  // If the valid status is true, mark the step as finished and valid:
  if (valid) {
    var z = document.getElementsByClassName("step") as HTMLCollectionOf<HTMLElement>;
    if(z != null){
        z[currentTab].className += " finish";
  }
  }
  return valid; // return the valid status
}

function fixStepIndicator(n: number) {
  // This function removes the "active" class of all steps...
  var i, x = document.getElementsByClassName("step");
  for (i = 0; i < x.length; i++) {
    x[i].className = x[i].className.replace(" active", "");
  }
  //... and adds the "active" class on the current step:
  x[n].className += " active";
}

export { fixStepIndicator, validateForm, nextPrev, showTab, currentTab }
