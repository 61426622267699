import { Action, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IInvoice, IPagination } from '../../../../types/Ivoice.type'
import axios from 'axios'

export const INVOICE_URL = `${process.env.REACT_APP_API_URL}/invoice`
export interface IInvoiceResponseModel extends IPagination {
  data: IInvoice[]
}

export interface IInvoiceWithTodayResponseModel {
  invoices: IInvoiceResponseModel
  today: string
  loading: boolean
}

export interface ActionWithPayload<T> extends Action {
  payload: T
}

const initialState: IInvoiceWithTodayResponseModel = {
  invoices: {
    current_page: 1,
    data: [],
    first_page_url: '',
    last_page: 1,
    last_page_url: '',
    next_page_url: '',
    prev_page_url: '',
    path: '',
    from: 1,
    to: 1,
    total: 1,
    per_page: 1,
  },
  today: '',
  loading: false,
}

export const getInvoices = createAsyncThunk(
  'invoice/get',
  async (params: any, { dispatch, getState }) => {
    const res = await axios.get(`${INVOICE_URL}/get`, { params })
    return res.data
  }
)

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getInvoices.pending, (state) => {
        state.loading = true
      })
      .addCase(
        getInvoices.fulfilled,
        (state, action: ActionWithPayload<IInvoiceWithTodayResponseModel>) => {
          state.loading = false
          Object.assign(state, action.payload)
        }
      )
      .addCase(getInvoices.rejected, (state) => {
        state.loading = false
      })
  },
})

export default invoiceSlice.reducer
