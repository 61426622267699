import React, { useCallback, useEffect, useState, useRef } from 'react'
import * as Yup from 'yup'
import { Modal } from 'react-bootstrap-v5'
import { KTSVG } from '../../../../_metronic/helpers'
import { Formik, Form, Field } from 'formik'
import { STATES } from '../../../../data/constants'
import { StatesDropDown } from './StatesDropDown'
import { BillingCompany, CustomerModel } from '../../../../types'
import { ConfirmBillingCompanyModal } from './ConfirmBillingCompanyModal'
import { checkIsBillingCompanyIdExist } from '../../../services/service'
import moment from 'moment'
import { getCustomersWithCompanyID } from '../../customers/CustomersCRUD'
import CustomersPage from '../../customers/CustomersPage'
import { Typography } from '@material-ui/core'
import { SelectDropDown } from '../../../components/SelectDropDown'
import DatePicker from 'react-datepicker'
import TipTapEditor from '../../../components/TiptapEditor/TipTapEditor'
import toast from 'react-hot-toast'

type Props = {
  isShow: boolean
  onHide: any
  billingCompany: BillingCompany
  billingCompanies: BillingCompany[]
  isViewMode?: boolean
  isCommingFromCustomers?: boolean
  getBillingCompanies: () => void
  fetchBillingCompanies : () => void
  handleChangeCompanyId: (companyId: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setCustomerFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const BillingCompanyEditSchema = Yup.object().shape({
  CompanyName: Yup.string().required('Company Name is required'),
  BillingCompanyID: Yup.string()
    .required('Company ID is required')
    .length(6, 'Company ID must be exactly 6 characters long'),

  // BillAttn_email: Yup.string().email('Billing Attention must be a valid email'),
})

const BillingCompanyModal: React.FC<Props> = (props) => {
  const {
    isShow,
    onHide,
    getBillingCompanies,
    billingCompany,
    isViewMode = false,
    isCommingFromCustomers = false,
    billingCompanies,
  } = props

  const [billState, setBillState] = useState<string>('')
  const [currentBillingCompany, setCurrentBillingCompany] = useState<BillingCompany>(billingCompany)
  const [showView, setShowView] = useState<boolean>(false)
  const [isExist, setIsExist] = useState<boolean>(false)
  const [billToggle, setBillToggle] = useState<boolean>(false)
  const [customers, setCustomers] = useState<CustomerModel[]>([])
  const [date, setDate] = useState<any>(
    billingCompany.expirationDate ? new Date(billingCompany.expirationDate) : null
  )
  const handleSubmit = (formValues: BillingCompany) => {
    if(formValues.BillNotes.length >= 1000)
      {
        toast.error("The text in Shipping Notes is too long and has been shortened! Ensure you have required data in the field!")
      }
    checkIsBillingCompanyIdExist(formValues.dbkey, formValues.BillingCompanyID).then((res) => {
      if (res.data) {
        setIsExist(true)
      } else {
        setIsExist(false)
        setCurrentBillingCompany(formValues)
        setShowView(true)
      }
    })
  }

  const handleHide = () => {
    setShowView(false)
  }

  const onSubmit = () => {
    setShowView(false)
    onHide()
    if(!isCommingFromCustomers) {
      getBillingCompanies()
    }
  }

  useEffect(() => {
    setDate(billingCompany.expirationDate ? new Date(billingCompany.expirationDate) : null)
    setBillState(billingCompany.BillState)
  }, [billingCompany])

  const getCustomers = useCallback(
    (data: CustomerModel[]) => {
      setCustomers(data)
    },
    [setCustomers]
  )
  return (
    <>
      <Modal
        size='lg'
        show={isShow}
        aria-labelledby='contained-modal-title-vcenter'
        className={showView ? 'visually-hidden' : ''}
        onHide={() => onHide(true)}
        centered
      >
        <Modal.Header>
          <Modal.Title className='w-100' id='contained-modal-title-vcenter'>
            <div className='d-flex justify-content-between'>
              <h2>{isViewMode ? 'View' : billingCompany.dbkey ? 'Edit' : 'Add'} Billing Company</h2>
              <span className='text-hover-primary cursor-pointer' onClick={() => onHide(true)}>
                <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-10 pb-15 px-lg-17'>
          <Formik
            validationSchema={BillingCompanyEditSchema}
            onSubmit={handleSubmit}
            initialValues={billingCompany}
          >
            {({ handleSubmit, setFieldValue, errors, touched, values }) => (
              <Form>
                <Field as='input' name='dbkey' className='d-none' />
                <div className='d-flex flex-column scroll-y me-n7 pe-7 max-height-60'>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-6 mb-5'>
                      <label className='fw-bold fs-6 required mb-2'>Company ID</label>
                      <Field
                        as='input'
                        disabled={customers.length > 0 && billingCompany.dbkey ? true : false}
                        name='BillingCompanyID'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly={isViewMode}
                      />
                      {customers.length > 0 && billingCompany.dbkey && (
                        <div className='fv-plugins-message-container'>
                          <div className=''>
                            This Company ID has assigned Customers. Please un-assign customers from
                            this Company ID before changing this value.
                          </div>
                        </div>
                      )}
                      {touched.BillingCompanyID && errors.BillingCompanyID ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.BillingCompanyID}</div>
                        </div>
                      ) : (
                        isExist && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>Company Id already exists.</div>
                          </div>
                        )
                      )}
                    </div>
                    <div className='col-lg-6 mb-5'>
                      <label className='fw-bold fs-6 required mb-2'>Company Name</label>
                      <Field
                        as='input'
                        name='CompanyName'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly={isViewMode}
                      />
                      {touched.CompanyName && errors.CompanyName ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.CompanyName}</div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-4 mb-5'>
                      <label className='fw-bold fs-6 mb-2'>Tax Exempt</label>
                      <SelectDropDown
                        data={['Yes', 'No']}
                        defaultValue={billingCompany.taxExempt || 0}
                        dirUp={false}
                        selectedValue={(value: string | number) =>
                          setFieldValue('taxExempt', value)
                        }
                      />
                      <Field
                        as='input'
                        name='taxExempt'
                        className='form-control form-control-solid mb-3 d-none'
                      />
                    </div>
                    <div className='col-lg-4 mb-7'>
                      <label className='fw-bold fs-6 mb-2'>Net Terms</label>
                      <SelectDropDown
                        data={[30, 35, 45, 60, 90]}
                        defaultValue={billingCompany.netTerms || 0}
                        dirUp={false}
                        selectedValue={(value: string | number) => setFieldValue('netTerms', value)}
                      />
                      <Field
                        as='input'
                        name='netTerms'
                        className='form-control form-control-solid d-none'
                      />
                    </div>
                    <div className='col-lg-4 mb-5'>
                      <label className='fw-bold fs-6 mb-2'>Exemption Expiration Date</label>
                      <DatePicker
                        className='form-control form-control-solid'
                        placeholderText='Select a date'
                        selected={date}
                        dateFormat='MM/dd/yyyy'
                        readOnly={isViewMode}
                        onChange={(expirationDate) => {
                          setFieldValue(
                            'expirationDate',
                            expirationDate ? expirationDate.toString() : null
                          )
                          setDate(expirationDate)
                        }}
                      />
                      <Field
                        as='input'
                        name='expirationDate'
                        className='form-control form-control-solid mb-3 d-none'
                      />
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-12 mb-5'>
                      <label className='fw-bold fs-6 mb-2'>Billing Attention</label>
                      <Field
                        as='input'
                        name='BillAttn_email'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly={isViewMode}
                      />
                      {touched.BillAttn_email && errors.BillAttn_email ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.BillAttn_email}</div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-6 mb-5'>
                      <label className='fw-bold fs-6 mb-2'>Phone Number</label>
                      <Field
                        as='input'
                        name='BillPhone'
                        className='form-control form-control-solid mb-3'
                        readOnly={isViewMode}
                      />
                    </div>
                    <div className='col-lg-6 mb-5'>
                      <label className='fw-bold fs-6 mb-2'>Fax Number</label>
                      <Field
                        as='input'
                        name='BillFax'
                        className='form-control form-control-solid'
                        readOnly={isViewMode}
                      />
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-12 mb-5'>
                      <label className='fw-bold fs-6 mb-2'>Address Line 1</label>
                      <Field
                        as='input'
                        name='BillAddress'
                        className='form-control form-control-solid'
                        readOnly={isViewMode}
                      />
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-12 mb-5'>
                      <label className='fw-bold fs-6 mb-2'>Address Line 2</label>
                      <Field
                        as='input'
                        name='BillAddress2'
                        className='form-control form-control-solid'
                        readOnly={isViewMode}
                      />
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-12 mb-5'>
                      <label className='fw-bold fs-6 mb-2'>City</label>
                      <Field
                        as='input'
                        name='BillCity'
                        className='form-control form-control-solid'
                        readOnly={isViewMode}
                      />
                    </div>
                  </div>
                  <div className='row fv-plugins-icon-container'>
                    <div className='col-lg-6 mb-5'>
                      <label className='fw-bold fs-6 mb-2'>State</label>
                      <StatesDropDown
                        data={STATES}
                        defaultValue={billState}
                        dirUp={!isViewMode}
                        selectedValue={(code: string) => setFieldValue('BillState', code)}
                        disable={isViewMode}
                      />
                      <Field
                        as='input'
                        name='BillState'
                        className='form-control form-control-solid d-none'
                      />
                      {touched.BillState && errors.BillState ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.BillState}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className='col-lg-6 mb-5'>
                      <label className='fw-bold fs-6 mb-2'>Zip Code</label>
                      <Field
                        as='input'
                        name='BillZip'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        readOnly={isViewMode}
                      />
                      {touched.BillZip && errors.BillZip ? (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{errors.BillZip}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className='col-lg-12 mb-5'>
                      <label className='fw-bold fs-6 mb-2'>Billing Notes</label>
                      <TipTapEditor
                          content={values.BillNotes}
                          onUpdate={(content) => {if (content.length <= 1000) {
                            setFieldValue('BillNotes', content)
                          } else {
                            setFieldValue('BillNotes', content.slice(0, 1000))
                            
                          }
                        }}
                        isReadOnly={isViewMode}
                        />
                      <Field
                        as='input'
                        name='BillNotes'
                        className='form-control form-control-solid mb-3 d-none'
                      />
                    </div>
                  </div>
                </div>
                {billingCompany.dbkey && (
                  <>
                    <div className='d-flex mb-5 mt-5 cursor-pointer'>
                      <div className='d-flex' onClick={() => setBillToggle(!billToggle)}>
                        <h3> Assigned Customers({customers.length})</h3>
                        <KTSVG
                          path={`/media/icons/duotone/Navigation/${
                            billToggle ? 'Angle-up' : 'Angle-down'
                          }.svg`}
                          className='svg-icon-1'
                        />
                      </div>
                    </div>
                    <div
                      className={`${billToggle ? 'd-none' : 'd-block'} ps-5`}
                      style={{ maxHeight: 260, overflowY: 'scroll' }}
                    >
                      {billingCompany.BillingCompanyID ? (
                        <CustomersPage
                          getCustomers={getCustomers}
                          companyId={billingCompany.BillingCompanyID}
                        />
                      ) : (
                        <Typography>There are not any Assigned customers</Typography>
                      )}
                    </div>
                  </>
                )}
                {!!values.dbkey && (
                  <div className='pt-15 fw-bold fs-5 mx-4'>
                    {
                      <div>
                        Updated {!!values.updator ? 'by ' + values.updator : ''} on{' '}
                        {moment(values.updated_at).format('MM/DD/YY \\a\\t hh:mm a')}
                      </div>
                    }
                    {
                      <div>
                        Created on {moment(values.created_at).format('MM/DD/YY \\a\\t hh:mm a')}
                      </div>
                    }
                  </div>
                )}

                {isViewMode ? (
                  <div className='text-center pt-15'>
                    <button
                      type='button'
                      className='btn btn-light me-3'
                      onClick={() => onHide(false)}
                    >
                      Ok
                    </button>
                  </div>
                ) : (
                  <div className='text-center pt-15'>
                    <button
                      type='button'
                      className='btn btn-light me-3'
                      onClick={() => onHide(true)}
                    >
                      Discard
                    </button>
                    <button type='submit' className='btn btn-primary'>
                      Submit
                    </button>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <ConfirmBillingCompanyModal
        type={currentBillingCompany.dbkey ? 'edit' : 'add'}
        currentBillingCompany={currentBillingCompany}
        customerKey={0}
        show={showView}
        onHide={handleHide}
        submit={onSubmit}
        fetchBillingCompanies={props.fetchBillingCompanies}
        handleChangeCompanyId={props.handleChangeCompanyId}
        setFieldValue={props.setCustomerFieldValue}
      />
    </>
  )
}

export { BillingCompanyModal }
