import React, { useState } from 'react'
import { Accordion } from 'react-bootstrap-v5'

import moment from 'moment'
import DatePicker from 'react-datepicker'
import CustomToggle from '../../../components/CustomToggle'
import FilterList from '../../../components/FilterList'
import 'react-datepicker/src/stylesheets/datepicker.scss'
import { CUSTOMER_LEVELS, CUSTOMER_STATUS, NBS_ADMIN_LEVEL } from '../../../../data/constants'

type FilterDropDownProps = {
  handleFilter: (filter: any) => void
  handleCancelCustomer: () => void
  isNBS: boolean
}

export const FilterDropdownInitialStatus = ['active', 'invited']

const FilterDropDown: React.FC<FilterDropDownProps> = ({ handleFilter, handleCancelCustomer, isNBS }) => {
  const levelList = isNBS ? Object.values(NBS_ADMIN_LEVEL) : Object.values(CUSTOMER_LEVELS)
  const statusList = Object.values(CUSTOMER_STATUS)

  const [, setIsReset] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<any>()
  const [endDate, setEndDate] = useState<any>()
  const [levelFilters, setLevelFilters] = useState<Array<string>>(levelList)
  const [statusFilters, setStatusFilters] = useState<Array<string>>(FilterDropdownInitialStatus)

  const handleChangeLevel = (level: string, isChecked: boolean) => {
    if (isChecked === true) {
      setLevelFilters([...levelFilters, level])
    } else {
      setLevelFilters(levelFilters.filter(l => l !== level))
    }
  }

  const handleChangeStatus = (status: string, isChecked: boolean) => {
    if (isChecked === true) {
      setStatusFilters([...statusFilters, status])
    } else {
      setStatusFilters(statusFilters.filter(s => s !== status))
    }
  }

  const handleReject = () => {
    setLevelFilters(levelList)
    setStatusFilters(statusList)
    setStartDate('')
    setEndDate('')
    setIsReset(true)
    handleFilter({
      isReset: true,
    })
  }

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>
      <div className='separator border-gray-200'></div>
      <div className='px-7 py-5'>
        <Accordion defaultActiveKey='0'>
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='filter-0'>Levels</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='filter-0' className='ms-8'>
            <ul className='list-unstyled'>
              {levelList.map((level, index) => {
                return (
                  <FilterList
                    key={`level-${index}`}
                    status={level}
                    isChecked={levelFilters.includes(level)}
                    handleChange={handleChangeLevel}
                  />
                )
              })}
            </ul>
          </Accordion.Collapse>
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='filter-1'>Status</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='filter-1' className='ms-8'>
            <ul className='list-unstyled'>
              {statusList.map((status, index) => {
                return (
                  <FilterList
                    key={`status-${index}`}
                    status={status}
                    isChecked={statusFilters.includes(status)}
                    handleChange={handleChangeStatus}
                  />
                )
              })}
            </ul>
          </Accordion.Collapse>
          <div className='separator border-gray-200'></div>
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='filter-2'>Activated on</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='filter-2'>
            <>
              <div className='mb-10'>
                <label className='form-label fw-bold'>From:</label>
                <DatePicker
                  className='form-control'
                  placeholderText='Select a date'
                  selected={startDate}
                  dateFormat='MM/dd/yy hh:mma'
                  showTimeSelect
                  onChange={(date) => {
                    setStartDate(date)
                  }}
                />
              </div>
              <div className='mb-10'>
                <label className='form-label fw-bold'>To:</label>
                <DatePicker
                  className='form-control'
                  placeholderText='Select a date'
                  selected={endDate}
                  dateFormat='MM/dd/yy hh:mma'
                  showTimeSelect
                  onChange={(date) => setEndDate(date)}
                />
              </div>
            </>
          </Accordion.Collapse>
          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-white btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
              onClick={handleReject}
            >
              Reset
            </button>
            <button
              type='button'
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={() => {
                setIsReset(false)
                handleFilter({
                  level: levelFilters,
                  status: statusFilters,
                  from:
                    !!startDate
                      ? moment(startDate).utc().format('Y-MM-DD HH:mm')
                      : null,
                  to:
                    !!endDate
                      ? moment(endDate).utc().format('Y-MM-DD HH:mm')
                      : moment().utc().format('Y-MM-DD HH:mm'),
                })
              }}
            >
              Apply
            </button>
          </div>
        </Accordion>
      </div>
    </div>
  )
}

export default FilterDropDown
