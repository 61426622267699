import { Action } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { QuoteModel } from '../../../types'
import { getSalesOrders } from './salesOrdersCrud'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  SalesOrdersRequested: '[GetSalesOrders] Action',
  SalesOrdersLoaded: '[Load Sales Orders] Action',
  SetQuoteNumberSlug: '[Set Quote Number Slug On Sales Orders] Action',
}

const initialQuotesState: ISalesOrdersState = {
  salesOrders: [],
  loading: false,
  quoteNumberSlug: null,
  totals: 0,
}

export interface ISalesOrdersState {
  salesOrders: any
  loading: boolean
  quoteNumberSlug: any
  totals: number
}

export const reducer = (
  state: ISalesOrdersState = initialQuotesState,
  action: ActionWithPayload<ISalesOrdersState>
) => {
  switch (action.type) {
    case actionTypes.SalesOrdersRequested: {
      return {
        ...state,
        loading: true,
      }
    }

    case actionTypes.SalesOrdersLoaded: {
      return {
        ...state,
        loading: false,
        ...action.payload,
      }
    }

    case actionTypes.SetQuoteNumberSlug: {
      const quoteNumberSlug = action.payload?.quoteNumberSlug
      return {
        ...state,
        quoteNumberSlug,
      }
    }

    default:
      return state
  }
}

export const actions = {
  getSalesOrders: (searchFilter: any = {}) => ({
    type: actionTypes.SalesOrdersRequested,
    payload: { searchFilter },
  }),

  salesOrdersLoaded: (salesOrders: QuoteModel[], totals: number) => ({
    type: actionTypes.SalesOrdersLoaded,
    payload: { salesOrders, totals },
  }),

  setQuoteNumberSlug: (quoteNumberSlug: any) => ({
    type: actionTypes.SetQuoteNumberSlug,
    payload: { quoteNumberSlug },
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.SalesOrdersRequested, function* salesOrdersRequested(action: any) {
    const { searchFilter } = action.payload
    const {
      data: { salesOrders, totals },
    } = yield getSalesOrders(searchFilter)

    yield put(actions.salesOrdersLoaded(salesOrders, totals))
  })
}
