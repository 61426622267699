import { Action } from '@reduxjs/toolkit'
import { getEquipmentReports } from './EquipmentReportsCrud'
import { put, takeLatest } from 'redux-saga/effects'
import { IEquipmentReportsModel } from '../../../types/equipment-reports/EquipmentReportModel'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  GetEquipmentReports: '[GetEquipmentReports] Action',
  GetEquipmentReportsSuccess: '[GetEquipmentReportsSuccess] Action',
}

export interface IEquipmentReportsState {
  equipmentReports: IEquipmentReportsModel[]
  loading: boolean
}

const initialState: IEquipmentReportsState = {
  equipmentReports: [],
  loading: false,
}

export const reducer = (
  state: IEquipmentReportsState = initialState,
  action: ActionWithPayload<IEquipmentReportsState>
) => {
  switch (action.type) {
    case actionTypes.GetEquipmentReports: {
      return { ...state, loading: true }
    }

    case actionTypes.GetEquipmentReportsSuccess: {
      const equipmentReports = action.payload?.equipmentReports as IEquipmentReportsModel[]

      return { ...state, equipmentReports, loading: false }
    }
    default:
      return state
  }
}

export const actions = {
  getEquipmentReports: (searchFilter = {}) => ({
    type: actionTypes.GetEquipmentReports,
    payload: { searchFilter },
  }),
  getEquipmentReportsSuccess: (equipmentReports: IEquipmentReportsModel[]) => ({
    type: actionTypes.GetEquipmentReportsSuccess,
    payload: { equipmentReports },
  }),
}

export function* saga() {
  yield takeLatest(
    actionTypes.GetEquipmentReports,
    function* getEquipmentReportsRequested(action: any) {
      const { searchFilter } = action.payload
      const { data } = yield getEquipmentReports(searchFilter)
      yield put(actions.getEquipmentReportsSuccess(data))
    }
  )
}
