import clsx from 'clsx'
import React, { FC, useState, useEffect, useCallback } from 'react'
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocalStorage, useReadLocalStorage } from 'usehooks-ts'

import { KTSVG, toServerUrl } from '../../../helpers'
import { HeaderUserMenu } from '../../../partials'
import { useLayout } from '../../core'
import { RootState } from '../../../../setup'
import { NotificationModel, UserModel } from '../../../../types'
import { HeaderNotifications } from '../../../../app/modules/notifications/HeaderNotifications'
import { getAvatar } from '../../../../helper/fileType.helper'
import { playNotificationSound } from '../../../../helper/notification.helper'

import { getUserSettings } from '../../../../app/modules/accounts/components/UserSettings/UserSettingsCrud'
import * as userSettingsRedux from '../../../../app/modules/accounts/components/UserSettings/UserSettingsRedux'
import { ShakeBell } from './ShakeBell'
import { ThemeContext } from '../../../../app/App'
import { ConfirmNotifications } from '../../../../app/components/_modal/ConfirmNotifications'

const toolbarButtonClass = {
  space: 'ms-1',
  height: 'mw-30px mh-30px mw-md-40px mh-md-40px',
  avatarHeight: 'symbol-30px symbol-md-40px',
  icon: 'svg-icon-1',
}

let timer: any = null

const TopbarBase: FC = (props: any) => {
  const { userSettings } = props
  const { config } = useLayout()
  const themes = useReadLocalStorage('themes')
  const dispatch = useDispatch()
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const [unReadCount, setUnReadCount] = useState<number>(0)
  const [darkMode, setDarkMode] = useState<boolean>(false)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [isNotify, setIsNotify] = useState<boolean>(false)
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false)
  const [, setUnReadCountStore] = useLocalStorage('unReadCount', 0)

  const notificationsSelector = useSelector<RootState>(
    (state) => state.notifications.notifications
  ) as any

  const [playSoundNotification, setPlaySoundNotification] = useState<number>(
    userSettings.play_sound_notification
  )

  // Play notification sound every 5 minutes when DOM is loaded & Un-read count is not zeron & play sound notification is enabled
  const startNotify = useCallback(() => {
    const handlerPlayNotificationSound = (play = true) => {
      const unReadCountOfStorage = localStorage.getItem('unReadCount')

      if (unReadCountOfStorage !== '0') {
        play && playNotificationSound()

        timer = setTimeout(handlerPlayNotificationSound, 5 * 60 * 1000)
      }
    }

    timer && clearTimeout(timer)

    if (unReadCount !== 0 && playSoundNotification === 1) {
      handlerPlayNotificationSound(false)
    }
    setShowConfirm(false)
    setIsConfirmed(true)
  }, [playSoundNotification, unReadCount])

  useEffect(() => {
    setDarkMode(false)

    if (themes) {
      if (themes === 'dark') setDarkMode(true)
    }
  }, [themes])

  useEffect(() => {
    let count = 0

    if (notificationsSelector?.notifications?.length !== 0) {
      notificationsSelector?.notifications?.forEach((notification: NotificationModel) => {
        if (!notification.read_at) count++
      })
    } else setIsConfirmed(true)
    setUnReadCountStore(count)
    setUnReadCount(count)
    if (!isNotify && count !== 0) {
      setShowConfirm(true)
      setIsNotify(true)
    }
  }, [notificationsSelector, isNotify, setUnReadCountStore])

  useEffect(() => {
    if (isConfirmed) {
      startNotify()
    }
  }, [startNotify, isConfirmed])

  useEffect(() => {
    setPlaySoundNotification(Number(userSettings.play_sound_notification))
  }, [userSettings])

  // Load some settings such as play sound notification
  useEffect(() => {
    const init = async () => {
      const playSoundNotificationValue: any = await getUserSettings('play_sound_notification')
      dispatch(
        userSettingsRedux.actions.updateUserSettings(
          'play_sound_notification',
          playSoundNotificationValue
        )
      )
    }

    init()
  }, [dispatch])

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}

      {/* NOTIFICATIONS */}
      <div className={clsx('d-flex align-items-center', toolbarButtonClass.space)}>
        {/* begin::Menu- wrapper */}
        <div
          className={clsx(
            'btn btn-icon btn-icon-muted btn-active-light toolbar-svg btn-active-color-primary position-relative',
            toolbarButtonClass.height
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          {unReadCount !== 0 ? (
            <ShakeBell count={unReadCount} />
          ) : (
            <KTSVG
              path='/media/icons/duotone/General/Bell1.svg'
              className={toolbarButtonClass.icon}
            />
          )}
        </div>
        <HeaderNotifications />
        {/* end::Menu wrapper */}
      </div>

      <div className={clsx('d-flex align-items-center', toolbarButtonClass.space)}>
        <ThemeContext.Consumer>
          {({ changeTheme }) => (
            <div
              className={clsx(
                'btn btn-icon btn-icon-muted btn-active-light toolbar-svg btn-active-color-primary position-relative',
                toolbarButtonClass.height
              )}
              onClick={() => {
                setDarkMode(!darkMode)
                changeTheme(darkMode ? 'light' : 'dark')
              }}
            >
              <KTSVG
                path={`/media/icons/duotone/Weather/${
                  darkMode ? 'sun-fill.svg' : 'moon-stars-fill.svg'
                }`}
                className={toolbarButtonClass.icon}
              />
            </div>
          )}
        </ThemeContext.Consumer>
      </div>

      {/* begin::User */}
      <div className={clsx('d-flex align-items-center ms-lg-3', toolbarButtonClass.space)}>
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarButtonClass.height)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <img
            src={toServerUrl('/media/avatar/' + getAvatar(user.avatar))}
            alt={`${user.firstname} ${user.lastname}`}
            className={toolbarButtonClass.height}
          />
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotone/Text/Toggle-Right.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
      {showConfirm && (
        <ConfirmNotifications
          content={`You have ${unReadCount} unread notifications. Please check the notifications.`}
          yesText='ok'
          selectYes={startNotify}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  userSettings: state.userSettings,
})

const Topbar = connect(mapStateToProps)(TopbarBase)

export { Topbar }
