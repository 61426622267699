/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react'
import DatePicker from 'react-datepicker'

type Props = {
  onApply: (status: string, startDate: any, endDate: any) => void
}

export const Dropdown:FC<Props> = ({ onApply }) => {
  const [status, setStatus] = useState<string>('1')
  const [startDate, setStartDate] = useState<any>('')
  const [endDate, setEndDate] = useState<any>('')

  const handleReset = () => {
    setStatus('1')
    setStartDate('')
    setEndDate('')
    onApply('1', '', '')
  }

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        <div className='mb-10'>
          <label className='form-label fw-bold'>Status:</label>

          <div>
            <select
              className='form-select form-select-solid'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            >
              <option value='1'>All</option>
              <option value='2'>Read</option>
              <option value='3'>Unread</option>
            </select>
          </div>
        </div>
        <div className='mb-10'>
          <label className='form-label fw-bold'>From:</label>
          <DatePicker
            className="form-control"
            placeholderText="Select a date"
            selected={startDate}
            dateFormat="MM/dd/yy hh:mma"
            showTimeSelect
            onChange={(date) => setStartDate(date)}
          />
        </div>
        <div className='mb-10'>
          <label className='form-label fw-bold'>To:</label>
          <DatePicker
            className="form-control"
            placeholderText="Select a date"
            selected={endDate}
            dateFormat="MM/dd/yy hh:mma"
            showTimeSelect
            onChange={(date) => setEndDate(date)}
          />
        </div>
        <div className='d-flex justify-content-end'>
          <button
            type='reset'
            className='btn btn-sm btn-white btn-active-light-primary me-2'
            data-kt-menu-dismiss='true'
            onClick={handleReset}
          >
            Reset
          </button>

          <button
            type='button'
            className='btn btn-sm btn-primary'
            data-kt-menu-dismiss='true'
            onClick={() => onApply(status, startDate, endDate)}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  )
}
