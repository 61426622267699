import axios from 'axios'

export const CUSTOMER_URL = `${process.env.REACT_APP_API_URL}/nbs-customers`

export function getNBSCustomers(searchFilter: any = {}) {
  return axios.get(CUSTOMER_URL, {
    params: searchFilter,
  })
}

export function createCustomer(values: any) {
  const API_URL = `${CUSTOMER_URL}/add`

  return axios.post(API_URL, values)
}

export function updateCustomer(id: string | number, values: any) {
  const API_URL = `${CUSTOMER_URL}/${id}`

  return axios.post(API_URL, values)
}

export function getCustomerShippingID() {
  const API_URL = `${CUSTOMER_URL}/get_shippingID`;
  return axios.get(API_URL)
}

// export function createCustomer({
//   firstname,
//   lastname,
//   email,
//   customerIds,
//   level,
//   title,
// }: CustomerFormData) {
//   return axios.post(CUSTOMER_URL, {
//     firstname,
//     lastname,
//     email,
//     customerIds,
//     level,
//     title,
//   })
// }

export function removeCustomer(id: string | number) {
  if (!id) return

  const API_URL = `${CUSTOMER_URL}/${id}`

  return axios.delete(API_URL)
}

export function getCustomersWithCompanyID(companyId: string) {
  const API_URL = `${CUSTOMER_URL}/getWithCompanyID/${companyId}`
  return axios.get(API_URL)
}
