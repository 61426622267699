import React, { useState, useRef, useEffect } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';

const QuestionTooltip = ({ hoverText }: { hoverText: string }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: '20px', bottom: 'auto' });
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const anchorRef = useRef<HTMLAnchorElement | null>(null); // Ref for the anchor

  useEffect(() => {
    const adjustTooltipPosition = () => {
      if (!anchorRef.current || !tooltipRef.current) return;

      const anchorRect = anchorRef.current.getBoundingClientRect();
      const tooltipHeight = tooltipRef.current.clientHeight;

      // Calculate space below the anchor element
      const spaceBelow = window.innerHeight - anchorRect.bottom;

      // If space below is less than the height of the tooltip, display it above the anchor
      if (spaceBelow < tooltipHeight + 10) { // 10px for some padding
        setTooltipPosition({ bottom: '20px', top: 'auto' });
      } else {
        setTooltipPosition({ top: '20px', bottom: 'auto' });
      }
    };

    if (showTooltip) {
      adjustTooltipPosition();
    }
  }, [showTooltip]);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = (event: { relatedTarget: any; }) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.relatedTarget)) {
      setShowTooltip(false);
    }
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <a
        ref={anchorRef} // Attach the ref to the anchor
        className='my-anchor-element'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave} 
        style={{ zIndex: 1001 }} 
      >
        <KTSVG path='/media/icons/duotone/Code/Question-circle.svg' className='svg-icon-2' />
      </a>
      {showTooltip && (
        <div
          ref={tooltipRef}
          onMouseLeave={handleMouseLeave}
          style={{
            position: 'absolute',
            ...tooltipPosition, // Use dynamic position
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: 'black',
            color: 'white',
            padding: '5px 10px',
            borderRadius: '4px',
            fontSize: '14px',
            minWidth: '60vh',
            maxWidth: '60vh',
            maxHeight: '40vh',
            overflow: 'auto', // Enable scrolling
            overflowWrap: 'break-word',
            zIndex: 1000,
            whiteSpace: 'pre-line', // Ensure new lines are respected
          }}
        >
          {hoverText}
        </div>
      )}
    </div>
  );
};

export { QuestionTooltip };