import React from 'react'
import { NBSCustomer } from '../../../../types'

type Props = {
  headers: string[]
  row: NBSCustomer
}

const Table: React.FC<Props> = ({ headers, row }) => {
  return (
    <table className='table table-row-dashed table-row-gray-300 align-middle  gs-0 gy-4'>
      <thead>
        <tr className='fw-bold fs-6'>
          {headers.map((header, index) => {
            return <th key={index}>{header}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {row && (
          <tr>
            <td>{row.customerId}</td>
            <td>{row.companyId}</td>
            <td>{row.contactFirstName}</td>
            <td>{row.contactLastName}</td>
            <td>{row.taxExempt}</td>
            <td>{row.status}</td>
            <td>{row.idShipping}</td>
            <td>{row.shipCompanyName}</td>
            <td>{row.shipPhoneNumber}</td>
            <td>{row.shipFaxNumber}</td>
            <td>{row.shipAddress}</td>
            <td>{row.shipCity}</td>
            <td>{row.shipState}</td>
            <td>{row.shipZip}</td>
            <td>{row.billingCompanyName}</td>
            <td>{row.billContact}</td>
            <td>{row.billPhoneNumber}</td>
            <td>{row.billFaxNumber}</td>
            <td>{row.billAddress}</td>
            <td>{row.billCity}</td>
            <td>{row.billState}</td>
            <td>{row.billZip}</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default Table
