import React, {FC, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {NotificationModel} from '../../../../types'

type Props = {
  index: number
  notification: NotificationModel
  markRead: (notification: NotificationModel) => void
}
const Notification: FC<Props> = ({index, notification, markRead}) => {
  const [expand, setExpand] = useState<number>(-1)

  const handleExpand = () => {
    if (index === expand) setExpand(-1)
    else setExpand(index)
  }
  return (
    <div
      className={`rounded p-5 mb-4 ${
        !!notification.read_at ? 'bg-light-success' : 'bg-light-danger'
      }`}
    >
      <div className={`d-flex align-items-center`}>
        <span className='svg-icon svg-icon-warning me-5'>
          <KTSVG path='/media/icons/duotone/Home/Library.svg' className='svg-icon-1' />
        </span>
        <div className='flex-grow-1 me-2'>
          <span
            className='fw-bolder text-gray-800 text-hover-primary cursor-pointer fs-6'
            onClick={handleExpand}
          >
            {expand === index ? '-' : '+'} {notification.data.subject}
          </span>
        </div>
        <div className='w-15px'>
          <input
            className='form-check-input cursor-pointer'
            type='checkbox'
            value=''
            name='notifications'
            onChange={() => {
              markRead(notification)
            }}
            checked={!!notification.read_at}
          />
        </div>
      </div>
      {expand === index && (
        <div className={`d-flex align-items-center me-2 ms-12`}>
          <div className='flex-grow-1 me-2'>
            <span
              className='fw-bolder text-gray-800 text-hover-primary cursor-pointer fs-6'
              onClick={handleExpand}
            ></span>
            <span className='text-muted fw-bold text-hover-primary d-block'>
              { !!notification.data.html
                ? <><span dangerouslySetInnerHTML={{ __html: notification.data.description }}></span></>
                : notification.data.description
              }
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export {Notification}
