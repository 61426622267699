import React, { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Accordion } from 'react-bootstrap-v5'
import { SearchDropDown } from '../../components/SearchDropDown'
import { UserModel, Customer } from '../../../types'
import { RootState } from '../../../setup'
import CustomToggle from '../../components/CustomToggle'
import * as levelHelper from '../../../helper/level.helper'
import 'react-datepicker/src/stylesheets/datepicker.scss'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { KTSVG } from '../../../_metronic/helpers'
import FilterList from '../../components/FilterList'
import {
  getCalibrationRepotCustomerIds,
  getCalibrationTypes,
} from '../../pages/calibration-reports/CalibrationReportsCrud'
import { getAccreditReportsCustomerIds } from '../../pages/accredit-reports/AccreditReportsCrud'
import { getCustomers } from '../../pages/other-cal-reports/OtherCalReportsCrud'
type FilterDropDownProps = {
  handleFilter: (filter: any) => void
  handleCancelCustomer: () => void
  accredited?: boolean
  open?: boolean
}

const FilterDropDown: React.FC<FilterDropDownProps> = ({
  handleFilter,
  handleCancelCustomer,
  accredited = false,
  open,
}) => {
  const allFilterTypes = ['Microscope', 'Calibration', 'Thermometer']

  const [isNBS, setIsNBS] = useState<boolean>(false)
  const [isReset, setIsReset] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<any>('')
  const [endDate, setEndDate] = useState<any>()
  const [customers, setCustomers] = useState<Customer[]>([{ id: '', name: '' }])
  const [allTypes, setAllTypes] = useState<Array<string>>([])
  const [types, setTypes] = useState<Array<string>>(allFilterTypes)
  const [selectedCustomers, setSelectedCustomers] = useState<
    { id: string; name: string | undefined }[]
  >([])

  // const allFilterTypes = useMemo(() => {

  // },[])

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const handleSelect = (customer: { id: string; name: string | undefined }) => {
    const isExist = selectedCustomers.find((c) => c.id === customer.id && c.name === customer.name)

    if (!isExist) setSelectedCustomers([...selectedCustomers, ...[customer]])
    setIsReset(false)
  }

  const handleCancel = (customerId: string) => {
    setSelectedCustomers(selectedCustomers.filter((customer) => customer.id !== customerId))
    handleCancelCustomer()
  }

  const handleChangeType = (type: string) => {
    if (types.includes(type)) {
      setTypes(types.filter((t) => t !== type))
    } else {
      setTypes([...types, type])
    }
  }

  const handleReject = () => {
    setStartDate('')
    setEndDate('')
    setIsReset(true)
    setSelectedCustomers([])
    setTypes(allTypes)
    handleFilter({
      isReset: true,
    })
  }

  useEffect(() => {
    if (open) {
      getCalibrationTypes().then((res) => {
        let types = res.data.data.map((item: any) => item.name)
        setAllTypes(types)
        setTypes(types)
      })
    }
  }, [open])

  useEffect(() => {
    setIsNBS(levelHelper.isNBS(user?.type))

    if (levelHelper.isNBS(user?.type)) {
      setSelectedCustomers([])
      if (accredited) {
        getAccreditReportsCustomerIds().then((res) => {
          setCustomers(
            res.data.map((d: any) => {
              return {
                id: d,
                name: d,
              }
            })
          )
        })
      } else {
        if (open) {
          getCustomers().then((res: any) => {
            // console.log('res==', res)
            setCustomers(
              res.data.map((d: any) => {
                return {
                  id: d.CustomerID,
                  name: d.ShipCompanyName,
                }
              })
            )
          })
        }
      }
    } else {
      let uCustomers: Array<Customer> = []

      if (user.customerIds) {
        user.customerIds.forEach((c) => {
          uCustomers.push({ id: c.customerId, name: c.companyName })
        })
      }
      setCustomers(uCustomers)
    }
  }, [user, accredited, open])

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>
      <div className='separator border-gray-200'></div>
      <div className='px-7 py-5'>
        <Accordion defaultActiveKey='0'>
          <>
            <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
              <CustomToggle eventKey='filter-1'>Type</CustomToggle>
            </div>
            <Accordion.Collapse eventKey='filter-1' className='ms-8'>
              <ul className='list-unstyled'>
                {allTypes.map((type, index) => {
                  return (
                    <FilterList
                      key={`status-${index}`}
                      status={type}
                      isChecked={!!types.includes(type)}
                      handleChange={handleChangeType}
                    />
                  )
                })}
              </ul>
            </Accordion.Collapse>
            <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
              <CustomToggle eventKey='filter-0'>Customer ID</CustomToggle>
            </div>
            <Accordion.Collapse eventKey='filter-0' className='ms-8'>
              <>
                <div>
                  {selectedCustomers?.map((customer, index) => {
                    return (
                      <div
                        key={index}
                        className='d-flex justify-content-between px-3 py-2 background-light-dark my-2 cursor-pointer'
                      >
                        <span>
                          {customer.id} - {customer?.name}
                        </span>
                        <span
                          className='text-hover-primary cursor-pointer'
                          onClick={() => handleCancel(customer.id)}
                        >
                          <KTSVG
                            path='/media/icons/duotone/Navigation/Close.svg'
                            className='svg-icon-1'
                          />
                        </span>
                      </div>
                    )
                  })}
                </div>
                <SearchDropDown
                  data={customers}
                  edit={isReset ? 'reset' : ''}
                  isNBS={isNBS}
                  selectedValue={(customer) => handleSelect(customer)}
                />
              </>
            </Accordion.Collapse>
          </>
          <div className='separator border-gray-200'></div>
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='filter-2'>Date of Calibration</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='filter-2'>
            <>
              <div className='mb-10'>
                <label className='form-label fw-bold'>From:</label>
                <DatePicker
                  className='form-control'
                  placeholderText='Select a date'
                  selected={startDate}
                  dateFormat='MM/dd/yy hh:mma'
                  showTimeSelect
                  onChange={(date) => {
                    setStartDate(date)
                  }}
                />
              </div>
              <div className='mb-10'>
                <label className='form-label fw-bold'>To:</label>
                <DatePicker
                  className='form-control'
                  placeholderText='Select a date'
                  selected={endDate}
                  dateFormat='MM/dd/yy hh:mma'
                  showTimeSelect
                  onChange={(date) => setEndDate(date)}
                />
              </div>
            </>
          </Accordion.Collapse>
          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-white btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
              onClick={handleReject}
            >
              Reset
            </button>
            <button
              type='button'
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={() => {
                setIsReset(false)
                handleFilter({
                  selectedCustomers,
                  types,
                  from: startDate === '' ? '' : moment(startDate).utc().format('Y-MM-DD'),
                  to: !endDate ? moment().add(1, 'days').utc().format('Y-MM-DD') : moment(endDate).utc().format('Y-MM-DD'),
                })
              }}
            >
              Apply
            </button>
          </div>
        </Accordion>
      </div>
    </div>
  )
}

export default FilterDropDown
