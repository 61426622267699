import { Action } from '@reduxjs/toolkit'
import { OtherCalReports } from '../../../types/other-cal-reports/OtherCalReportsTypes'
import { put, takeLatest } from 'redux-saga/effects'
import { getOtherCalReports } from './OtherCalReportsCrud'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  GetOtherCalReports: '[GetOtherCalReports] Action',
  GetOtherCalReportsSuccess: '[GetOtherCalReportsSuccess] Action',
}

export interface IOtherCalReportsState {
  otherCalReports: any
  lastPage: number
  loading: boolean
  current_page: number
  count: number
}

const initialState: IOtherCalReportsState = {
  otherCalReports: [],
  lastPage: 1,
  current_page: 1,
  loading: false,
  count: 0,
}

export const reducer = (state = initialState, action: ActionWithPayload<IOtherCalReportsState>) => {
  switch (action.type) {
    case actionTypes.GetOtherCalReports: {
      return { ...state, loading: true }
    }
    case actionTypes.GetOtherCalReportsSuccess: {
      const lastPage = action.payload?.lastPage
      const current_page = action.payload?.current_page
      const temp_accreditReports = action.payload?.otherCalReports
      const count = action.payload?.count
      const otherCalReports = temp_accreditReports?.map((item: any) => {
        Object.keys(item).map((obj) => {
          if (item[obj] == null) {
            item[obj] = ''
          }
        })
        return item
      })
      return { ...state, otherCalReports, lastPage, current_page, loading: false, count }
    }
    default:
      return state
  }
}

export const actions = {
  getOtherCalReports: (searchFilter = {}) => ({
    type: actionTypes.GetOtherCalReports,
    payload: { searchFilter },
  }),
  getOtherCalReportsSuccess: (data: any) => ({
    type: actionTypes.GetOtherCalReportsSuccess,
    payload: {
      otherCalReports: data.data,
      lastPage: data.last_page,
      current_page: data.current_page,
      count: data.total,
    },
  }),
}

export function* saga() {
  yield takeLatest(
    actionTypes.GetOtherCalReports,
    function* getAccreditReportsRequested(action: any) {
      const { searchFilter } = action.payload
      const { data } = yield getOtherCalReports(searchFilter)
      yield put(actions.getOtherCalReportsSuccess(data))
    }
  )
}
