import React, { useState, useEffect } from 'react'
import { Accordion } from 'react-bootstrap-v5'
import { SearchDropDown } from './SearchDropDown'
import { getAllCustomerIds } from '../../../pages/asset-mangement/AssetManagementCrud'
import { UserModel } from '../../../../types'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import * as levelHelper from '../../../../helper/level.helper'
import { KTSVG } from '../../../../_metronic/helpers'

type FilterDropDownProps = {
  handleFilter: (filter: any) => void
  handleCancelCustomer: () => void
}

const FilterDropDown: React.FC<FilterDropDownProps> = ({ handleFilter, handleCancelCustomer }) => {
  const [isNBS, setIsNBS] = useState<boolean>(false)
  const [selectedCustomers, setSelectedCustomers] = useState<
    { id: string; name: string | undefined }[]
  >([])
  const [customers, setCustomers] = useState<{ id: string; name: string }[]>([{ id: '', name: '' }])

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel

  const handleSelect = (customer: { id: string; name: string | undefined }) => {
    const isExist = selectedCustomers.find((c) => c.id === customer.id)

    if (!isExist) setSelectedCustomers([...selectedCustomers, ...[customer]])
  }

  const handleCancel = (customerId: string) => {
    setSelectedCustomers(selectedCustomers.filter((customer) => customer.id !== customerId))
    handleCancelCustomer()
  }

  useEffect(() => {
    setIsNBS(levelHelper.isNBS(user?.type))
  }, [user])

  useEffect(() => {
    if (isNBS)
      getAllCustomerIds().then((res) => {
        setCustomers(res.data)
      })
  }, [isNBS])

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter By Customers</div>
      </div>
      <div className='separator border-gray-200'></div>
      <div className='px-7 py-5'>
        <Accordion defaultActiveKey='0'>
          <div>
            {selectedCustomers?.map((customer, index) => {
              return (
                <div
                  key={index}
                  className='d-flex justify-content-between px-3 py-2 background-light-dark my-2 cursor-pointer'
                >
                  <span>
                    {customer.id} - {customer?.name}
                  </span>
                  <span
                    className='text-hover-primary cursor-pointer'
                    onClick={() => handleCancel(customer.id)}
                  >
                    <KTSVG
                      path='/media/icons/duotone/Navigation/Close.svg'
                      className='svg-icon-1'
                    />
                  </span>
                </div>
              )
            })}
          </div>
          <div className='separator border-gray-200'></div>
          <SearchDropDown
            data={customers}
            edit=''
            isNBS={isNBS}
            selectedValue={(customer) => handleSelect(customer)}
          />
          <div className='d-flex justify-content-end mt-5'>
            <button
              type='reset'
              className='btn btn-sm btn-white btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
              onClick={() => {
                setSelectedCustomers([])
                handleFilter({
                  isReset: true,
                })
              }}
            >
              Reset
            </button>
            <button
              type='button'
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={() =>
                handleFilter({
                  customers: selectedCustomers,
                })
              }
            >
              Apply
            </button>
          </div>
        </Accordion>
      </div>
    </div>
  )
}

export default FilterDropDown
