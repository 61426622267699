import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IInvoiceWithTodayResponseModel, INVOICE_URL, getInvoices } from './store/invoiceSlice'
import { RootState } from '../../../setup'
import { PageTitle } from '../../../_metronic/layout/core'
import { Card } from 'react-bootstrap-v5'
import { KTSVG } from '../../../_metronic/helpers'
import DataTableBase from '../../components/DataTableBase'
import { TableColumn } from 'react-data-table-component'
import { IInvoice, IInvoiceHdr } from '../../../types/Ivoice.type'
import { Select } from 'antd'
import FilterDropDown from './components/FilterDropDown'
import { BillingCompany, Customer } from '../../../types'
import { useDebounce } from 'use-debounce'
import ActionDropDown from './components/ActionDropDown'
import { isNBS } from '../../../helper'
import { IAuthState } from '../../modules/auth'
import { Dialog, DialogTitle, Fab, IconButton } from '@material-ui/core'
import { PDFViewer } from '@react-pdf/renderer'
import ViewPDFforInvoice from './components/pdf-view'
import axios from 'axios'
import toast from 'react-hot-toast'

type Props = {}

const InvoicePage = (props: Props) => {
  const { invoices, loading, today }: IInvoiceWithTodayResponseModel = useSelector(
    ({ invoice }: RootState) => invoice
  )
  const activeCustomerIds = useSelector<RootState>(({ auth }) => auth.activeCustomerIds) as string[]
  const auth = useSelector<RootState>(({ auth }) => auth) as IAuthState
  const [isAdmin, setIsAdmin] = useState<boolean>(isNBS(auth.user?.type))

  const [page, setPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(10)
  const [view, setView] = useState<number>(0)
  const [status, setStatus] = useState<'open' | 'paid'>('open')
  const [slug, setSlug] = useState<string>('')
  const [debouncedSlug] = useDebounce(slug, 1000)
  const [column, setColumn] = useState<string>('')
  const [sort, setSort] = useState<string>('')
  const dispatch = useDispatch()
  const filterRef = useRef() as React.MutableRefObject<HTMLButtonElement>
  const [filter, setFilter] = useState<any>()
  const [open, setOpen] = useState<boolean>(false)
  const [load, setLoad] = useState<boolean>(false)
  const [data, setData] = useState<IInvoiceHdr | null>(null)

  useEffect(() => {
    dispatch(
      getInvoices({
        page,
        perPage,
        view,
        slug: debouncedSlug,
        status,
        column,
        sort,
        customerIds: isAdmin ? [] : activeCustomerIds.length === 0 ? ['nbs'] : activeCustomerIds,
        ...filter,
      })
    )
  }, [
    dispatch,
    page,
    perPage,
    view,
    debouncedSlug,
    status,
    column,
    sort,
    isAdmin,
    activeCustomerIds,
    filter,
  ])

  useEffect(() => {
    setIsAdmin(isNBS(auth.user?.type))
  }, [activeCustomerIds, auth])

  const calculateDueDate = useCallback(
    (date: string) => {
      if (date === null) {
        return false
      } else {
        if (date > today) {
          return false
        } else {
          return true
        }
      }
    },
    [today]
  )

  const handleFilter = useCallback(
    (filters: any) => {
      const {
        fromInvoice,
        fromInvoiceDue,
        toInvoice,
        toInvoiceDue,
        selectedCompanies,
        selectedCustomers,
        isReset,
        endPrice,
        startPrice,
      } = filters
      if (isReset) {
        setFilter({})
        dispatch(
          getInvoices({
            page,
            perPage,
            view,
            slug,
            status,
            column,
            sort,
            customerIds: isAdmin
              ? []
              : activeCustomerIds.length === 0
              ? ['nbs']
              : activeCustomerIds,
          })
        )
      } else {
        const filteredCustomerIds: string[] = selectedCustomers.map(
          (customer: Customer) => customer.id
        )
        const filteredCompanyIds: string[] = selectedCompanies.map(
          (customer: BillingCompany) => customer.BillingCompanyID
        )
        if (isAdmin) {
          setFilter({
            customerIds: isAdmin
              ? filteredCustomerIds
              : activeCustomerIds.length === 0
              ? ['nbs']
              : activeCustomerIds,
            companyIds: filteredCompanyIds,
            fromInvoice,
            fromInvoiceDue,
            toInvoice,
            toInvoiceDue,
            endPrice,
            startPrice,
          })
        } else {
          setFilter({
            fromInvoice,
            fromInvoiceDue,
            toInvoice,
            toInvoiceDue,
            endPrice,
            startPrice,
          })
        }
      }
    },
    [page, perPage, view, slug, status, column, sort, isAdmin, activeCustomerIds]
  )

  const handleSort = (column: any, direction: any) => {
    setColumn(column.id)
    setSort(direction)
  }

  const handleCancelCustomer = () => {
    setTimeout(() => {
      filterRef.current.click()
    }, 200)
  }

  const columns: TableColumn<IInvoice>[] = useMemo(
    () => [
      {
        name: 'Actions',
        cell: (row) => (
          <>
            <ActionDropDown
              handleOpen={() => {
                setLoad(true)
                axios
                  .get(`${INVOICE_URL}/getById/${row.dbkey}`)
                  .then((res) => {
                    setData(res.data)
                    setLoad(false)
                  })
                  .catch((err) => {
                    toast.error('Something is wrong. Please try later')
                     setLoad(false)
                  })
                // setOpen(true)
              }}
            />
          </>
          // <ActionDropDown
          //   data={row}
          //   handleEdit={handleEdit}
          //   handleView={handleView}
          //   canEdit={isAdmin}
          // />
        ),
        width: '100px',
        style: {
          minWidth: '100px !important',
          maxWidth: '100px !important',
        },
      },
      {
        id: 'CompanyName',
        name: 'Company Name',
        selector: (row) => row.CompanyName,
        cell: (row) => row.CompanyName,
        sortable: true,
        width: '18%',
        style: {
          minWidth: '18% !important',
          maxWidth: '18% !important',
        },
      },
      {
        id: 'invoice_number',
        name: 'Invoice Number',
        selector: (row) => `${row.invoice_number}`,
        cell: (row) => row.invoice_number,
        sortable: true,
        width: '12%',
        style: {
          minWidth: '12% !important',
          maxWidth: '12% !important',
        },
      },
      {
        id: 'invoice_type',
        name: 'Invoice Type',
        selector: (row) => row.invoice_type,
        cell: (row) => row.invoice_type,
        sortable: true,
        width: '10%',
        style: {
          minWidth: '10% !important',
          maxWidth: '10% !important',
        },
      },
      {
        id: 'invoice_total',
        name: 'Total Amount Due',
        cell: (row) => <span>${row.invoice_total}</span>,
        selector: (row) => row.invoice_total,
        sortable: true,
        width: '16%',
        style: {
          minWidth: '16% !important',
          maxWidth: '16% !important',
        },
      },
      {
        id: 'invoiceDue',
        name: 'Invoice Due on',
        cell: (row) => {
          let past = calculateDueDate(row.invoiceDue)
          return (
            <span
              style={{
                color: past && row.status === 'Open' ? 'red' : 'black',
              }}
            >
              {row.invoiceDue}
            </span>
          )
        },
        selector: (row) => `${row.net_terms}`,
        sortable: true,
        width: '15%',
        style: {
          minWidth: '15% !important',
          maxWidth: '15% !important',
        },
      },
      {
        id: 'invoice_date',
        name: 'Invoice Date',
        selector: (row) => `${row.invoice_date}`,
        cell: (row) => row.invoice_date,
        sortable: true,
        width: '10%',
        style: {
          minWidth: '10% !important',
          maxWidth: '10% !important',
        },
      },
      {
        id: 'status',
        name: 'Status',
        selector: (row) => `${row.status}`,
        cell: (row) => row.status,
        sortable: true,
        width: '6%',
        style: {
          minWidth: '6% !important',
          maxWidth: '6% !important',
        },
      },
    ],
    [calculateDueDate]
  )
  return (
    <>
      <PageTitle breadcrumbs={[]}>Calibration Reports</PageTitle>
      <Card className='min-vh-100'>
        <Dialog open={load}>
          <img src='/media/pdf/pdf-loading.gif' />
        </Dialog>
        <Card.Header className='border-0 pt-6'>
          <Card.Title>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotone/General/Search.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-customer-table-filter='search'
                className='form-control form-control-solid w-250px ps-15 border-0'
                placeholder='Search Calibration Reports'
                value={slug}
                onChange={(e: any) => setSlug(e.target.value)}
              />
            </div>
            <Select
              // defaultValue='all'
              placeholder='View'
              style={{ width: 140, marginLeft: 10 }}
              onChange={(e) => setView(e)}
              options={[
                { value: 0, label: 'All Invoices' },
                { value: 1, label: 'This Month' },
                { value: 2, label: 'Last Month' },
                { value: 3, label: 'This Year' },
                { value: 4, label: 'Last Year' },
                { value: 5, label: 'Over Due' },
              ]}
            />
            <Select
              // defaultValue='open'
              placeholder='Status'
              style={{ width: 80, marginLeft: 10 }}
              onChange={(e) => setStatus(e)}
              options={[
                { value: 'open', label: 'Open' },
                { value: 'paid', label: 'Paid' },
              ]}
            />
          </Card.Title>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-customer-table-toolbar='base'>
              <button
                type='button'
                className='btn btn-light-primary me-3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Filter options'
                ref={filterRef}
              >
                <KTSVG
                  path='/media/icons/duotone/Text/Filter.svg'
                  className='svg-icon-2 text-light'
                />
                Filter
              </button>
              <FilterDropDown
                handleFilter={handleFilter}
                handleCancelCustomer={handleCancelCustomer}
              />
            </div>
          </div>
        </Card.Header>
        <Card.Body className='pt-0 calibration-reports-body'>
          <DataTableBase
            columns={columns}
            data={invoices.data}
            progressPending={loading}
            pagination
            onSort={handleSort}
            paginationTotalRows={invoices.total}
            paginationPerPage={perPage}
            paginationDefaultPage={1}
            paginationServer
            sortServer
            onChangePage={(m, n) => {
              setPage(m)
            }}
            onChangeRowsPerPage={(e, n) => {
              setPerPage(e)
            }}
          />
        </Card.Body>
      </Card>
      <Dialog
        open={data ? true : false}
        onClose={() => {
          setOpen(false)
          setData(null)
        }}
        fullScreen
      >
        <Fab
          variant='circular'
          size='small'
          onClick={() => {
            setOpen(false)
            setData(null)
          }}
          style={{ position: 'fixed', right: '60px', top: '80px' }}
        >
          <KTSVG
            path='/media/icons/duotone/Interface/Close-Square.svg'
            className='svg-icon-2 svg-icon-dark-500'
          />
        </Fab>
        {data && (
          <PDFViewer style={{ width: '100%', height: '100vh' }}>
            <ViewPDFforInvoice data={data as IInvoiceHdr} />
          </PDFViewer>
        )}
      </Dialog>
    </>
  )
}

export default InvoicePage
