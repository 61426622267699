import React, { FC } from 'react'
import { KTSVG } from '../../../helpers'

const toolbarButtonClass = {
  space: 'ms-1 ms-lg-3',
  height: 'mw-30px mh-30px mw-md-40px mh-md-40px w-auto h-auto',
  avatarHeight: 'symbol-30px symbol-md-40px',
  icon: 'svg-icon-1',
}

type Props = {
  count: number
}

export const ShakeBell: FC<Props> = (props) => {
  return (
    <>
      <KTSVG
        path='/media/icons/duotone/General/Bell1.svg'
        className={`${toolbarButtonClass.icon} bell-shake`}
      />
      <span className='position-absolute translate-middle unread-blink'>
        {props.count <= 3 ? props.count : '3+'}
      </span>
    </>
  )
}
