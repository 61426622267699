import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../../app/modules/auth'
import * as salesQuotes from '../../app/pages/sales-quotes/salesQuotesRedux'
import * as calibrationReports from '../../app/pages/calibration-reports/CalibrationReportsRedux'
import * as accreditReports from '../../app/pages/accredit-reports/AccreditReportsRedux'
import * as otherCalReports from '../../app/pages/other-cal-reports/OtherCalReportsRedux'
import * as serviceOrders from '../../app/pages/service-orders/serviceOrdersRedux'
import * as salesOrders from '../../app/pages/sales-orders/salesOrdersRedux'
import * as serviceQuotes from '../../app/pages/service-quotes/serviceQuotesRedux'
import * as customers from '../../app/pages/customer/redux/customerRedux'
import * as nbsCustomers from '../../app/pages/customers/CustomersRedux'
import * as nbsContacts from '../../app/pages/contacts/ContactsRedux'
import * as nbsAdmins from '../../app/pages/nbs-admin/redux/nbsAdminRedux'
import * as assetManagement from '../../app/pages/asset-mangement/AssetManagementRedux'
import * as blogs from '../../app/modules/blog/redux/blogRedux'
import * as notifications from '../../app/modules/notifications/NotificationsRedux'
import * as userSettings from '../../app/modules/accounts/components/UserSettings/UserSettingsRedux'
import * as billingCompanies from '../../app/pages/billing-companies/BillingCompanyRedux'
import invoice from '../../app/pages/invoice/store/invoiceSlice'
import * as equipmentReports from '../../app/pages/equipment-reports/EquipmentReportsRedux'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  billingCompany: billingCompanies.reducer,
  salesQuotes: salesQuotes.reducer,
  calibrationReports: calibrationReports.reducer,
  serviceOrders: serviceOrders.reducer,
  salesOrders: salesOrders.reducer,
  serviceQuotes: serviceQuotes.reducer,
  customers: customers.reducer,
  nbsContacts: nbsContacts.reducer,
  nbsCustomers: nbsCustomers.reducer,
  nbsAdmins: nbsAdmins.reducer,
  blogs: blogs.reducer,
  notifications: notifications.reducer,
  userSettings: userSettings.reducer,
  assetManagement: assetManagement.reducer,
  accreditReports: accreditReports.reducer,
  otherCalReports: otherCalReports.reducer,
  invoice: invoice,
  equipmentReports: equipmentReports.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    auth.saga(),
    salesQuotes.saga(),
    calibrationReports.saga(),
    salesOrders.saga(),
    serviceQuotes.saga(),
    serviceOrders.saga(),
    customers.saga(),
    nbsCustomers.saga(),
    nbsAdmins.saga(),
    blogs.saga(),
    notifications.saga(),
    assetManagement.saga(),
    billingCompanies.saga(),
    nbsContacts.saga(),
    accreditReports.saga(),
    otherCalReports.saga(),
    equipmentReports.saga(),
  ])
}
