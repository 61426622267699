import React from 'react'

type Props = {
  content: string
  yesText: string
  noText: string
  selectYes: () => void
  selectNo: () => void
}

const ConfirmModal: React.FC<Props> = (props) => {
  const { content, yesText, noText, selectYes, selectNo } = props

  return (
    <div className='swal2-container swal2-center swal2-backdrop-show'>
      <div className='swal2-popup swal2-modal swal2-icon-warning swal2-show'>
        <div className='swal2-icon swal2-warning swal2-icon-show'>
          <div className='swal2-icon-content'>!</div>
        </div>
        <div className='swal2-html-container text-center'>{content}</div>
        <div className='swal2-actions d-flex'>
          <button 
            className='swal2-confirm btn btn-primary'
            onClick={selectYes}
          >
            {yesText}
          </button>
          <button 
            className='swal2-cancel btn btn-active-light'
            onClick={selectNo}
          >
            {noText}
          </button>
        </div>
      </div>
    </div>
  )
}

export { ConfirmModal }