export const cutString = (string: string): string => {
  if (!string) return ''

  const subString = `${string.substring(0, 50)} ...`

  return subString
}

export const playNotificationSound = () => {
  const audio = new Audio('/media/sounds/notification.wav')
  audio.play()
}
