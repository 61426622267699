import React, { useState, useEffect } from 'react';

interface ShowPinsToggleProps {
  isChecked: boolean;
  handleChange: (checked: boolean) => void;
}

const ShowPinsToggle: React.FC<ShowPinsToggleProps> = ({ isChecked, handleChange }) => {
  const [checked, setChecked] = useState<boolean>(isChecked);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <div className='d-flex justify-content-end align-items-center' onClick={() => {
      handleChange(!checked);
      setChecked(!checked);
    }}>
      <input
        className='form-check-input w-17 h-17 mt-2 me-2'
        type='checkbox'
        name='showPins'
        checked={checked}
        readOnly
      />
      <button className='btn p-2 ps-0 w-100 text-start text-capitalize'>
        Show Pins
      </button>
    </div>
  );
};

export default ShowPinsToggle;
