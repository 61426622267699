import axios from 'axios'
import { ContactModel } from '../../../types'

export const CONTACT_URL = `${process.env.REACT_APP_API_URL}/nbs-contacts`

export function getNBSContacts(CustomerID: any = {}) {
  return axios.get(CONTACT_URL, {
    params: CustomerID,
  })
}
export function editNBSContacts(data: ContactModel) {
  return axios.post(CONTACT_URL, data)
}

export function deleteNBSContact(dbkey: number) {
  return axios.delete(CONTACT_URL, { params: { dbkey } })
}
