import { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap-v5'
import CustomToggle from '../../../components/CustomToggle'
import { KTSVG } from '../../../../_metronic/helpers'
import { BillingCompany, Customer, UserModel } from '../../../../types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import * as levelHelper from '../../../../helper/level.helper'
import { getAllCustomerIds } from '../../asset-mangement/AssetManagementCrud'
import { SearchDropDown as CustomerDropDown } from '../../../components/SearchDropDown'
import { getBillingCompaniesWithSpecCol } from '../../billing-companies/BillingCompanyCRUD'
import { SearchDropDown as BillingDropDown } from '../../billing-companies/component/SearchDropDown'
// import { DatePicker } from 'antd'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { Col, InputNumber, Row, Slider } from 'antd'

type Props = {
  handleFilter: (filter: any) => void
  handleCancelCustomer: () => void
}

const FilterDropDown = ({ handleCancelCustomer, handleFilter }: Props) => {
  const [isNBS, setIsNBS] = useState<boolean>(false)
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const [customers, setCustomers] = useState<Customer[]>([{ id: '', name: '' }])
  const [isReset, setIsReset] = useState<boolean>(false)
  const [selectedCustomers, setSelectedCustomers] = useState<
    { id: string; name: string | undefined }[]
  >([])

  const [selectedCompanies, setSelectedCompanies] = useState<BillingCompany[]>([])
  const [companies, setCompanies] = useState<BillingCompany[]>([])
  const [invoiceStartDate, setInvoiceStartDate] = useState<any>('')
  const [invoiceEndDate, setInvoiceEndDate] = useState<any>('')
  const [invoiceDueStartDate, setInvoiceDueStartDate] = useState<any>('')
  const [invoiceDueEndDate, setInvoiceDueEndDate] = useState<any>('')
  const [startPrice, setStartPrice] = useState<number>(0)
  const [endPrice, setEndPrice] = useState<number>(2000)

  const dispatch = useDispatch()
  useEffect(() => {
    setIsNBS(levelHelper.isNBS(user?.type))
  }, [user])

  useEffect(() => {
    if (isNBS) {
      getAllCustomerIds().then((res) => {
        setCustomers(res.data)
      })

      getBillingCompaniesWithSpecCol().then((res) => {
        setCompanies(res.data)
      })
    }
  }, [isNBS, dispatch])

  const handleCustomerCancel = (customerId: string) => {
    setSelectedCustomers(selectedCustomers.filter((customer) => customer.id !== customerId))
    handleCancelCustomer()
  }
  const handleCustomerSelect = (customer: { id: string; name: string | undefined }) => {
    const isExist = selectedCustomers.find((c) => c.id === customer.id)

    if (!isExist) setSelectedCustomers([...selectedCustomers, ...[customer]])
    setIsReset(false)
  }

  const handleCompanySelect = (company: BillingCompany) => {
    const isExist = selectedCompanies.find((c) => c.BillingCompanyID === company.BillingCompanyID)

    if (!isExist) setSelectedCompanies([...selectedCompanies, ...[company]])
    setIsReset(false)
  }
  const handleCompanyCancel = (companyId: string) => {
    setSelectedCompanies(
      selectedCompanies.filter((company) => company.BillingCompanyID !== companyId)
    )
    handleCancelCustomer()
  }

  const handleReject = () => {
    setInvoiceStartDate('')
    setInvoiceEndDate('')
    setInvoiceDueStartDate('')
    setInvoiceDueEndDate('')
    setIsReset(true)
    setSelectedCustomers([])
    setSelectedCompanies([])
    setStartPrice(0)
    setEndPrice(2000)
    handleFilter({
      isReset: true,
    })
  }

  return (
    <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
      </div>

      <div className='px-7 py-5'>
        <Accordion defaultActiveKey='0'>
          <>
            {isNBS && (
              <>
                <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
                  <CustomToggle eventKey='filter-0'>Customer ID</CustomToggle>
                </div>
                <Accordion.Collapse eventKey='filter-0' className='ms-8'>
                  <>
                    <div>
                      {selectedCustomers?.map((customer, index) => {
                        return (
                          <div
                            key={index}
                            className='d-flex justify-content-between px-3 py-2 background-light-dark my-2 cursor-pointer'
                          >
                            <span>
                              {customer.id} - {customer?.name}
                            </span>
                            <span
                              className='text-hover-primary cursor-pointer'
                              onClick={() => handleCustomerCancel(customer.id)}
                            >
                              <KTSVG
                                path='/media/icons/duotone/Navigation/Close.svg'
                                className='svg-icon-1'
                              />
                            </span>
                          </div>
                        )
                      })}
                    </div>
                    <CustomerDropDown
                      data={customers}
                      edit={isReset ? 'reset' : ''}
                      isNBS={isNBS}
                      selectedValue={(customer) => handleCustomerSelect(customer)}
                    />
                  </>
                </Accordion.Collapse>

                <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
                  <CustomToggle eventKey='filter-2'>Billing Company ID</CustomToggle>
                </div>
                <Accordion.Collapse eventKey='filter-2' className='ms-8'>
                  <>
                    <div>
                      {selectedCompanies?.map((company, index) => {
                        return (
                          <div
                            key={index}
                            className='d-flex justify-content-between px-3 py-2 background-light-dark my-2 cursor-pointer'
                          >
                            <span>
                              {company.BillingCompanyID} - {company?.CompanyName}
                            </span>
                            <span
                              className='text-hover-primary cursor-pointer'
                              onClick={() => handleCompanyCancel(company.BillingCompanyID)}
                            >
                              <KTSVG
                                path='/media/icons/duotone/Navigation/Close.svg'
                                className='svg-icon-1'
                              />
                            </span>
                          </div>
                        )
                      })}
                    </div>
                    <BillingDropDown
                      data={companies}
                      edit={isReset ? 'reset' : ''}
                      isNBS={isNBS}
                      selectedValue={(company) => handleCompanySelect(company)}
                    />
                  </>
                </Accordion.Collapse>
              </>
            )}
          </>

          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='filter-3'>Invoice Date</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='filter-3'>
            <>
              {/* <DatePicker.RangePicker /> */}
              <div className='mb-10'>
                <label className='form-label fw-bold'>From:</label>
                <DatePicker
                  className='form-control'
                  placeholderText='Select a date'
                  selected={invoiceStartDate}
                  dateFormat='MM/dd/yy hh:mma'
                  showTimeSelect
                  onChange={(date) => {
                    setInvoiceStartDate(date)
                  }}
                />
              </div>
              <div className='mb-10'>
                <label className='form-label fw-bold'>To:</label>
                <DatePicker
                  className='form-control'
                  placeholderText='Select a date'
                  selected={invoiceEndDate}
                  dateFormat='MM/dd/yy hh:mma'
                  showTimeSelect
                  onChange={(date) => setInvoiceEndDate(date)}
                />
              </div>
            </>
          </Accordion.Collapse>

          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='filter-4'>Invoice Due</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='filter-4'>
            <>
              {/* <DatePicker.RangePicker /> */}
              <div className='mb-10'>
                <label className='form-label fw-bold'>From:</label>
                <DatePicker
                  className='form-control'
                  placeholderText='Select a date'
                  selected={invoiceDueStartDate}
                  dateFormat='MM/dd/yy hh:mma'
                  showTimeSelect
                  onChange={(date) => {
                    setInvoiceDueStartDate(date)
                  }}
                />
              </div>
              <div className='mb-10'>
                <label className='form-label fw-bold'>To:</label>
                <DatePicker
                  className='form-control'
                  placeholderText='Select a date'
                  selected={invoiceDueEndDate}
                  dateFormat='MM/dd/yy hh:mma'
                  showTimeSelect
                  onChange={(date) => setInvoiceDueEndDate(date)}
                />
              </div>
            </>
          </Accordion.Collapse>
          <div className='fw-bolder text-gray-800 p-3 ps-0 fs-6'>
            <CustomToggle eventKey='filter-5'>Total Amount Range</CustomToggle>
          </div>
          <Accordion.Collapse eventKey='filter-5'>
            <>
              <Row align='middle' justify='space-around'>
                <Col flex='100px'>
                  <InputNumber
                    addonBefore='$'
                    min={0}
                    value={startPrice}
                    onChange={(e) => setStartPrice(e ?? 0)}
                    max={2000}
                  />
                </Col>
                <Col flex='100px'>
                  <InputNumber
                    addonBefore='$'
                    min={0}
                    value={endPrice}
                    onChange={(e) => setEndPrice(e ?? 2000)}
                    max={2000}
                  />
                </Col>
              </Row>
              <Slider
                marks={{ 0: '$0', 500: '$500', 1000: '$1000', 2000: '$2000' }}
                range
                defaultValue={[0, 2000]}
                value={[startPrice, endPrice]}
                onChange={(e) => {
                  setStartPrice(e[0])
                  setEndPrice(e[1])
                }}
                min={0}
                max={2000}
              />{' '}
            </>
          </Accordion.Collapse>
          <div className='d-flex justify-content-end'>
            <button
              type='reset'
              className='btn btn-sm btn-white btn-active-light-primary me-2'
              data-kt-menu-dismiss='true'
              onClick={handleReject}
            >
              Reset
            </button>
            <button
              type='button'
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={() => {
                setIsReset(false)
                handleFilter({
                  selectedCustomers,
                  selectedCompanies,
                  fromInvoice:
                    invoiceStartDate === '' ? '' : moment(invoiceStartDate).utc().format('Y-MM-DD'),
                  toInvoice:
                    invoiceEndDate !== ''
                      ? moment(invoiceEndDate).utc().format('Y-MM-DD')
                      : moment().utc().format('Y-MM-DD'),
                  fromInvoiceDue:
                    invoiceDueStartDate === ''
                      ? ''
                      : moment(invoiceDueStartDate).utc().format('Y-MM-DD'),
                  toInvoiceDue:
                    invoiceDueEndDate !== ''
                      ? moment(invoiceDueEndDate).utc().format('Y-MM-DD')
                      : moment().utc().format('Y-MM-DD'),
                  startPrice,
                  endPrice,
                })
              }}
            >
              Apply
            </button>
          </div>
        </Accordion>
      </div>
    </div>
  )
}

export default FilterDropDown
