import React, { useEffect, useState, useCallback, useRef } from 'react'
import toast from 'react-hot-toast'
import { Modal } from 'react-bootstrap-v5'
import { convertByteTo } from '../../../../helper/learningCenter.helper'
import { KTSVG } from '../../../../_metronic/helpers'
import { fileType } from '../../../../types'
import { uploadAttachment, downloadAttachment } from '../serviceQuotesCrud'
import { ConfirmModal } from '../../../components/_modal/ConfirmModal'
import { uploadOtherAttachment } from '../../other-cal-reports/OtherCalReportsCrud'
import { AppBar, Box, Button, Checkbox, Dialog, Grid, Toolbar, Typography } from '@material-ui/core'
import { CircularProgressWithLabel } from '../../calibration-reports/component/AddCalReportModal'

type Props = {
  show: boolean
  onHide: any
  data: fileType[]
  quoteId: number
  getQuotes: () => void
  otherCalReports?: boolean
  isNBS?: boolean
}

const AttachmentModal: React.FC<Props> = (props) => {
  const { show, onHide, data, quoteId, getQuotes, otherCalReports, isNBS } = props
  const [allFiles, setAllFiles] = useState<File[]>([])
  const [fileName, setFileName] = useState<string>('')
  const [fileType, setFileType] = useState<string>('')
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
  const [selectedFiles, setSelectedFiles] = useState<fileType[]>(data)
  const [checkedFiles, setCheckedFiles] = useState<fileType[]>([])
  const [count, setCount] = useState<number>(0)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [uploading, setUploading] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0)
  const inputFileRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const [all, setAll] = useState<boolean>(false)
  const openInputFile = () => {
    inputFileRef.current.click()

    return false
  }

  const handleChange = (event: any) => {
    const files = event.currentTarget.files
    let attachedFiles = [...allFiles, ...files]
    let dt = new DataTransfer()

    attachedFiles.forEach((file) => {
      dt.items.add(file)
    })

    let fetchFiles = []

    for (let file of files)
    fetchFiles.push({ id: "0", quoteId: 0, name: file.name, size: file.size, type: 'new', checked: false })

    setSelectedFiles([...selectedFiles, ...fetchFiles])
    setAllFiles(attachedFiles)
  }

  const handleCancelFile = useCallback((event: any, name: string, type: string) => {
    event.stopPropagation()

    setFileName(name)
    setFileType(type)
    setShowConfirmDelete(true)
  }, [])

  const handleYesDeleteFile = useCallback(() => {
    let newSelectedFiles: fileType[] = []

    selectedFiles.forEach((file) => {
      if (file.name === fileName && file.type === 'old')
        newSelectedFiles.push({
          name: file.name, size: file.size, type: 'removed', checked: false,
          id: '',
          quoteId: 0
        })
      else if (file.name === fileName && file.type === 'new') return
      else newSelectedFiles.push(file)
    })

    setSelectedFiles(newSelectedFiles)

    let removedFiles = []

    for (let file of allFiles) {
      if (file?.name !== fileName) removedFiles.push(file)
    }

    if (fileType === 'old') {
      if (otherCalReports) {
        setUploading(true)
        setTotalCount(0)
        uploadOtherAttachment(quoteId, newSelectedFiles, { files: [] }, (progress: any) => {
          setUploadProgress(progress)
        })
          .then((res) => {
            setUploading(false)
            toast.success('Upload successful')
            getQuotes()
          })
          .catch((err) => toast.error('Something is wrong'))
      } else {
        uploadAttachment(quoteId, newSelectedFiles, { files: [] }).then((res) => {
          getQuotes()
        })
      }
    }

    setAllFiles(removedFiles)
    setShowConfirmDelete(false)
  }, [allFiles, selectedFiles, fileName, quoteId, fileType, getQuotes])

  const handleDownLoad = useCallback((event: any, name: string) => {
    event.stopPropagation()

    downloadAttachment(name)
      .then((res) => {
        let url = window.URL.createObjectURL(res.data)
        let a = document.createElement('a')
        a.href = url
        a.download = name
        a.onload = () => {
          window.URL.revokeObjectURL(url) // Revoke the URL after initiating the download
        }
        a.click()
      })
      .catch((error) => {
        toast.error('This file is not found.')
      })
  }, [])

  const handleDownLoadAll = useCallback(async () => {
    let count = 0
    setTotalCount(selectedFiles.length)
    setUploading(true)
    for (const fileName of selectedFiles) {
      if (fileName.type !== 'removed') {
        // Create a wrapper function that returns a Promise
        const downloadFile = () =>
          new Promise<void>((resolve, reject) => {
            handleDownLoad({ stopPropagation: () => {} }, fileName.name)
            count += 1
            setUploadProgress(count)
            setTimeout(resolve, 500)
          })

        try {
          await downloadFile() // Wait for one download to initiate before starting the next
        } catch (error) {
          // Handle any errors
          console.error('Error downloading file:', fileName.name, error)
        }
      }
    }
    setUploading(false)
  }, [selectedFiles, handleDownLoad])

  const handleRemoveAll = () => {
    onHide()
    setSelectedFiles([])
    setAllFiles([])
    setShowConfirm(false)
  }
  const handleCheck = (e: any, file: fileType, index: number) => {
    let updatedFiles: fileType[] = []
    let update_count = 0
    selectedFiles.forEach((old_file, i: number) => {
      if (i === index) {
        old_file.checked = e.target.checked
      }
      if (old_file.checked) {
        update_count += 1
      }
      updatedFiles.push(old_file)
    })
    setSelectedFiles(updatedFiles)
    setCount(update_count)
  }

  const handleConfirmReturn = () => {
    setShowConfirm(false)
    setShowConfirmDelete(false)
  }

  const handleUploadAll = () => {
    let dt = new DataTransfer()

    allFiles.forEach((file) => {
      dt.items.add(file)
    })

    const attachFiles = {
      files: dt.files,
    }
    if (otherCalReports) {
      setUploading(true)

      uploadOtherAttachment(quoteId, selectedFiles, attachFiles, (progress: any) => {
        setUploadProgress(progress)
      })
        .then((res) => {
          setUploading(false)
          toast.success('Upload successful')
          setSelectedFiles([])
          setAllFiles([])
          onHide()
          getQuotes()
        })
        .catch((err) => toast.error('Something is wrong'))
    } else {
      uploadAttachment(quoteId, selectedFiles, attachFiles).then((res) => {
        setSelectedFiles([])
        setAllFiles([])
        onHide()
        getQuotes()
      })
    }
  }

  useEffect(() => {
    setSelectedFiles(data)
    setCount(0)
    setAll(false)
  }, [data])

  const handleCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAll(e.target.checked)
    setSelectedFiles(
      selectedFiles.map((f) => {
        f.checked = e.target.checked
        return f
      })
    )
    if (e.target.checked) {
      setCount(selectedFiles.length)
    } else {
      setCount(0)
    }
  }

  return (
    <>
      <Modal
        size='lg'
        show={show}
        dialogClassName='w-50'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        scrollable
      >
        <Modal.Header>
          <Modal.Title className='w-100' id='contained-modal-title-vcenter'>
            <div className='d-flex justify-content-between'>
              <h2>Service Quote-Attachment</h2>
              <span
                className='text-hover-primary cursor-pointer'
                onClick={() => setShowConfirm(true)}
              >
                <KTSVG path='/media/icons/duotone/Navigation/Close.svg' className='svg-icon-1' />
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='pt-10 pb-15 px-lg-17'>
          <div className='form-group'>
            <div className='dropzone dropzone-queue mb-2'>
              {otherCalReports ? (
                <AppBar position='static' color='inherit' style={{ boxShadow: 'none' }}>
                  {/* <Toolbar> */}
                  <Grid container spacing={1} alignItems='center'>
                    <Grid item md={1}>
                      <Checkbox checked={all} onChange={handleCheckAll} />
                    </Grid>
                    <Grid item md={5}>
                      <Typography>
                        Attachment{' '}
                        <span className='text-muted'>
                          ({count} of {selectedFiles.length} selected)
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography>Cal Type</Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography>Cal Date</Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography>Author</Typography>
                    </Grid>
                  </Grid>
                  {/* </Toolbar> */}
                </AppBar>
              ) : (
                // isNBS && (
                //   <div className='dropzone-panel d-flex justify-content-between align-items-center mb-4'>
                //     <span className='required'>Attachment</span>
                //     <span
                //       className='dropzone-select btn btn-sm btn-primary dz-clickable'
                //       onClick={openInputFile}
                //     >
                //       <KTSVG
                //         path='/media/icons/duotone/Files/Cloud-upload.svg'
                //         className='svg-icon-2'
                //       />
                //       Upload files
                //       <input
                //         type='file'
                //         name='files[]'
                //         className='form-control d-none'
                //         onChange={handleChange}
                //         ref={inputFileRef}
                //         multiple
                //       />
                //     </span>
                //   </div>
                // )
                <div className='dropzone-panel d-flex justify-content-between align-items-center mb-4'>
                  <span className='required'>Attachment</span>
                  <span
                    className='dropzone-select btn btn-sm btn-primary dz-clickable'
                    onClick={openInputFile}
                  >
                    <KTSVG
                      path='/media/icons/duotone/Files/Cloud-upload.svg'
                      className='svg-icon-2'
                    />
                    Upload files
                    <input
                      type='file'
                      name='files[]'
                      className='form-control d-none'
                      onChange={handleChange}
                      ref={inputFileRef}
                      multiple
                    />
                  </span>
                </div>
              )}
            </div>
            <div className='dropzone-items wm-200px'>
              {otherCalReports ? (
                <div style={{ maxHeight: '600px', overflowY: 'scroll' }}>
                  {selectedFiles.map((file, index) => {
                    return (
                      file.type !== 'removed' && (
                        <Grid
                          key={index}
                          className='background-light-dark'
                          container
                          style={{ margin: '8px 2px' }}
                          spacing={1}
                          alignItems='center'
                        >
                          <Grid item md={1}>
                            <Checkbox
                              checked={file.checked}
                              onChange={(e) => handleCheck(e, file, index)}
                            />
                          </Grid>
                          <Grid item md={5}>
                            <Typography noWrap>{file.name}</Typography>
                          </Grid>
                          <Grid item md={2}>
                            <Typography>Cal Type</Typography>
                          </Grid>
                          <Grid item md={2}>
                            <Typography>Cal Date</Typography>
                          </Grid>
                          <Grid item md={2}>
                            <Typography>Author</Typography>
                          </Grid>
                        </Grid>
                      )
                    )
                  })}
                  {/* <span className='form-text fs-6 text-muted'>
                    Max file size is 100MB per file.
                  </span> */}
                </div>
              ) : (
                selectedFiles.map((file, index) => {
                  return (
                    file.type !== 'removed' && (
                      <div
                        className='d-flex justify-content-between p-4 background-light-dark my-2 cursor-pointer'
                        onClick={(event) => handleDownLoad(event, file.name)}
                        key={index}
                      >
                        <span>
                          {file.name} {file.size !== 0 && `(${convertByteTo(file.size)})`}
                        </span>
                        <span
                          className='text-hover-primary cursor-pointer'
                          onClick={(event) => handleCancelFile(event, file.name, file.type)}
                        >
                          <KTSVG
                            path='/media/icons/duotone/Navigation/Close.svg'
                            className='svg-icon-1'
                          />
                        </span>
                        <span className='form-text fs-6 text-muted'>
                          Max file size is 100MB per file.
                        </span>
                      </div>
                    )
                  )
                })
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {otherCalReports ? (
            <>
              {selectedFiles.length > 0 && (
                <Box className='mt-6 float-end'>
                  <Button
                    className='mx-4'
                    variant='contained'
                    disabled={count === 0}
                    color='primary'
                    startIcon={
                      <KTSVG
                        path='/media/icons/duotone/Files/Cloud-download.svg'
                        className='svg-icon-2'
                      />
                    }
                  >
                    delete
                  </Button>
                  <Button
                    // className='float-end'
                    variant='contained'
                    disabled={count === 0}
                    color='primary'
                    startIcon={
                      <KTSVG
                        path='/media/icons/duotone/Files/Cloud-download.svg'
                        className='svg-icon-2'
                      />
                    }
                  >
                    download
                  </Button>
                </Box>
                // <>
                //   <span
                //     className='dropzone-select btn btn-sm btn-primary dz-clickable float-end mt-3'
                //     onClick={handleDownLoadAll}
                //   >
                //     <KTSVG
                //       path='/media/icons/duotone/Files/Cloud-download.svg'
                //       className='svg-icon-2'
                //     />
                //     Download All
                //   </span>
                //   <span
                //     className='dropzone-select btn btn-sm btn-primary dz-clickable float-end mt-3'
                //     onClick={handleDownLoadAll}
                //   >
                //     <KTSVG
                //       path='/media/icons/duotone/Files/Cloud-download.svg'
                //       className='svg-icon-2'
                //     />
                //     Download All
                //   </span>
                // </>
              )}
            </>
          ) : (
            <div>
              <span className='btn btn-sm btn-light-primary me-2' onClick={handleRemoveAll}>
                Cancel
              </span>
              {allFiles.length !== 0 && (
                <span className='dropzone-select btn btn-sm btn-primary' onClick={handleUploadAll}>
                  Add attachment
                </span>
              )}
            </div>
          )}
        </Modal.Footer>
      </Modal>
      {showConfirm && (
        <ConfirmModal
          content='Are you sure you would like to cancel?'
          yesText='Yes, cancel it!'
          noText='No, return'
          selectYes={handleRemoveAll}
          selectNo={handleConfirmReturn}
        />
      )}
      {showConfirmDelete && (
        <ConfirmModal
          content='Are you sure you would like to delete?'
          yesText='Yes, delete it!'
          noText='No, return'
          selectYes={handleYesDeleteFile}
          selectNo={handleConfirmReturn}
        />
      )}

      <Dialog
        open={uploading}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <CircularProgressWithLabel value={uploadProgress} count={totalCount} />
      </Dialog>
    </>
  )
}

export { AttachmentModal }
