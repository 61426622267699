import React from 'react'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'
type Props = {
  columns?: (string | number)[]
  rows?: (string | number | undefined | null)[]
  hdr?: boolean
}

const getStyles = (index: number) => {
  switch (index) {
    case 0:
      return styles.firstTableColHeaderStyle0
    case 1:
      return styles.firstTableColHeaderStyle1
    case 2:
      return styles.firstTableColHeaderStyle2
    case 3:
      return styles.firstTableColHeaderStyle3
    case 4:
      return styles.firstTableColHeaderStyle4
    case 5:
      return styles.firstTableColHeaderStyle5
    case 6:
      return styles.firstTableColHeaderStyle6
    default:
      break
  }
  return styles.firstTableColHeaderStyle0
}
const PdfTable = ({ columns, rows, hdr }: Props) => {
  const createTableHeader = () => {
    return (
      <View style={styles.tableRowStyle} fixed>
        {columns?.map((item, index) => (
          <View style={[getStyles(index), { backgroundColor: 'black' }]} key={index}>
            <Text style={styles.tableCellHeaderStyle}>{item}</Text>
          </View>
        ))}
      </View>
    )
  }

  const createTableRow = () => {
    return (
      <View style={styles.tableRowStyle}>
        {rows?.map((item, index) => (
          <View style={[getStyles(index), { backgroundColor: '#eaeffa' }]} key={index}>
            <Text style={styles.tableCellStyle}>{item === null ? 'null' : item}</Text>
          </View>
        ))}
      </View>
    )
  }
  const createTableRow3 = () => {
    return (
      <View style={styles.tableRowStyle}>
        {rows?.map((item, index) => (
          <View style={[getStyles(0), { width: '33.3%', backgroundColor: '#eaeffa' }]} key={index}>
            <Text style={styles.tableCellStyle}>{item === null ? 'null' : item}</Text>
          </View>
        ))}
      </View>
    )
  }

  return (
    <View style={styles.tableStyle}>
      {hdr ? (
        createTableRow3()
      ) : (
        <>
          {createTableHeader()}
          {createTableRow()}
        </>
      )}
      {/* {createTableRow()}
            {createTableRow()}
            {createTableRow()}
            {createTableRow()} */}
    </View>
  )
}
const styles = StyleSheet.create({
  pageStyle: {
    paddingTop: 16,
    paddingHorizontal: 40,
    paddingBottom: 56,
  },

  tableStyle: {
    display: 'flex',
    width: '100%',
  },

  tableRowStyle: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'stretch',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },

  firstTableColHeaderStyle0: {
    width: '10%',
    borderStyle: 'solid',
    borderColor: '#c0c0c0',
    borderBottomColor: '#c0c0c0',
    borderWidth: 1,

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  firstTableColHeaderStyle1: {
    width: '10%',
    borderStyle: 'solid',
    borderColor: '#c0c0c0',
    borderBottomColor: '#c0c0c0',
    borderWidth: 1,

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  firstTableColHeaderStyle2: {
    width: '10%',
    borderStyle: 'solid',
    borderColor: '#c0c0c0',
    borderBottomColor: '#c0c0c0',
    borderWidth: 1,

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  firstTableColHeaderStyle3: {
    width: '30%',
    borderStyle: 'solid',
    borderColor: '#c0c0c0',
    borderBottomColor: '#c0c0c0',
    borderWidth: 1,

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  firstTableColHeaderStyle4: {
    width: '10%',
    borderStyle: 'solid',
    borderColor: '#c0c0c0',
    borderBottomColor: '#c0c0c0',
    borderWidth: 1,

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  firstTableColHeaderStyle5: {
    width: '10%',
    borderStyle: 'solid',
    borderColor: '#c0c0c0',
    borderBottomColor: '#c0c0c0',
    borderWidth: 1,

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  firstTableColHeaderStyle6: {
    width: '20%',
    borderStyle: 'solid',
    borderColor: '#c0c0c0',
    borderBottomColor: '#c0c0c0',
    borderWidth: 1,

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  firstTableColStyle: {
    // maxWidth: "20%",
    borderStyle: 'solid',
    borderColor: '#c0c0c0',
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1 / 3,
    backgroundColor: '#eaeffa',
    // flex: 1
    // width: 'auto',
    // display: 'flex'
  },

  tableColStyle: {
    width: '20%',
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
  },

  tableCellHeaderStyle: {
    textAlign: 'center',
    margin: 4,
    fontSize: 12,
    fontWeight: 'bold',
    color: 'white',
  },

  tableCellStyle: {
    textAlign: 'center',
    margin: 4,
    fontSize: 10,
    display: 'flex',
  },
})

export default PdfTable
