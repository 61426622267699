import {CustomerID} from '../types'

const extractOnlyCustomerIds = (ids: CustomerID[] | undefined) => {
  if (ids === undefined) return []

  const onlyCustomerIds = ids.map((id) => id.customerId)
  return onlyCustomerIds
}

const extractOnlyCustomerIdSlectValues = (ids: CustomerID[] | undefined) => {
  if (ids === undefined) return []

  const onlyCustomervalues = ids.map((id) => id.value)
  return onlyCustomervalues
}

const getCustomerIdValuesFromCustomerIds = (
  allIds: CustomerID[] | undefined,
  searchIds: (string | undefined)[] | undefined
) => {
  if (allIds === undefined || searchIds === undefined) return []

  let result: string[] = []
  searchIds.forEach((v: string | undefined) => {
    const id = allIds.find((id: CustomerID) => id.customerId === v)
    if (id?.value) result.push(id?.value)
  })
  return result
}

const getCustomerIdsFromCustomerIdValues = (
  allIds: CustomerID[] | undefined,
  searchIds: (string | undefined)[] | undefined
) => {
  if (allIds === undefined || searchIds === undefined) return []

  let result: string[] = []
  searchIds.forEach((v: string | undefined) => {
    const id = allIds.find((id: CustomerID) => id.value === v)
    if (id?.customerId) result.push(id?.customerId)
  })
  return result
}

const getCompanyNamesFromCustomerIdValues = (
  allIds: CustomerID[] | undefined,
  searchIds: (string | undefined)[] | undefined
) => {
  if (allIds === undefined || searchIds === undefined) return []

  let result: string[] = []
  searchIds.forEach((v: string | undefined) => {
    const id = allIds.find((id: CustomerID) => id.value === v)
    if (id?.companyName) result.push(id?.companyName)
  })

  return [...new Set(result)].join(', ')
}

const getCompanyNamesFromCustomerIds = (
  allIds: CustomerID[] | undefined,
  searchIds: (string | undefined)[] | undefined
) => {
  if (allIds === undefined || searchIds === undefined) return []

  let result: string[] = []
  searchIds.forEach((v: string | undefined) => {
    const id = allIds.find((id: CustomerID) => id.customerId === v)
    if (id?.companyName) result.push(id?.companyName)
  })
  
  return [...new Set(result)].join(', ')
}

const getCompanyNameFromCustomerId = (allIds: CustomerID[], curId: string) => {
  if (allIds === undefined) return []

  const result = allIds.find((id: CustomerID) => id.customerId === curId)
  if (!result) return ''
  return result.companyName
}

export {
  extractOnlyCustomerIds,
  extractOnlyCustomerIdSlectValues,
  getCustomerIdValuesFromCustomerIds,
  getCustomerIdsFromCustomerIdValues,
  getCompanyNamesFromCustomerIdValues,
  getCompanyNamesFromCustomerIds,
  getCompanyNameFromCustomerId,
}
