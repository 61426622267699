import React from 'react'
import { ImportAsset } from '../../../../types'

type Props = {
  headers: string[]
  rows: ImportAsset[]
}

const Table: React.FC<Props> = ({ headers, rows }) => {
  return (
    <table className='table table-row-dashed table-row-gray-300 align-middle  gs-0 gy-4'>
      <thead>
        <tr className='fw-bold fs-6'>
          {headers.map((header, index) => {
            return <th key={index}>{header}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => {
          return (
            <tr key={index}>
              <td>{row.make}</td>
              <td>{row.model}</td>
              <td>{row.serial}</td>
              <td>{row.unitid}</td>
              <td>{row.dept}</td>
              <td>{row.equipment_type}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default Table
